import {
  Badge,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  message,
  Radio,
  Select,
  Space,
  TimePicker,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import {
  AuditOutlined,
  BellFilled,
  DashboardOutlined,
  DollarOutlined,
  FormOutlined,
  GroupOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  LockFilled,
  LogoutOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlusOutlined,
  QuestionOutlined,
  SendOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import authenticationService from "../../../services/authentication.service";
import { handleError, InstitutionLogo, InstitutionName } from "../../../common";
import NewsletterService from "../../../services/newsletter.service";
import TextEditor from "../../../common/TextEditor";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./send_message.css";
import classroomService from "../../../services/classroom.service";
import axios from "axios";
import useToken from "../../../hooks/useToken";
import BackButton from "../../../common/BackButton";
import SystemFooter from "../../../common/SystemFooter";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import facePlaceholder from "../../../Assets/images/user-placeholder.svg";
import { apiUrl } from "../../../http-common";

const SendMessage = ({ bulk }) => {
  const [form] = Form.useForm();
  const { token, setToken } = useToken();
  const [messageAttachmentFile, setmessageAttachmentFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [communicationOptions, setCommunicationOptions] = useState([]);
  const [audienceOptionsObjects, setAudienceOptionsObjects] = useState([]);
  const [audienceOptions, setAudienceOptions] = useState([]);
  const [whenToPublish, setWhenToPublish] = useState("now");
  const [scheduledPublishDate, setScheduledPublishDate] = useState(null);
  const [scheduledPublishTime, setScheduledPublishTime] = useState(null);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [usersSearchResults, setUsersSearchResults] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");

  const navigate = useNavigate();

  const navigation = useNavigation();

  const username = authenticationService.getUsername();
  const fullName = authenticationService.getFullName();

  const [
    isMobileAppDownloadContainerIsCollapsed,
    setisMobileAppDownloadContainerIsCollapsed,
  ] = useState(true);

  const [isHorizontalStickyMenuHidden, setIsHorizontalStickyMenuHidden] =
    useState(true);

  const school_id = authenticationService.getUserTenantId()

  const institution = authenticationService.getUserTenantId();

  const first_name = authenticationService.getFirstName();
  const last_name = authenticationService.getLastName();
  const full_name = `${first_name} ${last_name}`;

  const searchUsers = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      setUsersSearchResults([])
      // document.querySelector(".student_search_container #student_search").setAttribute("value", "") 
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-users/?q=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      // console.log("users", data);
      const filtered_data = data.filter(({is_superuser}) => !is_superuser)
      // console.log("filtered users", filtered_data)
      setUsersSearchResults(filtered_data);
    });
  };

  const handleMessageAttachmentFileUpload = ({ fileList }) => {
    // console.log(fileList);
    setmessageAttachmentFile(fileList[0]?.originFileObj);
  };

  const baseURL = "http://192.168.100.69:8000/api/v1/";

  const handleChangeCommunicationChannel = (selectedOptions) => {
    setCommunicationOptions(selectedOptions);
  };

  const handleChangeAudience = (selectedOptions) => {
    setAudienceOptions(selectedOptions);
  };

  const handleDateChange = (date, dateString) => {
    setScheduledPublishDate(dateString);
    console.log("date", date);
    console.log("date string", dateString);
  };

  const handleTimeChange = (time, timeString) => {
    setScheduledPublishTime(timeString);
    console.log("time", time);
    console.log("time string", timeString);
  };

  const checkIfAllOptionHasBeenSelected = (
    stateVariableOptions,
    setterFunction
  ) => {
    if (stateVariableOptions.includes("All")) {
      setterFunction(["All"]);
    }
  };

  document.addEventListener("click", (e) => {
    const clickedElement = e.target;
    if (!clickedElement.closest(".horizontal_sticky_menu_container")) {
      setIsHorizontalStickyMenuHidden(true);
    }
  });

  const fetchClassrooms = async () => {
    try {
      const response =
        await classroomService.getClassroomsWithGenderStatisticsByInstitutionId(
          authenticationService.getUserTenantId()
        );
        const uniqueStudents = Array.from(
          new Set(response?.data.map((classroom_data) => classroom_data?.level?.name))
        ).map((levelName) => ({
          label: `${levelName} Students`,
          value: `${levelName} Students`,
        }));
      // const parents = response?.data.map((classroom_data) => ({
      //   label: `${classroom_data?.level?.name} Parents`,
      //   value: `${classroom_data?.level?.name} Parents`,
      // }));
      const combined_parents_and_students = [
        { label: "All", value: "all" },
        ...uniqueStudents,
        // ...parents,
        { label: "Teachers", value: "Teachers" },
      ];

      // console.log("classroom response", combined_parents_and_students)
      setAudienceOptionsObjects(combined_parents_and_students);
    } catch (e) {}
  };

  useEffect(() => {
    fetchClassrooms();
  }, []);

  const getCurrentDateTimeInSimpleFormat = () => {
    const currentDateTime = new Date();

    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const year = currentDateTime.getFullYear();
    const formattedCurrentDate = `${day}:${month}:${year}`;

    let hours = currentDateTime.getHours();
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");
    hours = hours % 12 || 12;
    const formattedCurrentTime = `${hours}:${minutes}:${seconds}`;

    return {
      formattedCurrentDate: formattedCurrentDate,
      formattedCurrentTime: formattedCurrentTime,
    };
  };

  const getCurrentDateTimeInISO = () => {
    const currentDateTime = new Date();

    return currentDateTime.toISOString();
  };

  function convertToISO(dateStr, timeStr) {
    const [day, month, year] = dateStr.split(":");
    const [hours, minutes, seconds] = timeStr.split(":");

    const date = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, seconds)
    );

    return date.toISOString();
  }

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      setDisabled(true);

      const formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if(value) formData.append(key, value);
      }

      if (whenToPublish !== "now") {
        formData.set("schedule_date", getCurrentDateTimeInISO());
      } else {
        const schedule_date =
          getCurrentDateTimeInSimpleFormat().formattedCurrentDate;
        console.log("scheduled date", schedule_date);
        // formData.set(
        //   "schedule_date",
        //   convertToISO(getCurrentDateTimeInSimpleFormat().formattedCurrentDate, getCurrentDateTimeInSimpleFormat().formattedCurrentTime)
        // );
      }

      // formData.set("channel", communicationOptions);
      // formData.set("audience_category", audienceOptions);
      // if (messageAttachmentFile)
        formData.set("audience", audienceOptions?.includes("all") ? [...communicationAudienceOptions.map(({value}) => (value)).filter(val => val !== "all")] : audienceOptions);
      formData.set("channel", communicationOptions?.includes("All") ? [...communicationChannelOptions.map(({value}) => (value)).filter(val => val !== "All")] : communicationOptions);
      // formData.set("audience", audienceOptions);
      // formData.set("channel", communicationOptions);
      if(messageAttachmentFile) {formData.set("attachment", messageAttachmentFile);}
      formData.set("institutionID", institution);
      if (selectedUserId) formData.set("user_id", selectedUserId);
      formData.set("createdByID", authenticationService.getUserId());
      formData.set("createdBy", full_name);
 
      // for (const [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // } 

      const response = await axios.post(`${baseURL}create-message`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        message.success("Message sent successfully");
        window.location.reload();
      } else {
        console.log(
          "Message was not sent successful. Status:",
          response.status
        );
        message.error(
          response?.data?.error ??
            "An error occurred, please check your network."
        );
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const communicationChannelOptions = [
    { label: "All", value: "All" },
    { label: "SMS", value: "SMS" },
    { label: "Email", value: "Email" },
    { label: "WhatsApp", value: "WhatsApp" },
  ];

  const communicationAudienceOptions = [...audienceOptionsObjects];

  const messageSchedulingSendingOptions = [
    { label: "Now", value: "now" },
    { label: "At a later date and time", value: "scheduled" },
  ];

  const adminMenuItems = [
    {
      key: "1",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Dashboard",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/"),
    },
    {
      key: "2",
      icon: <SolutionOutlined style={{ marginLeft: 4 }} />,
      label: "Student Management",
      children: [
        {
          key: "3",
          label: "Students",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/students"),
        },
        {
          key: "4",
          label: "Admissions",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/new-student"),
        },
      ],
    },
    {
      key: "18",
      icon: <TeamOutlined style={{ marginLeft: 4 }} />,
      label: "Teacher Management",
      children: [
        {
          key: "19",
          label: "Teachers",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/teachers"),
        },
        {
          key: "20",
          label: "Register",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/new-teacher"),
        },
      ],
    },
    {
      key: "parents",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Parent Management",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/parents"),
    },
    {
      key: "6",
      icon: <GroupOutlined style={{ marginLeft: 4 }} />,
      label: "Classroom Management",
      children: [
        {
          key: "7",
          label: "Classes",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/student-classes"),
        },
        {
          key: "8",
          label: "Subject Allocations",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/subject-allocations"),
        },
      ],
    },
    {
      key: "10",
      icon: <SendOutlined style={{ marginLeft: 4 }} />,
      label: "Communications",
      children: [
        // {
        //     key: '11',
        //     icon: <MessageOutlined style={{ marginLeft: 4 }} />,
        //     label: 'SMS Management',
        //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
        //     onClick: () => navigate("/admin/sms-information"),
        //     disabled: true
        // },
        {
          key: "12",
          icon: <InboxOutlined style={{ marginLeft: 4 }} />,
          label: "Newsletters",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/newsletter"),
        },
        {
          key: "14",
          icon: <MessageOutlined style={{ marginLeft: 4 }} />,
          label: "Send Bulk Message",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/send_bulk_message"),
        },
        {
          key: "24",
          icon: <MessageOutlined style={{ marginLeft: 4 }} />,
          label: "Send Individual Message",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/send_individual_message"),
        },
      ],
    },
    // {
    //     key: 'hr',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Ancillary Staff Management',
    //     children: [
    //         {
    //             key: 'hr1',
    //             label: 'Staff',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff")
    //         },
    //         {
    //             key: 'hr2',
    //             label: 'Payroll',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff/payroll-records")
    //         },
    //     ]
    // },
    // {
    //     disabled: true,
    //     key: 'accounting',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Accounts',
    //     children: [
    //         {
    //             key: 'transactions',
    //             label: 'Transactions',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/transactions")
    //         },
    //         {
    //             key: 'income_statements',
    //             label: 'Income Statement',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/income-statement")
    //         },
    //     ]
    // },
    // {
    //     key: 'assetManagement',
    //     icon: <CarOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Asset Management',
    //     disabled: false,
    //     children: [
    //         {
    //             key: 'vehicle',
    //             label: 'Vehicles',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/vehicles")
    //         },
    //         {
    //             key: 'electronics',
    //             label: 'Electronics',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/electronics")
    //         },
    //         {
    //             key: 'general_asset',
    //             label: 'General Assets',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/general-assets")
    //         }
    //     ]
    // },
    // {
    //     key: 'library',
    //     icon: <BookOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Library Management',
    //     children: [
    //         {
    //             key: 'libraries',
    //             label: 'Libraries',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library")
    //         },
    //         {
    //             key: 'libraryBooks',
    //             label: 'Books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/books")
    //         },
    //         {
    //             key: 'libraryLoans',
    //             label: 'Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/book-loans")
    //         },
    //         {
    //             key: 'myLibraryLoans',
    //             label: 'My Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/book-loans")
    //         },
    //         {
    //             key: 'eBooks',
    //             label: 'E-books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/ebooks")
    //         },
    //     ]
    // },
    // {
    //     key: '14',
    //     icon: <PieChartOutlined style={{ marginLeft: 4 }} />,
    //     label: 'School Records',
    //     children: [
    //         {
    //             key: '15',
    //             label: 'Students',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/student-reports")
    //         },
    //         {
    //             key: '16',
    //             label: 'Teacher',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/teacher-reports")
    //         }
    //     ]
    // },
    // {
    //     key: 'liveClass',
    //     icon: <VideoCameraOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Live classes',
    //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //     onClick: () => navigate('/admin/live-classes')
    // },

    {
      key: "110",
      icon: <FormOutlined style={{ marginLeft: 4 }} />,
      label: "Exam Management",
      children: [
        {
          key: "111",
          label: "Manage Exams",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/examinations"),
        },
        {
          key: "112",
          label: "Mark Exams",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/mark/examinations"),
        },
      ],
    },
    {
      key: "adminStudentCoursework",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Coursework Management",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/coursework"),
    },
    {
      key: "13",
      icon: <InfoCircleOutlined style={{ marginLeft: 4 }} />,
      label: "School Information",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/school-information"),
    },
    {
      key: "e_learning",
      icon: <UserSwitchOutlined />,
      label: "E-Learning",
      onClick: () => navigate("/admin/e_learning"),
    },
    {
      key: "accounts",
      icon: <DollarOutlined />,
      label: "Accounts",
      onClick: () => navigate("/admin/accounts"),
    },
    {
      key: "17",
      icon: <UserOutlined style={{ marginLeft: 4 }} />,
      label: "Profile",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/account"),
    },
    {
      key: "21",
      icon: <PhoneOutlined style={{ marginLeft: 4 }} />,
      label: "Support",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
    },
    {
      key: "22",
      icon: <AuditOutlined style={{ marginLeft: 4 }} />,
      label: "System logs",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/system-logs"),
    },
    {
      key: "23",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];

  const adminMenuItems__mobile = [
    {
      key: "1",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Dashboard",
      to_destination: "/",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/"),
    },
    {
      key: "3",
      label: "Students",
      to_destination: "/admin/students",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/students"),
    },
    {
      key: "4",
      label: "Admissions",
      to_destination: "/admin/new-student",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/new-student"),
    },
    {
      key: "19",
      label: "Teachers",
      to_destination: "/admin/teachers",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/teachers"),
    },
    {
      key: "20",
      label: "Register Teacher",
      to_destination: "/admin/new-teacher",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/new-teacher"),
    },
    {
      key: "parents",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Parent Management",
      to_destination: "/admin/parents",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/parents"),
    },
    {
      key: "7",
      label: "Classes",
      to_destination: "/admin/student-classes",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/student-classes"),
    },
    {
      key: "8",
      label: "Subject Allocations",
      to_destination: "/admin/subject-allocations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/subject-allocations"),
    },
    {
      key: "12",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Newsletters",
      to_destination: "/admin/newsletter",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/newsletter"),
    },
    {
      key: "15",
      icon: <MessageOutlined style={{ marginLeft: 4 }} />,
      label: "Send Bulk Message",
      to_destination: "/admin/send_bulk_message",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/send_bulk_message"),
    },
    {
      key: "25",
      icon: <MessageOutlined style={{ marginLeft: 4 }} />,
      label: "Send Individual Message",
      to_destination: "/admin/send_individual_message",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/send_individual_message"),
    },
    // {
    //     key: 'hr',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Ancillary Staff Management',
    //     children: [
    //         {
    //             key: 'hr1',
    //             label: 'Staff',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff")
    //         },
    //         {
    //             key: 'hr2',
    //             label: 'Payroll',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff/payroll-records")
    //         },
    //     ]
    // },
    // {
    //     disabled: true,
    //     key: 'accounting',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Accounts',
    //     children: [
    //         {
    //             key: 'transactions',
    //             label: 'Transactions',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/transactions")
    //         },
    //         {
    //             key: 'income_statements',
    //             label: 'Income Statement',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/income-statement")
    //         },
    //     ]
    // },
    // {
    //     key: 'assetManagement',
    //     icon: <CarOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Asset Management',
    //     disabled: false,
    //     children: [
    //         {
    //             key: 'vehicle',
    //             label: 'Vehicles',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/vehicles")
    //         },
    //         {
    //             key: 'electronics',
    //             label: 'Electronics',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/electronics")
    //         },
    //         {
    //             key: 'general_asset',
    //             label: 'General Assets',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/general-assets")
    //         }
    //     ]
    // },
    // {
    //     key: 'library',
    //     icon: <BookOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Library Management',
    //     children: [
    //         {
    //             key: 'libraries',
    //             label: 'Libraries',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library")
    //         },
    //         {
    //             key: 'libraryBooks',
    //             label: 'Books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/books")
    //         },
    //         {
    //             key: 'libraryLoans',
    //             label: 'Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/book-loans")
    //         },
    //         {
    //             key: 'myLibraryLoans',
    //             label: 'My Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/book-loans")
    //         },
    //         {
    //             key: 'eBooks',
    //             label: 'E-books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/ebooks")
    //         },
    //     ]
    // },
    // {
    //     key: '14',
    //     icon: <PieChartOutlined style={{ marginLeft: 4 }} />,
    //     label: 'School Records',
    //     children: [
    //         {
    //             key: '15',
    //             label: 'Students',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/student-reports")
    //         },
    //         {
    //             key: '16',
    //             label: 'Teacher',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/teacher-reports")
    //         }
    //     ]
    // },
    // {
    //     key: 'liveClass',
    //     icon: <VideoCameraOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Live classes',
    //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //     onClick: () => navigate('/admin/live-classes')
    // },

    {
      key: "111",
      label: "Manage Exams",
      to_destination: "/admin/examinations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/examinations"),
    },
    {
      key: "112",
      label: "Mark Exams",
      to_destination: "/admin/mark/examinations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/mark/examinations"),
    },

    {
      key: "adminStudentCoursework",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Coursework Management",
      to_destination: "/admin/coursework",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/coursework"),
    },
    {
      key: "13",
      icon: <InfoCircleOutlined style={{ marginLeft: 4 }} />,
      label: "School Information",
      to_destination: "/admin/school-information",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/school-information"),
    },
    {
      key: "e_learning",
      icon: <UserSwitchOutlined />,
      label: "E-Learning",
      to_destination: "/admin/e_learning",
      do_not_show_in_mobile_view: false,
      onClick: () => navigate("/admin/e_learning"),
    },
    {
      key: "accounts",
      icon: <DollarOutlined />,
      label: "Accounts",
      to_destination: "/admin/accounts",
      do_not_show_in_mobile_view: false,
      onClick: () => navigate("/admin/accounts"),
    },
    {
      key: "17",
      icon: <UserOutlined style={{ marginLeft: 4 }} />,
      label: "Profile",
      to_destination: "/admin/account",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/account"),
    },
    {
      key: "22",
      icon: <AuditOutlined style={{ marginLeft: 4 }} />,
      label: "System logs",
      to_destination: "/admin/system-logs",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/system-logs"),
    },
    {
      key: "23",
      data_identifier: "logout",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      do_not_show_in_mobile_view: false,
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];

  const items = [
    {
      key: 1,
      label: (
        <Link to="/" className="text-decoration-none">
          <QuestionOutlined />
          &nbsp; Help
        </Link>
      ),
    },
    {
      key: 2,
      style: { color: "#39b54a" },
      label: (
        <Link to="/superadmin/change-password" className="text-decoration-none">
          <LockFilled />
          &nbsp; Change Password
        </Link>
      ),
    },
    {
      key: 3,
      danger: true,
      label: (
        <Link to="/" className="text-decoration-none">
          <LogoutOutlined />
          &nbsp; Sign out
        </Link>
      ),
      onClick: () => authenticationService.logout(),
    },
  ];

  return (
    <>
      <Layout style={{ margin: "64px 0 0" }}>
        <Header className={"ant-nav-bar"}>
          <div className="navbar-brand ms-3 text-white d-flex justify-content-between align-items-center">
            <InstitutionLogo />
            <InstitutionName textColor="text-white" />
          </div>
          <div className={"w-100"}></div>
          <div className="d-flex flex-row align-items-center justify-content-evenly text-white">
            <Badge showZero count={0} className="mx-3">
              <BellFilled className="fs-4 text-white" />
            </Badge>
            <Dropdown className="btn btn-sm rounded-5" menu={{ items }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span className="small text-white text-nowrap">
                    <span className={"d-none d-sm-inline text-nowrap"}>
                      {fullName}{" "}
                    </span>{" "}
                    {username}
                  </span>
                  <img
                    src={facePlaceholder}
                    className={"user-nav-img"}
                    alt="User"
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <div
          className={`horizontal_sticky_menu_container ${
            isHorizontalStickyMenuHidden ? "sticky_menu_hidden" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setIsHorizontalStickyMenuHidden(
              !isMobileAppDownloadContainerIsCollapsed
            );
          }}
        >
          {isHorizontalStickyMenuHidden ? (
            <p>
              OPEN<br></br>MENU
            </p>
          ) : (
            <>
              {adminMenuItems__mobile
                .filter(
                  ({ do_not_show_in_mobile_view }) =>
                    !do_not_show_in_mobile_view
                )
                .map(
                  ({
                    key,
                    label,
                    to_destination,
                    icon,
                    data_identifier,
                    onClick,
                  }) => (
                    <Link
                      key={key}
                      to={to_destination ? to_destination : ""}
                      className={`horizontal_sticky_menu_container__item ${
                        data_identifier && data_identifier
                      }`}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                        }
                      }}
                    >
                      {icon}
                      {label}
                    </Link>
                  )
                )}
              <div className="download_mobile_app_container">
                <a href="/mobile_apps/E-Schools(v1.1.3).apk" download>
                  Download the teacher marking app
                </a>
              </div>
            </>
          )}
        </div>
        <Layout hasSider>
          <Sider
            className="sidebar_menu"
            width={240}
            theme={"dark"}
            collapsedWidth="50px"
            breakpoint="lg"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={adminMenuItems}
              theme={"dark"}
              style={{ borderRight: 0, paddingTop: 75 }}
            />
            <div
              className="download_mobile_app_container"
              onMouseEnter={() => {
                setisMobileAppDownloadContainerIsCollapsed(false);
              }}
              onMouseLeave={() => {
                setisMobileAppDownloadContainerIsCollapsed(true);
              }}
            >
              <h3>Teacher Exam Marking mobile app</h3>
              <a href="/mobile_apps/E-Schools(v1.1.3).apk" download>
                Download the app
              </a>
            </div>
          </Sider>

          <div className="mx-4 send_bulk_or_single_message_container">
            <Layout className="site-layout main_content_container">
              <BackButton />
              <h3>{bulk ? "Send Bulk Message" : "Send Individual Message"}</h3>
              <Form
                form={form}
                layout="vertical"
                id="newsletter-form"
                onFinish={handleFormSubmit}
              >
                <Card className="w-90 mx-auto">
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the message title",
                      },
                    ]}
                  >
                    <Input placeholder="Subject" size="large" />
                  </Form.Item>

                  <Form.Item
                    label="Channel of communication"
                    name="communicationChannel"
                    rules={[
                      {
                        required: true,
                        message: "Please select the channel of communication",
                      },
                    ]}
                  >
                    <Checkbox.Group
                      placeholder="Channel of communication"
                      size="large"
                      options={communicationChannelOptions}
                      onChange={handleChangeCommunicationChannel}
                    />
                  </Form.Item>

                  {bulk ? (
                    <Form.Item
                      label="Audience"
                      name="audienceOptions"
                      rules={[
                        {
                          required: true,
                          message: "Please select the target audience",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        placeholder="Audience"
                        size="large"
                        options={communicationAudienceOptions}
                        onChange={handleChangeAudience}
                      />
                    </Form.Item>
                  ) : (
                    <div className="search_and_search_results_container student_search_container">
                      <h3>Search for the message recipient</h3>
                      <div className="input_container">
                        <input
                          type="text"
                          name="student_search"
                          id="student_search"
                          placeholder="Enter name/reg #/email"
                          onChange={(e) => {
                            e.preventDefault();
                            setUsersSearchTerm(e.target.value.trim());
                          }}
                        />
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            if (usersSearchTerm === "") {
                              setUsersSearchResults([]);
                              return;
                            }
                            searchUsers(usersSearchTerm);
                          }}
                        >
                          Search
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.target.parentElement.querySelector(
                              "input"
                            ).value = "";
                            setUsersSearchResults([]);
                          }}
                        >
                          Clear search
                        </button>
                      </div>
                      <div className="results_container">
                        <h3>Select message recipient</h3>
                        {usersSearchResults.length > 0 ? (
                          <select
                            className="search_result_container"
                            onChange={(e) => {
                              const selectedOptionId =
                                e.target.selectedOptions[0].getAttribute(
                                  "data_user_id"
                                );
                              setSelectedUserId(selectedOptionId);
                            }}
                            required
                          >
                            <option>Select a user</option>
                            {usersSearchResults.map(
                              ({
                                firstName,
                                middleNames,
                                id,
                                lastName,
                                username,
                              }) => (
                                <option key={id} data_user_id={id}>
                                  <p>
                                    <b>Reg #:</b> {username ? username : ""}{" "}
                                    |&nbsp;
                                  </p>
                                  <p>
                                    <b>Name:</b> {firstName ? firstName : ""}
                                  </p>
                                  {middleNames && <p>{middleNames}</p>}
                                  <p>
                                    <b></b> {lastName ? lastName : ""}
                                  </p>
                                </option>
                              )
                            )}
                          </select>
                        ) : (
                          <option>No user found</option>
                        )}
                      </div>
                    </div>
                  )}

                  <Form.Item
                    label="Message"
                    name="message"
                    className="message_container message_body"
                  >
                    <TextEditor
                    //  style={{height: '300px', overFlowY: 'scroll'}}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Upload attachment(s)"
                    name="messageAttachmentFile"
                    // rules={[{ message: "Upload newsletter file" }]}
                  >
                    <Upload.Dragger
                      name="messageAttachmentFile"
                      type="file"
                      maxCount={1}
                      multiple={false}
                      //   listType="picture"
                      accept=".pdf,.jpg,.jpeg,.png, .mp4, .avi"
                      onChange={handleMessageAttachmentFileUpload}
                      maxFileSize={1024 * 1024 * 10}
                      beforeUpload={() => false}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag file to this area to upload
                      </p>
                      <p className="ant-upload-hint">
                        You can only upload one file at a time. The file can be
                        an image, pdf or video ( .pdf, .jpg, .jpeg, .png, .mp4
                        or .avi). The maximum file size is 10MB.
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                  <div className="newsletter_date_time_container">
                    <h3>Schedule when you want to send the message</h3>
                    <div className="publishing_options_container">
                      <Radio.Group
                        options={messageSchedulingSendingOptions}
                        defaultValue={"now"}
                        onChange={(e) => {
                          if (e.target.value === "now") {
                            form.resetFields([
                              "messageSendingScheduledDate",
                              "messageSendingScheduledTime",
                            ]);
                            setScheduledPublishDate(null);
                            setScheduledPublishTime(null);
                          } else {
                            handleDateChange();
                            handleTimeChange();
                          }
                          setWhenToPublish(e.target.value);
                        }}
                      />
                    </div>
                    {whenToPublish === "scheduled" && (
                      <div className="newsletter_date_time_container__inputs">
                        <Form.Item
                          label="Date"
                          name="messageSendingScheduledDate"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter the newsletter publishing date",
                            },
                          ]}
                        >
                          <DatePicker
                            format="DD:MM:YYYY"
                            onChange={handleDateChange}
                            value={scheduledPublishDate}
                            picker="date"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Time"
                          name="messageSendingScheduledTime"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter the newsletter publishing time",
                            },
                          ]}
                        >
                          <TimePicker
                            format="h:mm A"
                            onChange={handleTimeChange}
                            value={scheduledPublishTime}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </Card>

                <Button
                  type="primary"
                  size="large"
                  className="mt-4 px-4"
                  loading={loading}
                  disabled={disabled}
                  htmlType="submit"
                  icon={<PlusOutlined />}
                >
                  Send Bulk Message
                </Button>
              </Form>
              <SystemFooter />
            </Layout>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default SendMessage;
