import { useEffect, useState } from "react";
import { apiUrl } from "../../../http-common";
import "../../../css/search_for_students.css";
import authenticationService from "../../../services/authentication.service";
import { Link, useNavigate } from "react-router-dom";
import authHeader from "../../../services/auth-header";
import SystemFooter from "../../../common/SystemFooter";
import {
  AuditOutlined,
  BellFilled,
  DashboardOutlined,
  DollarOutlined,
  FormOutlined,
  GroupOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  LockFilled,
  LogoutOutlined,
  MessageOutlined,
  PhoneOutlined,
  PlusOutlined,
  QuestionOutlined,
  SendOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  DatePicker,
  Dropdown,
  Form,
  Layout,
  Menu,
  Space,
} from "antd";
import BackButton from "../../../common/BackButton";
import { InstitutionLogo, InstitutionName } from "../../../common";
import { Header } from "antd/es/layout/layout";
import facePlaceholder from "../../../Assets/images/user-placeholder.svg";
import Sider from "antd/es/layout/Sider";

const SearchForStudents = () => {
  const [studentsInInstitutionSearchTerm, setStudentsInInstitutionSearchTerm] =
    useState("");
  const [selectedClassRoomId, setSelectedClassRoomId] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [
    studentsInInstitutionSearchResults,
    setStudentsInInstitutionSearchResults,
  ] = useState([]);
  const [usersSearchResults, setUsersSearchResults] = useState([]);
  const [allClassRooms, setAllClassRooms] = useState([]);
  const [allLevels, setAllLevels] = useState([]);

  const tenantId = authenticationService.getUserTenantId();

  const getAllClassRooms = async () => {
    const res = await fetch(
      `${apiUrl}class-rooms/get-classrooms-by-institution-id/${tenantId}/`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );

    const response = res.json();

    response.then((data) => { 
      const formattedData = data.map((classItem) => ({
        level: `${classItem.level.name} ${classItem.name}`,
        class_id: classItem.id,
      }));
 
      setAllClassRooms(formattedData);
    });
  };

  const getAllLevels = async () => {
    const res = await fetch(
      `${apiUrl}class-rooms/get-classrooms-by-institution-id/${tenantId}/`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );

    const response = res.json();

    response.then((data) => {
      console.log("all levels", data);
      const formattedData = data.map((level) => ({
        level_name: `${level.level.name}`,
        level_id: level.id,
      }));
 
      setAllLevels(formattedData);
    });
  };

  const getLevelById = async (level_id) => {
    const res = await fetch(`${apiUrl}levels/${level_id}/`, {
      headers: {
        ...authHeader(),
      },
    });

    const response = res.json();

    response.then((data) => {
      const formattedData = data.map(({ name }) => name); 
    });
  };

  const getStudentDetailsByRegNumber = async (regNumber) => {
    const res = await fetch(
      `${apiUrl}students/get-student-by-reg-number/${regNumber}`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );

    const response = res.json();

    response.then((data) => {
      console.log("student details", [data]);
      const student_data = data.student;
      const classroom = data.classroom;
      const level_name = getLevelById(classroom.id);
      console.log(level_name.then((data) => data));
      const formattedData = [student_data].map((student) => ({
        student_model_id: student.id,
        built_in_user_model_id: student.user.id,
        firstName: student.user.firstName,
        lastName: student.user.lastName,
        username: student.user.username,
        level: level_name,
        parentPhoneNumber: student.parentPhoneNumber,
        dateOfBirth: student.dateOfBirth,
      })); 
    });
  }; 

  useEffect(() => {
    getAllClassRooms();
    getAllLevels();
    getAllStudentsByInstitutionId();
  }, []);

  useEffect(() => {
    // console.log("selectedClassRoom", selectedClassRoomId);
  }, [selectedClassRoomId]);

  const searchStudentsInInsitution = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-students-for-institution/?q=${searchTerm.trim()}&institution_id=${tenantId}`
    );

    const response = res.json();

    response.then((data) => {
      // console.log("students in institution", data);
      setStudentsInInstitutionSearchResults(data);
    });
  };

  const getAllStudentsByInstitutionId = async () => {
    const res = await fetch(
      `${apiUrl}students/get-all-students-by-institution-id/${tenantId}/`,
      {
        headers: {
          ...authHeader(),
        },
      }
    );

    const response = res.json();

    response.then((data) => {
      console.log("all students in institution", data);
      const formattedData = data.map((student) => ({
        student_model_id: student.id,
        built_in_user_model_id: student.user.id,
        firstName: student.user.firstName,
        lastName: student.user.lastName,
        username: student.user.username,
        level: student.level.name,
        parentPhoneNumber: student.parentPhoneNumber,
        dateOfBirth: student.dateOfBirth,
      }));
      setStudentsInInstitutionSearchResults(formattedData);
    });
  };

  const navigate = useNavigate();

  const username = authenticationService.getUsername();
  const fullName = authenticationService.getFullName();

  const [
    isMobileAppDownloadContainerIsCollapsed,
    setisMobileAppDownloadContainerIsCollapsed,
  ] = useState(true);

  const [isHorizontalStickyMenuHidden, setIsHorizontalStickyMenuHidden] =
    useState(true);

  const adminMenuItems = [
    {
      key: "1",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Dashboard",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/"),
    },
    {
      key: "2",
      icon: <SolutionOutlined style={{ marginLeft: 4 }} />,
      label: "Student Management",
      children: [
        {
          key: "3",
          label: "Students",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/students"),
        },
        {
          key: "4",
          label: "Admissions",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/new-student"),
        },
        {
          key: "search_for_students",
          label: "Search",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/search"),
        },
      ],
    },
    {
      key: "18",
      icon: <TeamOutlined style={{ marginLeft: 4 }} />,
      label: "Teacher Management",
      children: [
        {
          key: "19",
          label: "Teachers",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/teachers"),
        },
        {
          key: "20",
          label: "Register",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/new-teacher"),
        },
      ],
    },
    {
      key: "parents",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Parent Management",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/parents"),
    },
    {
      key: "6",
      icon: <GroupOutlined style={{ marginLeft: 4 }} />,
      label: "Classroom Management",
      children: [
        {
          key: "7",
          label: "Classes",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/student-classes"),
        },
        {
          key: "8",
          label: "Subject Allocations",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/subject-allocations"),
        },
      ],
    },
    {
      key: "10",
      icon: <SendOutlined style={{ marginLeft: 4 }} />,
      label: "Communications",
      children: [
        // {
        //     key: '11',
        //     icon: <MessageOutlined style={{ marginLeft: 4 }} />,
        //     label: 'SMS Management',
        //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
        //     onClick: () => navigate("/admin/sms-information"),
        //     disabled: true
        // },
        {
          key: "12",
          icon: <InboxOutlined style={{ marginLeft: 4 }} />,
          label: "Newsletters",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/newsletter"),
        },
        {
          key: "14",
          icon: <MessageOutlined style={{ marginLeft: 4 }} />,
          label: "Send Bulk Message",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/send_bulk_message"),
        },
        {
          key: "24",
          icon: <MessageOutlined style={{ marginLeft: 4 }} />,
          label: "Send Individual Message",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/send_individual_message"),
        },
      ],
    },
    // {
    //     key: 'hr',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Ancillary Staff Management',
    //     children: [
    //         {
    //             key: 'hr1',
    //             label: 'Staff',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff")
    //         },
    //         {
    //             key: 'hr2',
    //             label: 'Payroll',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff/payroll-records")
    //         },
    //     ]
    // },
    // {
    //     disabled: true,
    //     key: 'accounting',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Accounts',
    //     children: [
    //         {
    //             key: 'transactions',
    //             label: 'Transactions',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/transactions")
    //         },
    //         {
    //             key: 'income_statements',
    //             label: 'Income Statement',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/income-statement")
    //         },
    //     ]
    // },
    // {
    //     key: 'assetManagement',
    //     icon: <CarOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Asset Management',
    //     disabled: false,
    //     children: [
    //         {
    //             key: 'vehicle',
    //             label: 'Vehicles',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/vehicles")
    //         },
    //         {
    //             key: 'electronics',
    //             label: 'Electronics',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/electronics")
    //         },
    //         {
    //             key: 'general_asset',
    //             label: 'General Assets',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/general-assets")
    //         }
    //     ]
    // },
    // {
    //     key: 'library',
    //     icon: <BookOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Library Management',
    //     children: [
    //         {
    //             key: 'libraries',
    //             label: 'Libraries',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library")
    //         },
    //         {
    //             key: 'libraryBooks',
    //             label: 'Books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/books")
    //         },
    //         {
    //             key: 'libraryLoans',
    //             label: 'Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/book-loans")
    //         },
    //         {
    //             key: 'myLibraryLoans',
    //             label: 'My Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/book-loans")
    //         },
    //         {
    //             key: 'eBooks',
    //             label: 'E-books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/ebooks")
    //         },
    //     ]
    // },
    // {
    //     key: '14',
    //     icon: <PieChartOutlined style={{ marginLeft: 4 }} />,
    //     label: 'School Records',
    //     children: [
    //         {
    //             key: '15',
    //             label: 'Students',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/student-reports")
    //         },
    //         {
    //             key: '16',
    //             label: 'Teacher',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/teacher-reports")
    //         }
    //     ]
    // },
    // {
    //     key: 'liveClass',
    //     icon: <VideoCameraOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Live classes',
    //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //     onClick: () => navigate('/admin/live-classes')
    // },

    {
      key: "110",
      icon: <FormOutlined style={{ marginLeft: 4 }} />,
      label: "Exam Management",
      children: [
        {
          key: "111",
          label: "Manage Exams",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/examinations"),
        },
        {
          key: "112",
          label: "Mark Exams",
          style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
          onClick: () => navigate("/admin/mark/examinations"),
        },
      ],
    },
    {
      key: "adminStudentCoursework",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Coursework Management",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/coursework"),
    },
    {
      key: "13",
      icon: <InfoCircleOutlined style={{ marginLeft: 4 }} />,
      label: "School Information",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/school-information"),
    },
    {
      key: "e_learning",
      icon: <UserSwitchOutlined />,
      label: "E-Learning",
      onClick: () => navigate("/admin/e_learning"),
    },
    {
      key: "accounts",
      icon: <DollarOutlined />,
      label: "Accounts",
      onClick: () => navigate("/admin/accounts"),
    },
    {
      key: "17",
      icon: <UserOutlined style={{ marginLeft: 4 }} />,
      label: "Profile",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/account"),
    },
    {
      key: "21",
      icon: <PhoneOutlined style={{ marginLeft: 4 }} />,
      label: "Support",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
    },
    {
      key: "22",
      icon: <AuditOutlined style={{ marginLeft: 4 }} />,
      label: "System logs",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/system-logs"),
    },
    {
      key: "23",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];

  const adminMenuItems__mobile = [
    {
      key: "1",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Dashboard",
      to_destination: "/",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/"),
    },
    {
      key: "3",
      label: "Students",
      to_destination: "/admin/students",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/students"),
    },
    {
      key: "4",
      label: "Admissions",
      to_destination: "/admin/new-student",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/new-student"),
    },
    {
      key: "search_for_students_mobile",
      to_destination: "/admin/search",
      do_not_show_in_mobile_view: false,
      label: "Search",
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/search"),
    },
    {
      key: "19",
      label: "Teachers",
      to_destination: "/admin/teachers",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/teachers"),
    },
    {
      key: "20",
      label: "Register Teacher",
      to_destination: "/admin/new-teacher",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/new-teacher"),
    },
    {
      key: "parents",
      icon: <DashboardOutlined style={{ marginLeft: 4 }} />,
      label: "Parent Management",
      to_destination: "/admin/parents",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/parents"),
    },
    {
      key: "7",
      label: "Classes",
      to_destination: "/admin/student-classes",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/student-classes"),
    },
    {
      key: "8",
      label: "Subject Allocations",
      to_destination: "/admin/subject-allocations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/subject-allocations"),
    },
    {
      key: "12",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Newsletters",
      to_destination: "/admin/newsletter",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/newsletter"),
    },
    {
      key: "15",
      icon: <MessageOutlined style={{ marginLeft: 4 }} />,
      label: "Send Bulk Message",
      to_destination: "/admin/send_bulk_message",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/send_bulk_message"),
    },
    {
      key: "25",
      icon: <MessageOutlined style={{ marginLeft: 4 }} />,
      label: "Send Individual Message",
      to_destination: "/admin/send_individual_message",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/send_individual_message"),
    },
    // {
    //     key: 'hr',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Ancillary Staff Management',
    //     children: [
    //         {
    //             key: 'hr1',
    //             label: 'Staff',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff")
    //         },
    //         {
    //             key: 'hr2',
    //             label: 'Payroll',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/ancillary-staff/payroll-records")
    //         },
    //     ]
    // },
    // {
    //     disabled: true,
    //     key: 'accounting',
    //     icon: <IdcardOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Accounts',
    //     children: [
    //         {
    //             key: 'transactions',
    //             label: 'Transactions',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/transactions")
    //         },
    //         {
    //             key: 'income_statements',
    //             label: 'Income Statement',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/accounting/income-statement")
    //         },
    //     ]
    // },
    // {
    //     key: 'assetManagement',
    //     icon: <CarOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Asset Management',
    //     disabled: false,
    //     children: [
    //         {
    //             key: 'vehicle',
    //             label: 'Vehicles',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/vehicles")
    //         },
    //         {
    //             key: 'electronics',
    //             label: 'Electronics',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/electronics")
    //         },
    //         {
    //             key: 'general_asset',
    //             label: 'General Assets',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/general-assets")
    //         }
    //     ]
    // },
    // {
    //     key: 'library',
    //     icon: <BookOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Library Management',
    //     children: [
    //         {
    //             key: 'libraries',
    //             label: 'Libraries',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library")
    //         },
    //         {
    //             key: 'libraryBooks',
    //             label: 'Books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/books")
    //         },
    //         {
    //             key: 'libraryLoans',
    //             label: 'Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/library/book-loans")
    //         },
    //         {
    //             key: 'myLibraryLoans',
    //             label: 'My Book Loans',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/book-loans")
    //         },
    //         {
    //             key: 'eBooks',
    //             label: 'E-books',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("library/ebooks")
    //         },
    //     ]
    // },
    // {
    //     key: '14',
    //     icon: <PieChartOutlined style={{ marginLeft: 4 }} />,
    //     label: 'School Records',
    //     children: [
    //         {
    //             key: '15',
    //             label: 'Students',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/student-reports")
    //         },
    //         {
    //             key: '16',
    //             label: 'Teacher',
    //             style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //             onClick: () => navigate("admin/teacher-reports")
    //         }
    //     ]
    // },
    // {
    //     key: 'liveClass',
    //     icon: <VideoCameraOutlined style={{ marginLeft: 4 }} />,
    //     label: 'Live classes',
    //     style: { borderRadius: 0, margin: '0 0 5px', width: '100%' },
    //     onClick: () => navigate('/admin/live-classes')
    // },

    {
      key: "111",
      label: "Manage Exams",
      to_destination: "/admin/examinations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/examinations"),
    },
    {
      key: "112",
      label: "Mark Exams",
      to_destination: "/admin/mark/examinations",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/mark/examinations"),
    },

    {
      key: "adminStudentCoursework",
      icon: <InboxOutlined style={{ marginLeft: 4 }} />,
      label: "Coursework Management",
      to_destination: "/admin/coursework",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/coursework"),
    },
    {
      key: "13",
      icon: <InfoCircleOutlined style={{ marginLeft: 4 }} />,
      label: "School Information",
      to_destination: "/admin/school-information",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/school-information"),
    },
    {
      key: "e_learning",
      icon: <UserSwitchOutlined />,
      label: "E-Learning",
      to_destination: "/admin/e_learning",
      do_not_show_in_mobile_view: false,
      onClick: () => navigate("/admin/e_learning"),
    },
    {
      key: "accounts",
      icon: <DollarOutlined />,
      label: "Accounts",
      to_destination: "/admin/accounts",
      do_not_show_in_mobile_view: false,
      onClick: () => navigate("/admin/accounts"),
    },
    {
      key: "17",
      icon: <UserOutlined style={{ marginLeft: 4 }} />,
      label: "Profile",
      to_destination: "/admin/account",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/account"),
    },
    {
      key: "22",
      icon: <AuditOutlined style={{ marginLeft: 4 }} />,
      label: "System logs",
      to_destination: "/admin/system-logs",
      do_not_show_in_mobile_view: false,
      style: { borderRadius: 0, margin: "0 0 5px", width: "100%" },
      onClick: () => navigate("/admin/system-logs"),
    },
    {
      key: "23",
      data_identifier: "logout",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      do_not_show_in_mobile_view: false,
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];

  const items = [
    {
      key: 1,
      label: (
        <Link to="/" className="text-decoration-none">
          <QuestionOutlined />
          &nbsp; Help
        </Link>
      ),
    },
    {
      key: 2,
      style: { color: "#39b54a" },
      label: (
        <Link to="/superadmin/change-password" className="text-decoration-none">
          <LockFilled />
          &nbsp; Change Password
        </Link>
      ),
    },
    {
      key: 3,
      danger: true,
      label: (
        <Link to="/" className="text-decoration-none">
          <LogoutOutlined />
          &nbsp; Sign out
        </Link>
      ),
      onClick: () => authenticationService.logout(),
    },
  ];

  return (
    <>
      <Layout style={{ margin: "64px 0 0" }}>
        <Header className={"ant-nav-bar"}>
          <div className="navbar-brand ms-3 text-white d-flex justify-content-between align-items-center">
            <InstitutionLogo />
            <InstitutionName textColor="text-white" />
          </div>
          <div className={"w-100"}></div>
          <div className="d-flex flex-row align-items-center justify-content-evenly text-white">
            <Badge showZero count={0} className="mx-3">
              <BellFilled className="fs-4 text-white" />
            </Badge>
            <Dropdown className="btn btn-sm rounded-5" menu={{ items }}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <span className="small text-white text-nowrap">
                    <span className={"d-none d-sm-inline text-nowrap"}>
                      {fullName}{" "}
                    </span>{" "}
                    {username}
                  </span>
                  <img
                    src={facePlaceholder}
                    className={"user-nav-img"}
                    alt="User"
                  />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
        <div
          className={`horizontal_sticky_menu_container ${
            isHorizontalStickyMenuHidden ? "sticky_menu_hidden" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setIsHorizontalStickyMenuHidden(
              !isMobileAppDownloadContainerIsCollapsed
            );
          }}
        >
          {isHorizontalStickyMenuHidden ? (
            <p>
              OPEN<br></br>MENU
            </p>
          ) : (
            <>
              {adminMenuItems__mobile
                .filter(
                  ({ do_not_show_in_mobile_view }) =>
                    !do_not_show_in_mobile_view
                )
                .map(
                  ({
                    key,
                    label,
                    to_destination,
                    icon,
                    data_identifier,
                    onClick,
                  }) => (
                    <Link
                      key={key}
                      to={to_destination ? to_destination : ""}
                      className={`horizontal_sticky_menu_container__item ${
                        data_identifier && data_identifier
                      }`}
                      onClick={() => {
                        if (onClick) {
                          onClick();
                        }
                      }}
                    >
                      {icon}
                      {label}
                    </Link>
                  )
                )}
              <div className="download_mobile_app_container">
                <a href="/mobile_apps/E-Schools(v1.1.3).apk" download>
                  Download the teacher marking app
                </a>
              </div>
            </>
          )}
        </div>
        <Layout hasSider>
          <Sider
            className="sidebar_menu"
            width={240}
            theme={"dark"}
            collapsedWidth="50px"
            breakpoint="lg"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={adminMenuItems}
              theme={"dark"}
              style={{ borderRight: 0, paddingTop: 75 }}
            />
            <div
              className="download_mobile_app_container"
              onMouseEnter={() => {
                setisMobileAppDownloadContainerIsCollapsed(false);
              }}
              onMouseLeave={() => {
                setisMobileAppDownloadContainerIsCollapsed(true);
              }}
            >
              <h3>Teacher Exam Marking mobile app</h3>
              <a href="/mobile_apps/E-Schools(v1.1.3).apk" download>
                Download the app
              </a>
            </div>
          </Sider>

          <div className="mx-4 search_and_search_results_container">
            <Layout className="site-layout main_content_container">
              <BackButton />
              <div className="search_for_students">
                <h1>Student Search</h1>

                {/* <div className="institution_student_search_container"> */}
                  <h2>Search for students</h2>
                  <div className="input_container">
                    <input
                      type="text"
                      name="search_students_in_institution"
                      id="search_students_in_institution"
                      placeholder="Enter student name or reg number"
                      onChange={(e) => {
                        e.preventDefault();
                        setStudentsInInstitutionSearchTerm(
                          e.target?.value?.trim()
                        );
                      }}
                    />
                    <button
                      onClick={() => {
                        searchStudentsInInsitution(
                          studentsInInstitutionSearchTerm
                        );
                      }}
                      className="search_using_query"
                    >
                      Search
                    </button>
                    <button
                      onClick={() => {
                        getAllStudentsByInstitutionId();
                      }}
                    >
                      Display all students in school
                    </button>
                    <button
                      onClick={(e) => {
                        e.target.parentElement.querySelector("input").value =
                          "";
                        setStudentsInInstitutionSearchResults([]);
                      }}
                      className="clear_search"
                    >
                      Clear search
                    </button>
                    <div className="search_by_class">
                      {allLevels && (
                        <select
                          onChange={(e) => {
                            const selectedOption =
                              e.target.selectedOptions[0].value;
                            setSelectedLevel(selectedOption);
                          }}
                        >
                          <option data_class_id={-1}>Select level</option>
                          {allLevels.map((level) => (
                            <option>{level.level_name}</option>
                          ))}
                        </select>
                      )}
                      <button
                        onClick={() => { 
                          searchStudentsInInsitution(selectedLevel);
                        }}
                      >
                        Display students in level/grade
                      </button>
                    </div>
                  </div>

                  <h3>Student search results</h3>
                  <div className="results_container">
                    {studentsInInstitutionSearchResults?.length > 0 ? (
                      <table>
                        <thead>
                          <tr>
                            <th>Student first name:</th>
                            <th>Student last name:</th>
                            <th>Student reg # / user name:</th>
                            <th>Student level/grade:</th>
                            <th>Student date of birth:</th>
                            <th>Parent phone number:</th>
                            <th>Edit student details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentsInInstitutionSearchResults?.map(
                            (
                              {
                                student_model_id,
                                firstName,
                                lastName,
                                username,
                                parentPhoneNumber,
                                level,
                                dateOfBirth,
                              },
                              index
                            ) => (
                              <tr key={index}>
                                <td>{firstName ? firstName : ""}</td>
                                <td>{lastName ? lastName : ""}</td>
                                <td>{username ? username : ""}</td>
                                <td>{level ? level : ""}</td>
                                <td>{dateOfBirth ? dateOfBirth : ""}</td>
                                <td>
                                  {parentPhoneNumber?.length
                                    ? parentPhoneNumber
                                    : ""}
                                </td>
                                <td>
                                  <Link
                                    to={`/admin/students/${student_model_id}/edit`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <p>No students found</p>
                    )}
                  </div>
                {/* </div> */}
              </div>

              <SystemFooter />
            </Layout>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default SearchForStudents;
