import NewsletterService from "../../../services/newsletter.service";
import { useNavigate, useLoaderData, useParams, Link } from "react-router-dom";
import { fallbackImg, toHumanDate } from "../../../common";
import {
  ArrowLeftOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PictureOutlined,
  PlusOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Divider, Tag, Tooltip, Typography } from "antd";
import "./newsletter.css";
import AuthenticationService from "../../../services/authentication.service";
import { useEffect, useState } from "react";
import UpdateImageModal from "./UpdateImageModal";

export async function newsletterLoader({ params }) {
  try {
    const newsletterResponse = await NewsletterService.newGetAllNewslettersById(
      params.id
    );
    return { newsletter: newsletterResponse.data };
  } catch (e) {
    console.log(e);
  }
}

const NewsletterDetail = () => {
  const url = window.location.pathname;
  const parts = url.split("/");
  const id = parts[3];
  const user_role = AuthenticationService.getUserRole();

  const [newsletterData, setNewletterData] = useState({});
  const [selectedNewsletter, setSelectedNewsletter] = useState(null);
  const [openChangeImageModal, setOpenChangeImageModal] = useState(false);

  const { newsletter } = useLoaderData();
  const navigate = useNavigate();
  const closeCreateNewsletterModal = () => {
    setSelectedNewsletter(null);
    setOpenChangeImageModal(false);
  };

  async function singleNewsLetterLoader(id) {
    try {
      const singleNewsletterResponse =
        await NewsletterService.newGetNewsletterById(id);
      setNewletterData(singleNewsletterResponse.data);
      return { singleNewsletterResponse };
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    singleNewsLetterLoader(id);
  }, [id]);

  //   useEffect(() => {
  //     console.log("newsletter id", id);
  //     console.log("newsletter data", newsletterData);
  //   }, [id, newsletterData]);

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className="text-muted text-decoration-none mb-2 d-flex align-items-center"
        type="text"
      >
        <ArrowLeftOutlined /> Back
      </Button>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Newsletter</h3>
        {(AuthenticationService.getUserRole() === "SUPER-SCHOOL-ADMIN" || AuthenticationService.getUserRole() === "ADMIN") && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="border-0 px-3 text-white"
            onClick={() =>
              navigate(`/admin/newsletter/${newsletterData?.id}/update`)
            }
          >
            Edit Newsletter
          </Button>
        )}
      </div>

      <Divider />

      {/* <div className="newsletter-large">
        <Card
          style={{ height: "100%", width: "300px" }}
          cover={
            <img
              alt="example"
              id={`img-${newsletterData?.id}`}
              style={{ objectFit: "cover", height: "150px" }}
              src={newsletterData?.image || fallbackImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = fallbackImg;
              }}
            />
          }
        >
          <h5>Title: {newsletterData?.title}</h5>
          <small>
            To:{" "}
            <Tag bordered={false} color={"blue"}>
              {newsletterData?.audience &&
                Array(newsletterData?.audience).map((intended_audience) => (
                  <span class="audience_tag">{intended_audience}</span>
                ))}
            </Tag>
          </small>
          <Divider />
          <Typography.Paragraph className="mt-2">
            <div dangerouslySetInnerHTML={{ __html: newsletterData.body }} />
          </Typography.Paragraph>
          <Divider />
          <div className="d-md-flex justify-content-between align-items-center"> 
            <span>
              Date created: {toHumanDate(newsletterData?.date_created)}
            </span>
          </div>
        </Card>
      </div> */}
      <Badge.Ribbon
        placement="start"
        text={
         !( user_role === "ADMIN" || user_role === "SUPER-SCHOOL-ADMIN")
            ? ""
            : newsletterData.is_published
            ? "Published"
            : "Not Published"
        }
        color={
          !( user_role === "ADMIN" || user_role === "SUPER-SCHOOL-ADMIN")
            ? "transparent"
            : newsletterData.is_published
            ? "green"
            : "red"
        }
      >
        <Card
          hoverable={false}
          style={{ minHeight: 392, height: "100%", position: "relative" }}
          cover={
            <img
              alt="example"
              id={`img-${newsletterData.id}`}
              height={200}
              style={{ objectFit: "cover" }}
              src={newsletterData.image || fallbackImg}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = fallbackImg;
              }}
            />
          }
          className="newsletter_container"
        >
          <h5>{newsletterData.title}</h5>
          <small>
            To:{" "}
            <Tag bordered={false} color={"blue"}>
              {newsletterData.audience}
            </Tag>
          </small>
          <Typography.Paragraph
            ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
            className="mt-2"
          >
            <div dangerouslySetInnerHTML={{ __html: newsletterData.body }} />
          </Typography.Paragraph>
          <div className="d-md-flex justify-content-between align-items-center">
            <span>
              Date created: {toHumanDate(newsletterData.date_created)}
            </span>
          </div>
          {newsletterData.pdf_file && (
            <div className="d-md-flex justify-content-between align-items-center newsletter_file">
              <a
                href={newsletterData.pdf_file}
                download
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation()
                  // const link = document.createElement("a");
                  // link.href = newsletterData.pdf_file;
                  // link.download = "";
                  // document.body.appendChild(link)
                  // link.click();
                  // document.body.removeChild(link)
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Download newsletter file
              </a>
            </div>
          )}
          {(user_role === "ADMIN" || user_role === "SUPER-SCHOOL-ADMIN") && (
            <Tooltip title="Options">
              <Button
                data-bs-toggle="dropdown"
                aria-expanded="false"
                type="text"
                shape="circle"
                icon={<MoreOutlined />}
                style={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              />
            </Tooltip>
          )}
          {(user_role === "ADMIN" || user_role === "SUPER-SCHOOL-ADMIN") && (
            <ul className="dropdown-menu">
              <li>
                <button
                  onClick={() => {
                    setSelectedNewsletter(newsletterData);
                    setOpenChangeImageModal(true);
                  }}
                  className="dropdown-item"
                >
                  <PictureOutlined /> Change Image
                </button>
              </li>
            </ul>
          )}
        </Card>
      </Badge.Ribbon>
      {(user_role === "ADMIN" || user_role === "SUPER-SCHOOL-ADMIN") && (
        <UpdateImageModal
          open={openChangeImageModal}
          close={() => closeCreateNewsletterModal()}
          newsletter={selectedNewsletter}
        />
      )}
    </>
  );
};

export default NewsletterDetail;
