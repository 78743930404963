import { Badge, Dropdown, Layout, Menu, Spin } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { InstitutionLogo } from "../../../common";
import SystemFooter from "../../../common/SystemFooter";
import {
  BarChartOutlined,
  BellFilled,
  DashboardOutlined,
  DollarOutlined,
  FileTextOutlined,
  InboxOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Link, Outlet, useNavigate, useNavigation } from "react-router-dom";
import authenticationService from "../../../services/authentication.service";
import Sider from "antd/es/layout/Sider";
import CompleteStudentRegistration from "./regparentstudentsms";
import { useEffect, useState } from "react";
import studentService from "../../../services/student.service";
import "./parent_has_not_yet_completed_student_registration.css";
import parentService from "../../../services/parent.service";

const ParentHasNotYetCompletedStudentRegistration = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [students, setStudents] = useState([]);
  const parentId = authenticationService.getUserId();
  const parentPhoneNumber = authenticationService.getUserPhoneNumber();
  const [parentData, setParentData] = useState({});
  const [parentFullName, setParentFullName] = useState("");

  const first_name = authenticationService.getFirstName();
  const last_name = authenticationService.getLastName();

  async function fetchParentStudentsData() {
    try {
      const childrenResponse = await studentService.getChildrenOfParent(
        authenticationService.getUserId()
      );
      setStudents(childrenResponse.data);
    } catch (e) {
      return [];
    }
  }

  async function fetchParentDetails() {
    try {
      const parentData = await parentService.getParentByUserId(
        authenticationService.getUserId()
      );
      setParentData(parentData?.data); 
      setParentFullName(parentData?.data?.user?.firstName + " " + parentData?.data?.user?.lastName);
    } catch (e) {
      return {};
    }
  }
 
  useEffect(() => { 
    fetchParentDetails();
  }, []);

  const hasCompletedRegistration =
    students?.length > 0 && !(first_name === null || last_name === null);

  useEffect(() => {
    fetchParentStudentsData(); 
  }, [hasCompletedRegistration]);

  const parentMenuItems = [
    {
      key: "7",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];

  const parentMenuItems__mobile = [
    {
      key: "7",
      data_identifier: "logout",
      icon: <LogoutOutlined style={{ marginLeft: 4 }} />,
      label: "Logout",
      style: {
        borderRadius: 0,
        margin: "0 0 5px",
        width: "100%",
        backgroundColor: "red",
      },
      onClick: () => authenticationService.logout(),
    },
  ];
 
  return (
    <div className="parent_has_not_completed_registration">
      <Layout style={{ margin: "66px 0 0" }}>
        <Header className={"ant-nav-bar"}>
          <InstitutionLogo />
          <div className="parent_name_container">
            Welcome {parentFullName}
          </div>
        </Header>

        <div className="horizontal_sticky_menu_container">
          {parentMenuItems__mobile
            .filter(
              ({ do_not_show_in_mobile_view }) => !do_not_show_in_mobile_view
            )
            .map(
              ({
                key,
                label,
                to_destination,
                icon,
                data_identifier,
                onClick,
              }) => (
                <Link
                  key={key}
                  to={to_destination && to_destination}
                  className={`horizontal_sticky_menu_container__item ${
                    data_identifier && data_identifier
                  }`}
                  onClick={() => {
                    if (onClick) {
                      onClick();
                    }
                  }}
                >
                  {icon}
                  {label}
                </Link>
              )
            )}
        </div>
        <Layout hasSider>
          <Sider
            className="sidebar_menu"
            width={240}
            theme={"dark"}
            collapsedWidth="50px"
            breakpoint="lg"
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              items={parentMenuItems}
              theme={"dark"}
              style={{ borderRight: 0, paddingTop: 75 }}
            />
          </Sider>

          <Layout className="site-layout">
            <div className="students">
              {students?.length > 0 ? (
                <>
                  <h2>
                    {students?.length === 1 ? "Your Child:" : "Your Children:"}
                  </h2>
                  {students.map((student, index) => (
                    <div key={index} className="student_container">
                      <p
                        className={`student_parent_registration_status${
                          student?.user?.username &&
                          student?.user?.firstName &&
                          student?.dateOfBirth &&
                          student?.user?.lastName
                            ? " registration_completed_color"
                            : ""
                        }`}
                      ></p>
                      <p>
                        <b>Student Reg Number:</b> {student?.user?.username}
                      </p>
                      <p>
                        <b>Full Name:</b> {student?.user?.firstName}{" "}
                        {student?.user?.lastName}
                      </p>
                      {!(
                        student?.user?.username &&
                        student?.dateOfBirth &&
                        student?.user?.firstName &&
                        student?.user?.lastName
                      ) ? (
                        <Link
                          to={`/parent/reg/studentid/${
                            student.user.id
                          }/parentid/${parentId}?f=${
                            student?.user?.firstName
                          }&m=${student?.user?.middleNames || ""}&l=${
                            student?.user?.lastName
                          }&p=${parentPhoneNumber}`}
                        >
                          Complete registration
                        </Link>
                      ) : (
                        <p className="registration_completed_status registration_completed_color">
                          Registration already completed
                        </p>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <h3>No students linked to your account</h3>
              )}
            </div>
            <SystemFooter />
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};

export default ParentHasNotYetCompletedStudentRegistration;
