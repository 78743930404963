import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Spin,
  TimePicker,
  Upload,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import {
  InboxOutlined,
  PlusOutlined,
  LoadingOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import authenticationService from "../../../services/authentication.service";
import { handleErrorWithoutDisplayingErrorName } from "../../../common";
import NewsletterService from "../../../services/newsletter.service";
import TextEditor from "../../../common/TextEditor";
import BackButton from "../../../common/BackButton";
import { useNavigate } from "react-router-dom";
import "./newsletter.css";
import "../Communications/send_message.css";
import { apiUrl } from "../../../http-common";
import dayjs from "dayjs";

const CreateNewsletter = () => {
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [newsletterFile, setNewsletterFile] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [whenToPublish, setWhenToPublish] = useState("now");
  const [scheduledPublishDate, setScheduledPublishDate] = useState(null);
  const [scheduledPublishTime, setScheduledPublishTime] = useState(null);
  const [audienceOptions, setAudienceOptions] = useState([]);
  const [communicationOptions, setCommunicationOptions] = useState([]);
  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [usersSearchResults, setUsersSearchResults] = useState([]);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(true);
  const [hasSearchedForRecipients, setHasSearchedForRecipients] = useState(false);
  const [selectedGroupOfReceipients, setSelectedGroupOfReceipients] = useState([]);
  const [shouldSelectMultipleRecipients, setShouldSelectMultipleRecipients] = useState(false);
  const [iDsOfMultipleParents, setIDsOfMultipleParents] = useState([]);
  const [selectedUsersIDs, setSelectedUserIDs] = useState("");
  const [isAllAudiencesOptionChecked, setIsAllAudiencesOptionChecked] = useState(false);
  const [isAllCommunicationChannelOptionChecked, setIsAllCommunicationChannelOptionChecked] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewContent, setPreviewContent] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [pdfUploaded, setPdfUploaded] = useState(false);
  const navigate = useNavigate();

  const institution = authenticationService.getUserTenantId();

  const validateFile = (file, type) => {
    const isImage = type === "image";
    const isSizeValid = file.size / 1024 / 1024 < (isImage ? 10 : 20);
    const isTypeValid = isImage
      ? file.type.startsWith("image/")
      : file.type === "application/pdf";

    if (!isTypeValid) {
      notification.error({
        message: "Invalid File Type",
        description: isImage
          ? "Please upload an image file (jpg, jpeg, png)."
          : "Please upload a PDF file.",
        placement: "topRight",
      });
      return false;
    }

    if (!isSizeValid) {
      notification.error({
        message: "File Too Large",
        description: `File must be smaller than ${isImage ? "10MB" : "20MB"}.`,
        placement: "topRight",
      });
      return false;
    }

    return true;
  };

  const handleFileUpload = ({ fileList }) => {
    if (fileList.length === 0) {
      setImage(null);
      setPreviewImage(null);
      setImageUploaded(false);
      return;
    }
    
    const file = fileList[0]?.originFileObj;
    
    if (file && validateFile(file, "image")) {
      setImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewImage(reader.result);
        setImageUploaded(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleNewsLetterFileUpload = ({ fileList }) => {
    if (fileList.length === 0) {
      setNewsletterFile(null);
      setPdfUploaded(false);
      return;
    }
    
    const file = fileList[0]?.originFileObj;
    
    if (file && validateFile(file, "pdf")) {
      setNewsletterFile(file);
      setPdfUploaded(true);
      notification.success({
        message: "PDF Uploaded",
        description: "Newsletter PDF file has been added successfully.",
        placement: "topRight",
      });
    }
  };

  const getCurrentDateTimeInSimpleFormat = () => {
    const currentDateTime = new Date();
    currentDateTime.setSeconds(currentDateTime.getSeconds() + 30);
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const year = currentDateTime.getFullYear();
    const formattedCurrentDate = `${year}-${month}-${day}`;
    const hours = currentDateTime.getHours();
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");
    const formattedCurrentTime = `${hours}:${minutes}:${seconds}`;

    return {
      formattedCurrentDate,
      formattedCurrentTime,
    };
  };

  const handleDateChange = (date, dateString) => {
    setScheduledPublishDate(dateString);
  };

  const handleTimeChange = (time, timeString) => {
    setScheduledPublishTime(timeString);
  };

  const handleChangeAudience = (selectedOptions) => {
    // Only store the last selected value to ensure we only have one value
    const selectedValue = Array.isArray(selectedOptions)
      ? selectedOptions[selectedOptions.length - 1]
      : selectedOptions;
    setAudienceOptions([selectedValue]);
  };

  const handleClick = (e) => {
    if (e.target.value === "group_of_parents") {
      if (shouldSelectMultipleRecipients) {
        setUsersSearchTerm("");
        setUsersSearchResults([]);
        setIDsOfMultipleParents([]);
        setHasSearchedForRecipients(false);
      }
      setShouldSelectMultipleRecipients(!shouldSelectMultipleRecipients);
    }
  };

  const handleChangeCommunicationChannel = (selectedOptions) => {
    if (selectedOptions.includes("All")) {
      setCommunicationOptions(["All"]);
      return;
    }
    setCommunicationOptions(selectedOptions);
  };

  const checkIfAllOptionHasBeenSelected = (stateVariableOptions, setterFunction) => {
    if (stateVariableOptions.includes("All")) {
      setterFunction(["All"]);
    }
  };

  const searchUsers = async (searchTerm) => {
    setUsersSearchResults([]);
    setIDsOfMultipleParents([]);
    setIsLoadingSearchResults(true);
    if (searchTerm.trim().length === 0) {
      setUsersSearchResults([]);
      return;
    }

    setHasSearchedForRecipients(true);
    const res = await fetch(`${apiUrl}accounts/search-users/?q=${searchTerm.toLowerCase().trim()}`);
    const response = res.json();

    response.then((data) => {
      const filtered_data = data.filter(({ role }) => role === "PARENT");
      setUsersSearchResults(filtered_data);
      setIsLoadingSearchResults(false);
    });
  };

  const handleAddOrRemoveFromMultipleParents = (parent_id) => {
    const current_multiple_parents = [...iDsOfMultipleParents];
    let new_multiple_parents = [];
    if (current_multiple_parents.includes(parent_id)) {
      new_multiple_parents = current_multiple_parents.filter(
        (id) => id !== parent_id
      );
    } else {
      new_multiple_parents = [...iDsOfMultipleParents, parent_id];
    }
    setIDsOfMultipleParents(new_multiple_parents);
  };

  const validateScheduledTime = (date, time) => {
    if (!date || !time) return false;
    const scheduledDateTime = new Date(`${date} ${time}`);
    const now = new Date();
    return scheduledDateTime > now;
  };

  const handleFormSubmit = async (values) => {
    try {
      if (
        whenToPublish === "scheduled" &&
        !validateScheduledTime(scheduledPublishDate, scheduledPublishTime)
      ) {
        notification.error({
          message: "Invalid Schedule Time",
          description: "Please select a future date and time for scheduling.",
          placement: "topRight",
        });
        return;
      }

      // Validate audience selection
      if (!audienceOptions.length) {
        notification.warning({
          message: "No Audience Selected",
          description: "Please select at least one audience group for the newsletter.",
          placement: "topRight",
        });
        return;
      }

      // Validate communication channels
      if (!communicationOptions.length) {
        notification.warning({
          message: "No Communication Channel Selected",
          description: "Please select at least one communication channel.",
          placement: "topRight",
        });
        return;
      }

      setLoading(true);
      setDisabled(true);

      const formData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        if (key !== "image" && key !== "is_published" && key !== "audience" && key !== "media_channel") {
          formData.append(key, value);
        }
      }

      if (whenToPublish !== "now") {
        formData.set("scheduled_publish_time", `${scheduledPublishDate} ${scheduledPublishTime}`);
      } else {
        formData.set(
          "scheduled_publish_time",
          `${getCurrentDateTimeInSimpleFormat().formattedCurrentDate} ${getCurrentDateTimeInSimpleFormat().formattedCurrentTime}`
        );
      }

      formData.set("is_published", "False");
      
      // Send audience value directly without any JSON.stringify
      formData.set("audience", audienceOptions[0] || "");
      
      // Handle multiple parents only for group_of_parents
      if (audienceOptions[0] === "group_of_parents" && iDsOfMultipleParents.length > 0) {
        formData.set("ids_of_multiple_parents", JSON.stringify(iDsOfMultipleParents));
      }
      
      // Handle communication channels.
      const channelValue = communicationOptions.includes("All") ? "All" : communicationOptions;
      formData.set("media_channel", channelValue);
      
      if (image) {
        formData.set("image", image);
      }
      
      if (newsletterFile) {
        formData.set("pdf_file", newsletterFile);
      }
      
      formData.set("institution", institution);
      formData.set("created_by", authenticationService.getUserId());

      const response = await NewsletterService.newCreate(formData);

      if (response.status === 200 || response.status === 201) {
        notification.success({
          message: whenToPublish !== "now" ? "Newsletter Scheduled" : "Newsletter Created",
          description: whenToPublish !== "now"
            ? `Newsletter has been scheduled for ${scheduledPublishDate} at ${scheduledPublishTime}`
            : "Newsletter has been created and will be published shortly.",
          placement: "topRight",
          duration: 5,
        });

        setTimeout(() => {
          form.resetFields();
          setWhenToPublish("now");
          setPreviewImage(null);
          setPreviewContent("");
          setPreviewTitle("");
          window.location.reload();
        }, 2000);
      } else {
        throw new Error(response?.data?.error ?? "An error occurred");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message || "An error occurred while creating the newsletter.",
        placement: "topRight",
      });
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  // Updated handleCheckboxChange that differentiates between audience and communication channels.
  const handleCheckboxChange = (value, dataArray, stateVariable, setterFunction, isAudience) => {
    // Use different "all" value based on the type.
    const all_value = isAudience ? "all" : "All";

    if (isAudience) {
      // For audience, just set the single selected value.
      setterFunction([value]);
      setShouldSelectMultipleRecipients(value === "group_of_parents");
      return;
    }

    if (value === all_value) {
      setterFunction([all_value]);
      return;
    }

    let updatedSelection = stateVariable.includes(value)
      ? stateVariable.filter((item) => item !== value)
      : [...stateVariable, value];

    if (updatedSelection.includes(all_value)) {
      updatedSelection = [value];
    } else {
      updatedSelection = updatedSelection.filter((v) => v !== all_value);
    }

    setterFunction(updatedSelection);
  };

  const audience = [
    { label: "All", value: "all" },
    { label: "Teachers", value: "teachers" },
    { label: "Students", value: "students" },
    { label: "All Parents", value: "parents" },
    { label: "Group of Parents", value: "group_of_parents" },
  ];

  const communicationChannelOptions = [
    { label: "All", value: "All" },
    { label: "SMS", value: "SMS" },
    { label: "Email", value: "Email" },
    { label: "WhatsApp", value: "WhatsApp" },
  ];

  const newsletterPublishingOptions = [
    { label: "Now", value: "now" },
    { label: "At a later date and time", value: "scheduled" },
  ];

  return (
    <div className="mx-4">
      <BackButton />
      <h3>Create Newsletter</h3>
      
      <div className="newsletter-container">
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
            onValuesChange={(_, allValues) => {
              setPreviewTitle(allValues.title || "");
              setPreviewContent(allValues.body || "");
            }}
          >
            <div className="upload-section">
              <div className="upload-item">
                <Form.Item
                  label="Cover Image"
                  name="image"
                  rules={[{ required: true, message: "Please upload a cover image" }]}
                >
                  {!imageUploaded ? (
                    <Upload.Dragger
                      name="image"
                      maxCount={1}
                      multiple={false}
                      listType="picture"
                      accept=".jpg,.jpeg,.png"
                      onChange={handleFileUpload}
                      beforeUpload={() => false}
                      onRemove={() => {
                        setImage(null);
                        setPreviewImage(null);
                        setImageUploaded(false);
                      }}
                    >
                      <InboxOutlined className="upload-icon" />
                      <p className="upload-text">Upload Cover Image</p>
                      <p className="upload-hint">JPG, JPEG or PNG (max: 10MB)</p>
                    </Upload.Dragger>
                  ) : (
                    <div className="uploaded-file">
                      <div className="file-preview">
                        <InboxOutlined /> Cover Image Uploaded
                      </div>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setImage(null);
                          setPreviewImage(null);
                          setImageUploaded(false);
                          form.setFieldValue("image", undefined);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </div>

              <div className="upload-item">
                <Form.Item label="Newsletter PDF" name="newsletterFile">
                  {!pdfUploaded ? (
                    <Upload.Dragger
                      name="newsletterFile"
                      maxCount={1}
                      multiple={false}
                      accept=".pdf"
                      onChange={handleNewsLetterFileUpload}
                      beforeUpload={() => false}
                      onRemove={() => {
                        setNewsletterFile(null);
                        setPdfUploaded(false);
                      }}
                    >
                      <InboxOutlined className="upload-icon" />
                      <p className="upload-text">Upload PDF (Optional)</p>
                      <p className="upload-hint">PDF only (max: 20MB)</p>
                    </Upload.Dragger>
                  ) : (
                    <div className="uploaded-file">
                      <div className="file-preview">
                        <InboxOutlined /> PDF Uploaded
                      </div>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          setNewsletterFile(null);
                          setPdfUploaded(false);
                          form.setFieldValue("newsletterFile", undefined);
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </Form.Item>
              </div>
            </div>

            <Form.Item
              label="Title"
              name="title"
              rules={[
                { required: true, message: "Please enter the newsletter title" },
                { max: 100, message: "Title cannot be longer than 100 characters" },
              ]}
            >
              <Input placeholder="Enter newsletter title" maxLength={100} />
            </Form.Item>

            <div className="audience_channel_of_communication_options">
              <span>Audience</span>
              <div>
                {audience.map(({ label, value }) => (
                  <div key={value}>
                    <label htmlFor={value}>{label}</label>
                    <input
                      type="radio"
                      id={value}
                      name="audience"
                      value={value}
                      checked={audienceOptions.includes(value)}
                      onChange={() => setAudienceOptions([value])}
                      onClick={handleClick}
                    />
                  </div>
                ))}
              </div>
            </div>

            {shouldSelectMultipleRecipients && (
              <div className="search_and_search_results_container student_search_container">
                <h3>Search for the newsletter recipients</h3>
                <div className="input_container">
                  <input
                    type="text"
                    name="student_search"
                    id="student_search"
                    placeholder="Enter name/reg #/email"
                    onChange={(e) => {
                      e.preventDefault();
                      setUsersSearchTerm(e.target.value.trim());
                    }}
                  />
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (usersSearchTerm === "") {
                        setUsersSearchResults([]);
                        return;
                      }
                      searchUsers(usersSearchTerm);
                    }}
                  >
                    Search
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      e.target.parentElement.querySelector("input").value = "";
                      setUsersSearchResults([]);
                      setUsersSearchTerm("");
                      setIDsOfMultipleParents([]);
                      setHasSearchedForRecipients(false);
                    }}
                  >
                    Clear search
                  </button>
                </div>
                {hasSearchedForRecipients && (
                  <div className="results_container">
                    <h3>Select message recipients</h3>
                    <div className="results_container__container">
                      {usersSearchResults.length > 0 ? (
                        usersSearchResults.map((user) => (
                          <div className="multiple_recipient_checkbox_container" key={user.id}>
                            <label htmlFor={user.id}>{`Reg number: ${user.username} || Full name: ${user.firstName} ${user.lastName}`}</label>
                            <input
                              type="checkbox"
                              name={user.id}
                              id={user.id}
                              value={user.id}
                              onChange={(e) =>
                                handleAddOrRemoveFromMultipleParents(e.target.value)
                              }
                            />
                          </div>
                        ))
                      ) : isLoadingSearchResults ? (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                      ) : (
                        <option>No parents found</option>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="audience_channel_of_communication_options">
              <span>Channel of communication</span>
              <div>
                {communicationChannelOptions.map(({ label, value }) => (
                  <div key={value}>
                    <label htmlFor={value}>{label}</label>
                    <input
                      type="checkbox"
                      id={value}
                      value={value}
                      checked={communicationOptions.includes(value)}
                      onChange={() =>
                        handleCheckboxChange(
                          value,
                          communicationChannelOptions,
                          communicationOptions,
                          setCommunicationOptions,
                          false
                        )
                      }
                      disabled={communicationOptions.includes("All") && value !== "All"}
                    />
                  </div>
                ))}
              </div>
            </div>

            <Form.Item
              label="Content"
              name="body"
              rules={[{ required: true, message: "Please enter the newsletter content" }]}
            >
              <TextEditor />
            </Form.Item>

            <div className="newsletter_date_time_container">
              <h3>Publishing Schedule</h3>
              <Radio.Group value={whenToPublish} onChange={(e) => setWhenToPublish(e.target.value)}>
                <Radio value="now">Publish Now</Radio>
                <Radio value="scheduled">Schedule for Later</Radio>
              </Radio.Group>

              {whenToPublish === "scheduled" && (
                <div className="newsletter_date_time_container__inputs">
                  <Form.Item label="Date" required>
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={handleDateChange}
                      disabledDate={(current) => current && current < dayjs().startOf("day")}
                    />
                  </Form.Item>
                  <Form.Item label="Time" required>
                    <TimePicker style={{ width: "100%" }} format="HH:mm" onChange={handleTimeChange} />
                  </Form.Item>
                </div>
              )}
            </div>

            <Button
              type="primary"
              size="large"
              className="mt-4"
              loading={loading}
              disabled={disabled}
              htmlType="submit"
              icon={<PlusOutlined />}
            >
              {whenToPublish === "scheduled" ? "Schedule Newsletter" : "Create Newsletter"}
            </Button>
          </Form>
        </Card>

        <div className="newsletter-preview-section">
          <div className="newsletter-preview-header">
            <h2>Live Preview</h2>
            {pdfUploaded && newsletterFile && (
              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() => {
                  const url = URL.createObjectURL(newsletterFile);
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = newsletterFile.name || "newsletter.pdf";
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  URL.revokeObjectURL(url);
                }}
              >
                Download PDF
              </Button>
            )}
          </div>
          {previewImage && (
            <img src={previewImage} alt="Newsletter cover" className="newsletter-preview-image" />
          )}
          {previewTitle && <h1 className="newsletter-preview-title">{previewTitle}</h1>}
          <div className="newsletter-preview-content" dangerouslySetInnerHTML={{ __html: previewContent }} />
        </div>
      </div>
    </div>
  );
};

export default CreateNewsletter;
