import instance, { apiUrl } from "../http-common";
import { jwtDecode } from "jwt-decode";

class AuthenticationService {
  login(data) {
    return instance.post(`${apiUrl}accounts/token/`, data);
  }

  verifyPassword(data) {
    return instance.post("accounts/verify-password/", data);
  }

  changePassword(data) {
    return instance.post("accounts/change-password/", data);
  }

  verifyToken(data) {
    return instance.post("accounts/token/verify/", data);
  }

  refreshToken(data) {
    return instance.post("accounts/token/refresh/", data);
  }

  logout() {
    const SCHOOL_NAME_CONSTANT = localStorage.getItem("SCHOOL_NAME_CONSTANT");

    (async function gatherLoginDetails() {
      async function fetchIPAddress() {
        try {
          const response = await fetch("https://api.ipify.org?format=json");
          const data = await response.json();
          return data.ip;
        } catch (error) {
          console.error("Error fetching IP:", error);
          window.location.href = SCHOOL_NAME_CONSTANT
            ? `/?school=${SCHOOL_NAME_CONSTANT}`
            : `/`;
          return "Unknown";
        }
      }

      function getDeviceDetails() {
        const userAgent = navigator.userAgent;
        let deviceOS = "Unknown OS";
        let browserUsed = "Unknown Browser";

        if (/Windows/i.test(userAgent)) deviceOS = "Windows";
        else if (/Mac/i.test(userAgent)) deviceOS = "MacOS";
        else if (/Linux/i.test(userAgent)) deviceOS = "Linux";
        else if (/Android/i.test(userAgent)) deviceOS = "Android";
        else if (/iPhone|iPad|iPod/i.test(userAgent)) deviceOS = "iOS";

        if (/Chrome/i.test(userAgent) && !/Edge/i.test(userAgent))
          browserUsed = "Chrome";
        else if (/Firefox/i.test(userAgent)) browserUsed = "Firefox";
        else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent))
          browserUsed = "Safari";
        else if (/Edge/i.test(userAgent)) browserUsed = "Edge";
        else if (/MSIE|Trident/i.test(userAgent))
          browserUsed = "Internet Explorer";

        return {
          deviceOS,
          browserUsed,
          userAgent,
        };
      }

      function getDeviceType() {
        return /Mobile|Android|iP(hone|od|ad)/i.test(navigator.userAgent)
          ? "mobile"
          : "desktop";
      }

      function getReferrerURL() {
        return document.referrer || "No Referrer";
      }

      function generateSessionID() {
        return Math.random().toString(36).substr(2, 9) + "-" + Date.now();
      }

      const ipAddress = await fetchIPAddress();
      const deviceDetails = getDeviceDetails();
      const deviceType = getDeviceType();
      const referrerURL = getReferrerURL();
      const logoutTime = new Date().toISOString();
      const sessionID = generateSessionID();

      const logoutDetails = {
        user_id: () => {
          const token = JSON.parse(localStorage.getItem("user"))?.access;
          return token?.user_id;
        },
        ip_address: ipAddress,
        device_os: deviceDetails.deviceOS,
        browser_used: deviceDetails.browserUsed,
        user_agent: deviceDetails.userAgent,
        device_type: deviceType,
        username: () => {
          const token = JSON.parse(localStorage.getItem("user"))?.access;
          return token?.username;
        },
        role: () => {
          const token = JSON.parse(localStorage.getItem("user"))?.access;
          return token?.role;
        },
        login_time: null,
        logout_time: logoutTime,
        session_id: sessionID,
        login_status: "loged_out",
        device_name: "",
        access_method: referrerURL ? "Referrer" : "Direct",
        access_duration: null,
        error_message: "",
        referrer_url: referrerURL,
        custom_tags: "",
        device_model: "",
        network_provider: "",
      };

      // console.log(logoutDetails);

      const token = JSON.parse(localStorage.getItem("user"))?.access;

      // console.log("tokeeeen", token);
      const res = await fetch(`${apiUrl}tracking-system-access/acces-logs-logout/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(logoutDetails),
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error("Error posting access log:", errorData);
        window.location.href = SCHOOL_NAME_CONSTANT
        ? `/?school=${SCHOOL_NAME_CONSTANT}`
        : `/`;
      } else {
        console.log("Access log posted successfully!");
        window.location.href = SCHOOL_NAME_CONSTANT
        ? `/?school=${SCHOOL_NAME_CONSTANT}`
        : `/`;
      }
    })();

    localStorage.removeItem("user");
    
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  decodeToken() {
    const token = JSON.parse(localStorage.getItem("user"))?.access;
    return token && jwtDecode(token);
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem("user"))?.refresh;
  }

  getUserRole() {
    const token = this.decodeToken();
    return token?.role;
  }

  getUsername() {
    const token = this.decodeToken();
    return token?.username;
  }

  getUserId() {
    const token = this.decodeToken();
    return token?.user_id;
  }

  getFullName() {
    const token = this.decodeToken();
    return (token?.firstName + " " + token?.lastName).toUpperCase();
  }

  getFirstName() {
    const token = this.decodeToken();
    return token?.firstName;
  }

  getLastName() {
    const token = this.decodeToken();
    return token?.lastName;
  }

  getUserTenantId() {
    const token = this.decodeToken();
    return token?.tenant;
  }

  getIsLibrarian() {
    const token = this.decodeToken();
    return token?.is_librarian || token?.role === "ADMIN";
  }

  getLevelId() {
    const token = this.decodeToken();
    return token;
  }

  getHasCompletedParentStudentRegistration() {
    const token = this.decodeToken();
    return token?.has_completed_student_registration_process;
  }

  getUserPhoneNumber() {
    const token = this.decodeToken();
    return token?.phoneNumber;
  }
}

export default new AuthenticationService();
