import { useParams, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  DatePicker,
  message,
  Select,
  Upload,
} from "antd";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import ChangePasswordForm from "../../../common/ChangePasswordForm";
import "../../../css/RegisterParent.css";
import { apiUrl } from "../../../http-common";
import half_logo from "../../../Assets/images/half_logo.png";
import useToken from "../../../hooks/useToken";
import authenticationService from "../../../services/authentication.service";
import studentService from "../../../services/student.service";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const CompleteStudentRegistration = () => {
  const { studentid, parentid } = useParams();
  const location = useLocation();
  const { token, setToken } = useToken();
  const urlParams = new URLSearchParams(location.search);
  const studentFirstName = urlParams.get("f") || "";
  const middleName = urlParams.get("m") || "";
  const studentLastName = urlParams.get("l") || "";
  const parentPhoneNumber = urlParams.get("p") || "";
  const [birthCertificate, setBirthCertificate] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dateString, setDateString] = useState(null);
  const [nowdate, setDate] = useState(formatDate(new Date()));
  const [institution, setInstitution] = useState({ name: "", logo_url: "" });
  const [error, setError] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const [students, setStudents] = useState([]);

  async function fetchParentStudentsData() {
    try {
      const childrenResponse = await studentService.getChildrenOfParent(
        authenticationService.getUserId()
      );
      setStudents(childrenResponse?.data);
    } catch (e) {
      return [];
    }
  }

  // ===============HANDLE DATE CHANGE============================
  const [inputDate, setInputDate] = useState("");
  const [formattedDate2, setFormattedDate] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const handleYearChange = (e) => {
    // setYear(e.target.value);
    const value = e.target.value;
    // Allow only 4-digit numbers
    if (/^\d{0,4}$/.test(value)) {
      setYear(value);
    }
  };

  const uniqueStrings = new Set();

  function generateUniqueString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result;

    do {
      result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    } while (uniqueStrings.has(result));

    uniqueStrings.add(result);
    return result;
  }

  function generateUniqueStringWithTimestamp(length) {
    const baseString = generateUniqueString(length);
    return baseString + Date.now();
  }

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) {
      setMonth(value);
    }
  };

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await fetch(
          `${apiUrl}parents/institution/${studentid}`
        );
        if (!response.ok) {
          console.log("failed to fetch logo and school name");
        }
        const data = await response.json();
        setInstitution(data);
        console.log("logo and school data ======================");
        console.log(data);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };

    fetchInstitution();
  }, []);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setDateString(dateString);
  };

  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  const handleImageError = () => {
    setImageExists(false);
  };

  function generateRandomFiveDigitNumber() {
    return Math.floor(10000 + Math.random() * 90000);
  }

  const handleFileUpload = ({ fileList }) => {
    setBirthCertificate(fileList[0]?.originFileObj);
  };

  const handleProfilePictureUpload = ({ fileList }) => {
    setProfilePicture(fileList[0]?.originFileObj);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    const formattedDatenew = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    // console.log(values);

    const payload = {
      user: {
        firstName: values.parentName,
        lastName: values.lastName,
        middleNames: values.middleName || "",
        role: "PARENT",
        gender: values.gender,
        parentEmail: values?.parentEmail,
        phoneNumber: values.parentNumber,
        password: generateRandomFiveDigitNumber(),
      },
      address: values.nationalIdNumber,
      nationalId: values.nationalIdNumber,
      parentNumber: values.parentNumber,
      parentAlternateNumber: values.parentAlternateNumber,
      childDateOfBirth: formattedDatenew,
      childFirstName: values.childFirstName,
      childMiddleName: values?.childMiddleName,
      childLastName: values.childLastName,
      childGender: values.childGender,
      childEmail: values?.childEmail,
      studentID: studentid,
      parentID: parentid,
      birthCertificate,
      profilePicture
    };

    console.log("form data", payload);

    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (key === "user") {
        Object.entries(value).forEach(([nestedKey, nestedValue]) => {
          formData.append(`user[${nestedKey}]`, nestedValue);
        });
      } else {
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.post(
        `${apiUrl}students/parent-complete-student-enolment-process-2025`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success(
          `You have completed registration for ${values.childFirstName} ${
            values?.childMiddleName || ""
          } ${values.childLastName}`
        );
        form.resetFields();
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        const errorMessage = error.response.data.error;
        console.error("Error during registration:", error);
        message.error(errorMessage);
      } else {
        console.error("Unexpected error:", error);
        message.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <div className="register-parent-container">
      <div className="header">
        <div className="image_institution_name_container">
          {
            imageExists && institution?.logo_url && (
              // ? (
              <img
                src={institution?.logo_url}
                alt={`${institution?.name} Logo`}
                style={{ width: "auto", height: "100%" }}
                onError={handleImageError}
              />
            )
            // ) : (
            //   <img
            //     src={half_logo}
            //     alt={`${institution.name} Logo`}
            //     style={{ width: "10%", height: "auto" }}
            //     onError={handleImageError}
            //   />
            // )
          }
          <h2>{institution?.name}</h2>
        </div>
        <h1>
          Complete Student Registration{" "}
          {studentFirstName?.length > 0 &&
            `for ${studentFirstName} ${middleName} ${studentLastName}`}
        </h1>
      </div>
      <Divider />

      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          childFirstName: studentFirstName,
          childMiddleName: middleName,
          childLastName: studentLastName,
          parentNumber: parentPhoneNumber,
        }}
      >
        <div className="row inputs_container">
          <Form.Item
            label="Child's First Name"
            name="childFirstName"
            rules={[
              {
                required: true,
                message: "Please input your child's first name!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Child's First Name" />
          </Form.Item>
          <Form.Item
            label="Child's Middle Name (optional)"
            name="childMiddleName"
            rules={[
              {
                message: "Please input your child's middle name",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Child's Middle Name" />
          </Form.Item>
          <Form.Item
            label="Child's Last Name"
            name="childLastName"
            rules={[
              {
                required: true,
                message: "Please input your child last name!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Child's Last Name" />
          </Form.Item>
          <Form.Item
            label="Child's Email (optional)"
            name="childEmail"
            rules={[{ message: "Please enter your child's email" }]}
          >
            <Input
              size="large"
              type="email"
              placeholder="Enter Your Child's Email"
            />
          </Form.Item>
          <Form.Item label="Child's Date Of Birth" required>
            <Input
              value={day}
              required
              onChange={handleDayChange}
              size="large"
              placeholder="DD"
              style={{ width: "30%" }}
            />
            <Input
              value={month}
              required
              onChange={handleMonthChange}
              size="large"
              placeholder="MM"
              style={{ width: "30%", marginRight: "5%" }}
            />
            <Input
              value={year}
              required
              onChange={handleYearChange}
              size="large"
              placeholder="YYYY"
              style={{ width: "30%", marginRight: "5%" }}
            />
          </Form.Item>
          <Form.Item
            className="hidden_form_input"
            label=""
            name="institutionID"
            rules={[
              {
                type: "hidden",
              },
            ]}
          >
            <Input
              size="large"
              type="hidden"
              value={institution?.id}
              placeholder="Institution id"
            />
          </Form.Item>
          <Form.Item
            className="hidden_form_input"
            label=""
            name="parentID"
            rules={[
              {
                type: "hidden",
              },
            ]}
          >
            <Input
              size="large"
              type="hidden"
              value={parentid}
              placeholder="Parent id"
            />
          </Form.Item>
          <Form.Item
            label="Child's Gender"
            name="childGender"
            rules={[
              {
                required: true,
                message: "Please select your child's gender",
              },
            ]}
          >
            <Select size="large" placeholder="Select Gender">
              <Select.Option value="MALE">Male</Select.Option>
              <Select.Option value="FEMALE">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Child's Birth Certificate (optional)"
            name="birthCertificate"
            className="birth_certificate"
            // rules={[{ message: "Upload birth certificate" }]}
          >
            <Upload.Dragger
              name="birthCertificate"
              type="file"
              maxCount={1}
              multiple={false}
              listType="picture"
              accept=".jpg,.jpeg,.png, .pdf"
              onChange={handleFileUpload}
              maxFileSize={1024 * 1024 * 10}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">
                Upload student's birth certificate (jpg, jpeg, png or pdf)
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            label="Child's National ID Number"
            name="childNationalIdNumber"
            rules={[
              { 
                message: "Please input your child's national ID number",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Your Child's National ID Number" />
          </Form.Item>
          <Form.Item
            label="Student Passport-Sized Photo (optional)"
            name="profilePicture"
            className="birth_certificate"
            // rules={[{ message: "Upload birth certificate" }]}
          >
            <Upload.Dragger
              name="profilePicture"
              type="file"
              maxCount={1}
              multiple={false}
              listType="picture"
              accept=".jpg,.jpeg,.png"
              onChange={handleProfilePictureUpload}
              maxFileSize={1024 * 1024 * 10}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">
                Upload student's passport-sized photo (jpg, jpeg, png)
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            label="Parent's First Name"
            name="parentName"
            rules={[
              { required: true, message: "Please input your first name" },
            ]}
          >
            <Input size="large" placeholder="Enter Your First Name" />
          </Form.Item>
          <Form.Item label="Parent's Middle Name (optional)" name="middleName">
            <Input size="large" placeholder="Enter Middle Name" />
          </Form.Item>
          <Form.Item
            label="Parent's Last Name"
            name="lastName"
            rules={[{ required: true, message: "Please enter your last name" }]}
          >
            <Input size="large" placeholder="Enter Your Last Name" />
          </Form.Item>

          <Form.Item
            label="Parent's Gender"
            name="gender"
            rules={[{ required: true, message: "Please select your gender" }]}
          >
            <Select size="large" placeholder="Select Gender">
              <Select.Option value="MALE">Male</Select.Option>
              <Select.Option value="FEMALE">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Parent's Address (optional)" name="parentAddress">
            <Input placeholder="Enter Address" />
          </Form.Item>
          <Form.Item
            label="Parent's Email (optional)"
            name="parentEmail"
            rules={[{ message: "Please enter your email" }]}
          >
            <Input size="large" type="email" placeholder="Enter Your Email" />
          </Form.Item>
          <Form.Item
            label="Parent's National ID Number"
            name="nationalIdNumber"
            rules={[
              {
                required: true,
                message: "Please input your national ID number",
              },
            ]}
          >
            <Input size="large" placeholder="Enter Your National ID Number" />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="parentNumber"
            rules={[
              {
                required: true,
                message: "Enter your phone number eg 263777777777",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your phone number eg 263777777777"
            />
          </Form.Item>
          <Form.Item
            label="Alternate Phone Number (optional)"
            name="parentAlternateNumber"
            rules={[
              { 
                message: "Enter your other phone number eg 263777777777",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter your other phone number eg 263777777777"
            />
          </Form.Item>
        </div>
        <div>
          By registering on this platform, you acknowledge and consent to the
          collection, processing, and secure storage of your and your child’s
          information for the purposes of school management and communication.
          Please review our{" "}
          <a
            href="https://eschools.brainstake.tech/terms.html"
            rel="noreferrer"
            target="_blank"
          >
            terms
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://eschools.brainstake.tech/privacy.html"
            rel="noreferrer"
            target="_blank"
          >
            privacy policy
          </a>
          &nbsp;for further details.
        </div>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="large"
          loading={loading}
          block
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default CompleteStudentRegistration;
