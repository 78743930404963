import { useState } from "react";
import { apiUrl } from "../../http-common";
import "../../css/search_for_statistics.css";

const SearchForStatistics = () => {
  const [examSearchTerm, setExamSearchTerm] = useState("");
  const [examSearchResults, setExamSearchResults] = useState([]);

  const [insitutionSearchTerm, setInsitutionSearchTerm] = useState("");
  const [insitutionSearchResults, setInsitutionSearchResults] = useState([]);

  const [savedInstitutions, setSavedInstitutions] = useState([]);
  const [selectedInstitution, setSelectedInstitution] = useState({});

  const [studentsInInstitutionSearchTerm, setStudentsInInstitutionSearchTerm] =
    useState("");
  const [
    studentsInInstitutionSearchResults,
    setStudentsInInstitutionSearchResults,
  ] = useState([]);


  const [subjectsSearchTerm, setSubjectsSearchTerm] = useState("");
  const [subjectSearchResults, setSubjectSearchResults] = useState([]);

  const [teachersSearchTerm, setTeachersSearchTerm] = useState("");
  const [teacherSearchResults, setTeacherSearchResults] = useState([]);

  const [usersSearchTerm, setUsersSearchTerm] = useState("");
  const [usersSearchResults, setUsersSearchResults] = useState([]);

  const [studentEndTermMarkSearchTerm, setStudentEndTermMarkSearchTerm] =
    useState("");
  const [studentEndTermMarkSearchResults, setStudentEndTermMarkSearchResults] =
    useState([]);

  const [parentSearchTerm, setParentSearchTerm] = useState("");
  const [parentSearchResults, setParentSearchResults] = useState([]);

  const searchEndTermExam = async (searchTerm) => {
    if (searchTerm?.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-end-term-exam/?q=${searchTerm?.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      // console.log("res", data);
      setExamSearchResults(data);
    });
    return response;
  };

  const searchInsitution = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-institutions/?q=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("institutions", data);
      setInsitutionSearchResults(data);
      const reduced_institutions = data.map(
        ({ institution_name, tenant_id }) => ({ institution_name, tenant_id })
      );
      console.log("reduced_institutions", reduced_institutions);
      setSavedInstitutions(reduced_institutions);
    });
    return response;
  };

  const searchStudentsInInsitution = async (tenant_id, searchTerm) => {
    if (tenant_id.trim().length === 0 || searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-students-for-institution/?q=${searchTerm.trim()}&institution_id=${tenant_id}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("students in institution", data);
      setStudentsInInstitutionSearchResults(data);
    });
  };

  const searchSubjects = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-subject/?q=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("subjects", data);
      setSubjectSearchResults(data);
    });
  };

  const searchTeachers = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-teacher/?q=${teachersSearchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("teachers", data);
      setTeacherSearchResults(data);
    });
  };

  const searchUsers = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-users/?q=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("users", data);
      setUsersSearchResults(data);
    });
  };

  const searchStudentEndTermMark = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-student-end-term-mark/?query=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("student end term mark", data);
      setStudentEndTermMarkSearchResults(data);
    });
  };

  const searchParents = async (searchTerm) => {
    if (searchTerm.trim().length === 0) {
      return;
    }
    const res = await fetch(
      `${apiUrl}accounts/search-parents/?q=${searchTerm.trim()}`
    );

    const response = res.json();

    response.then((data) => {
      console.log("parents", data);
      setParentSearchResults(data);
    });
  };

  // useEffect(() => {
  //   setSelectedInstitution(savedInstitutions[0]);
  // }, [savedInstitutions]);

  // useEffect(() => {
  //   console.log("selectedInstitution", selectedInstitution);
  //   console.log(
  //     "studentsInInstitutionSearchTerm",
  //     studentsInInstitutionSearchTerm
  //   );
  //   console.log(
  //     "setStudentsInInstitutionSearchResult",
  //     studentsInInstitutionSearchResults
  //   );
  // }, [
  //   selectedInstitution,
  //   studentsInInstitutionSearchTerm,
  //   studentsInInstitutionSearchResults,
  // ]);

  return (
    <div className="search_for_stats">
      <h1>Statistics Search Center</h1>
      <div className="search_and_search_results_container subject_search_container">
        <h2>Search for subjects</h2>
        <div className="input_container">
          <input
            type="text"
            name="subject_search"
            id="subject_search"
            placeholder="Enter subject name"
            onChange={(e) => {
              e.preventDefault();
              setSubjectsSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (subjectsSearchTerm === "") {
                setSubjectSearchResults([]);
                return;
              }
              searchSubjects(subjectsSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setSubjectSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>School subject search results</h2>
          {subjectSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {subjectSearchResults?.map(
                ({ name, id, institution, department }) => (
                  <li key={id}>
                    <p>
                      <b>Subject name:</b> {name ? name : "No subject name"}
                    </p>
                    <p>
                      <b>Department:</b>{" "}
                      {department ? department : "No department"}
                    </p>
                    <p>
                      <b>Subject id:</b> {id ? id : "No subject id"}
                    </p>
                    <p>
                      <b>Institution:</b>{" "}
                      {institution ? institution : "No institution name"}
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No subjects found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container exam_search_container">
        <h2>Search for exams</h2>
        <div className="input_container">
          <input
            type="text"
            name="search_exam"
            id="search_exam"
            placeholder="Type to search for an exam"
            onChange={(e) => {
              e.preventDefault();
              setExamSearchTerm(e.target.value.trim());
              // if(e.target.value.trim() === '') {
              //   setSearchResults([])
              // }
            }}
          />
          <button
            onClick={() => {
              if (examSearchTerm === "") {
                setExamSearchResults([]);
                return;
              }
              searchEndTermExam(examSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setExamSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Exam search results</h2>
          {examSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {examSearchResults?.map(
                (
                  {
                    id,
                    institution,
                    level,
                    name,
                    numberOfPapers,
                    subject,
                    term,
                    is_published,
                    papers,
                  },
                  index
                ) => (
                  <li key={index}>
                    <p>
                      <b>Subject id:</b> {id}
                    </p>
                    <p>
                      <b>Institution name:</b> {institution}
                    </p>
                    <p>
                      <b>Subject:</b> {subject}
                    </p>
                    <p>
                      <b>Name of exam:</b> {name}
                    </p>
                    <p>
                      <b>Level/Grade:</b> {level}
                    </p>
                    <p>
                      <b>Term:</b> {term}
                    </p>
                    <p>
                      <b>Number of papers on this exam:</b> {numberOfPapers}
                    </p>
                    <p>
                      <b>Were results for this school published?:</b>{" "}
                      {is_published ? "YES" : "NO"}
                    </p>
                    {papers?.map(({ id, total_mark, weight, name }) => (
                      <ul key={id}>
                        <h3>
                          <b>Paper Details</b>
                        </h3>
                        <li>Paper id: {id}</li>
                        <li>Paper name: {name}</li>
                        <li>Total mark: {total_mark}</li>
                        <li>Weigth: {weight}</li>
                      </ul>
                    ))}
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No exam results</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container institution_search_container">
        <h2>Search for institutions</h2>
        <div className="input_container">
          <input
            type="text"
            name="search_institution"
            id="search_institution"
            placeholder="Type to search for an institution"
            onChange={(e) => {
              e.preventDefault();
              setInsitutionSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (insitutionSearchTerm === "") {
                setInsitutionSearchResults([]);
                return;
              }
              searchInsitution(insitutionSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setInsitutionSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Institution search results</h2>
          {insitutionSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {insitutionSearchResults?.map(
                (
                  {
                    tenant_id,
                    institution_name,
                    institution_type,
                    email_address,
                    phone_number,
                    ownership,
                    address,
                    district,
                    province,
                    school_type,
                    mission,
                    vision,
                  },
                  index
                ) => (
                  <li key={index}>
                    <p>
                      <b>Institution id:</b> {tenant_id}
                    </p>
                    <p>
                      <b>Institution name:</b> {institution_name}
                    </p>
                    <p>
                      <b>Institution type:</b> {institution_type ?? ""}
                    </p>
                    <p>
                      <b>Email address:</b>{" "}
                      {email_address?.length > 0
                        ? email_address
                        : "No email address"}
                    </p>
                    <p>
                      <b>Phone number:</b> {phone_number ?? "No phone number"}
                    </p>
                    <p>
                      <b>Ownership:</b> {ownership ?? "No ownership set"}
                    </p>
                    <p>
                      <b>Address:</b>{" "}
                      {address?.length > 0 ? address : "No address was set"}
                    </p>
                    <p>
                      <b>District:</b>{" "}
                      {district?.length > 0 ? district : "No district was set"}
                    </p>
                    <p>
                      <b>Province:</b>{" "}
                      {province?.length > 0 ? province : "No province was set"}
                    </p>
                    <p>
                      <b>School type:</b>{" "}
                      {school_type ?? "No school type was set"}
                    </p>
                    <p>
                      <b>Mission:</b>{" "}
                      {mission?.length > 0 ? mission : "No mission was set"}
                    </p>
                    <p>
                      <b>Vision:</b>{" "}
                      {vision?.length > 0 ? vision : "No vision was set"}
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No institution found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container institution_student_search_container">
        <h2>Search for students in an institution</h2>
        <div className="input_container">
          <select
            onClick={(e) => {
              const selected_option = e.target.selectedOptions[0];
              if (!selected_option.getAttribute("data_tenant_id")) {
                return;
              }
              const selected_option_object = {
                tenant_id: selected_option.getAttribute("data_tenant_id"),
                institution_name: selected_option.value,
              };
              setSelectedInstitution(selected_option_object);
              console.log("selected_option", selected_option_object);
            }}
          >
            {savedInstitutions?.length > 0 ? (
              <>
                <option>Select an institution</option>
                {savedInstitutions?.map(({ institution_name, tenant_id }) => (
                  <option key={tenant_id} data_tenant_id={tenant_id}>
                    {institution_name}
                  </option>
                ))}
              </>
            ) : (
              <option>Search for an institution first</option>
            )}
          </select>
          <input
            type="text"
            name="search_students_in_institution"
            id="search_students_in_institution"
            placeholder="Enter student name or reg number"
            onChange={(e) => {
              e.preventDefault();
              setStudentsInInstitutionSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (studentsInInstitutionSearchTerm === "") {
                setStudentsInInstitutionSearchResults([]);
                return;
              }
              searchStudentsInInsitution(
                selectedInstitution?.tenant_id,
                studentsInInstitutionSearchTerm
              );
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setStudentsInInstitutionSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Student search results per institution</h2>
          {studentsInInstitutionSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {studentsInInstitutionSearchResults?.map(
                (
                  {
                    firstName,
                    lastName,
                    username,
                    dateOfBirth,
                    email,
                    parentPhoneNumber,
                    level,
                    address,
                    surbub,
                    town,
                    province,
                  },
                  index
                ) => (
                  <li key={index}>
                    <p>
                      <b>First name:</b>{" "}
                      {firstName ? firstName : "No first name"}
                    </p>
                    <p>
                      <b>Last name:</b> {lastName ? lastName : "No last name"}
                    </p>
                    <p>
                      <b>Reg # / User name:</b>{" "}
                      {username ? username : "No username"}
                    </p>
                    <p>
                      <b>Level/Grade:</b> {level ? level : "No level/grade"}
                    </p>
                    <p>
                      <b>Email address:</b> {email ? email : "No email address"}
                    </p>
                    <p>
                      <b>Date of birth:</b>{" "}
                      {dateOfBirth ? dateOfBirth : "No DOB"}
                    </p>
                    <p>
                      <b>Parent Phone number:</b>{" "}
                      {parentPhoneNumber?.length
                        ? parentPhoneNumber
                        : "No phone number"}
                    </p>
                    <p>
                      <b>Address:</b> {address ? address : "No address was set"}
                    </p>
                    <p>
                      <b>District:</b> {surbub ? surbub : "No surbub was set"}
                    </p>
                    <p>
                      <b>Town:</b> {town ? town : "No town was set"}
                    </p>
                    <p>
                      <b>Province:</b>{" "}
                      {province ? province : "No province was set"}
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No students found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container teacher_search_container">
        <h2>Search for teachers</h2>
        <div className="input_container">
          <input
            type="text"
            name="teacher_search"
            id="teacher_search"
            placeholder="Enter teacher name/reg #/email"
            onChange={(e) => {
              e.preventDefault();
              setTeachersSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (teachersSearchTerm === "") {
                setTeacherSearchResults([]);
                return;
              }
              searchTeachers(teachersSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setTeacherSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Teachers search results</h2>
          {teacherSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {teacherSearchResults?.map(
                ({ firstName, id, lastName, subjects }) => (
                  <li key={id}>
                    <p>
                      <b>First name:</b>{" "}
                      {firstName ? firstName : "No first name"}
                    </p>
                    <p>
                      <b>Last name:</b> {lastName ? lastName : "No last name"}
                    </p>
                    <p>
                      <b>Teacher id:</b> {id ? id : "No subject id"}
                    </p>
                    {/* <p>
                      Institution:{" "}
                      {institution ? institution : "No institution name"}
                    </p> */}
                    {subjects?.length > 0 && (
                      <ul>
                        {subjects?.map((subject, index) => (
                          <li key={index}>{subject}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No subjects found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container student_search_container">
        <h2>Search for a user</h2>
        <div className="input_container">
          <input
            type="text"
            name="student_search"
            id="student_search"
            placeholder="Enter name/reg #/email"
            onChange={(e) => {
              e.preventDefault();
              setUsersSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (usersSearchTerm === "") {
                setUsersSearchResults([]);
                return;
              }
              searchUsers(usersSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setUsersSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Users search results</h2>
          {usersSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {usersSearchResults?.map(
                ({
                  firstName,
                  middleNames,
                  id,
                  lastName,
                  gender,
                  email,
                  role,
                  username,
                  phoneNumber,
                  Institution,
                }) => (
                  <li key={id}>
                    <p>
                      <b>First name:</b>{" "}
                      {firstName ? firstName : "No first name"}
                    </p>
                    {middleNames && <p>Middle name: {middleNames}</p>}
                    <p>
                      <b>Last name:</b> {lastName ? lastName : "No last name"}
                    </p>
                    <p>
                      <b>Phone number:</b>{" "}
                      {phoneNumber ? phoneNumber : "No phone name"}
                    </p>
                    <p>
                      <b>Email:</b> {email ? email : "No email"}
                    </p>
                    <p>
                      <b>Reg number:</b> {username ? username : "No reg number"}
                    </p>
                    <p>
                      <b>User id:</b> {id ? id : "No user id"}
                    </p>
                    <p>
                      <b>Institution:</b>{" "}
                      {Institution ? Institution : "No institution name"}
                    </p>
                    <p>
                      <b>Gender:</b> {gender ? gender : "No gender entered"}
                    </p>
                    <p>
                      <b>Role:</b> {role ? role : "No role"}
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No student found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container student_end_term_mark_search_container">
        <h2>Search for student end term mark</h2>
        <div className="input_container">
          <input
            type="text"
            name="student_end_term_mark_search"
            id="student_end_term_mark_search"
            placeholder="Enter student name/reg #/email"
            onChange={(e) => {
              e.preventDefault();
              setStudentEndTermMarkSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (studentEndTermMarkSearchTerm === "") {
                setStudentEndTermMarkSearchResults([]);
                return;
              }
              searchStudentEndTermMark(studentEndTermMarkSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setStudentEndTermMarkSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Student end term mark search results</h2>
          {studentEndTermMarkSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {studentEndTermMarkSearchResults?.map(
                ({
                  // student_name,
                  student_username,
                  id,
                  // lastName,
                  paper_name,
                  paper_subject,
                  mark,
                  paper_total_mark,
                  paper_weight,
                  institution,
                }) => (
                  <li key={id}>
                    {/* <p>
                      <b>First name:</b>{" "}
                      {student_name ? student_name : "No first name"}
                    </p> */}
                    {/* <p>
                      <b>Last name:</b> {lastName ? lastName : "No last name"}
                    </p> */}
                    <p>
                      <b>Paper name:</b>{" "}
                      {paper_name ? paper_name : "No paper name"}
                    </p>
                    <p>
                      <b>Paper subject:</b>{" "}
                      {paper_subject ? paper_subject : "No paper subject"}
                    </p>
                    <p>
                      <b>Paper mark:</b> {mark ? mark : "No paper mark"}
                    </p>
                    <p>
                      <b>Paper total mark:</b>{" "}
                      {paper_total_mark
                        ? paper_total_mark
                        : "No paper total mark"}
                    </p>
                    <p>
                      <b>Paper weight:</b>{" "}
                      {paper_weight ? paper_weight : "No paper weight"}
                    </p>
                    {mark && paper_total_mark && paper_weight && (
                      <p>
                        <b>Percentage:</b>{" "}
                        {`${Math.round((mark / paper_total_mark) * 100, 2)}%`}
                      </p>
                    )}
                    <p>
                      <b>Reg number:</b>{" "}
                      {student_username ? student_username : "No reg number"}
                    </p>
                    <p>
                      <b>User id:</b> {id ? id : "No user id"}
                    </p>
                    <p>
                      <b>Institution:</b>{" "}
                      {institution ? institution : "No institution name"}
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No student found</p>
          )}
        </div>
      </div>

      <div className="search_and_search_results_container parents_search_container">
        <h2>Search for parents</h2>
        <div className="input_container">
          <input
            type="text"
            name="parents_search"
            id="parents_search"
            placeholder="Enter parent name/reg #/email"
            onChange={(e) => {
              e.preventDefault();
              setParentSearchTerm(e.target.value.trim());
            }}
          />
          <button
            onClick={() => {
              if (parentSearchTerm === "") {
                setParentSearchResults([]);
                return;
              }
              searchParents(parentSearchTerm);
            }}
          >
            Search
          </button>
          <button
            onClick={(e) => {
              e.target.parentElement.querySelector("input").value = "";
              setParentSearchResults([]);
            }}
          >
            Clear search
          </button>
        </div>
        <div className="results_container">
          <h2>Parent search results</h2>
          {parentSearchResults?.length > 0 ? (
            <ul className="search_result_container">
              {parentSearchResults?.map(
                ({
                  firstName,
                  lastName,
                  email,
                  username,
                  // phoneNumber,
                  built_in_user_model_id,
                  students,
                }) => (
                  <li key={built_in_user_model_id}>
                    <p>First name: {firstName ? firstName : "No first name"}</p>
                    <p>Last name: {lastName ? lastName : "No last name"}</p>
                    {/* <p>
                      Phone number:{" "}
                      {phoneNumber ? phoneNumber : "No phone name"}
                    </p> */}
                    <p>Email: {email ? email : "No email"}</p>
                    <p>Reg number: {username ? username : "No reg number"}</p>
                    <p>
                      User id:{" "}
                      {built_in_user_model_id
                        ? built_in_user_model_id
                        : "No user id"}
                    </p>
                    {students?.length > 0 && (
                      <ul>
                        <h3>Parent's Students</h3>
                        {students?.map((student, index) => (
                          <li key={index}>
                            <p>
                              First name:{" "}
                              {student?.firstName
                                ? student?.firstName
                                : "No first name"}
                            </p>
                            <p>
                              Last name:{" "}
                              {student?.lastName
                                ? student?.lastName
                                : "No last name"}
                            </p>
                            <p>
                              Email:{" "}
                              {student?.email ? student?.email : "No email"}
                            </p>
                            <p>
                              Reg number:{" "}
                              {student?.username
                                ? student?.username
                                : "No reg number"}
                            </p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                )
              )}
            </ul>
          ) : (
            <p>No parents found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchForStatistics;
