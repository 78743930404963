import { useEffect, useState } from "react";
import FormInput from "./FormInput";

const EnterChildDetails = ({
  isFirstChildInSelection,
  setSelectedSchooldIds,
  setSelectedChildrenData,
  selectedChildrenData,
  numberOfSelectedChildren,
  setNumberOfSelectedChildren,
  data_key,
  fetchAllClassrooms,
  allLevels,
  setAllLevels,
  allClasses,
  allSchools,
  dataKeyToTenantId,
  setDataKeyToTenantId,
  selectedClasses,
  setSelectedClasses,
}) => { 
  const handleRemoveChild = (e) => { 
    setSelectedChildrenData((prev) => {
      const updatedData = { ...prev };
      delete updatedData[data_key];
      return updatedData;
    }); 
  };

  // useEffect(() => {
  //   console.log("all classes", allClasses);
  //   console.log("selected classes", selectedClasses);
  // }, [allClasses, selectedClasses]);
  return (
    <div
      className={`enter_parent_student_details add_border_bottom`}
      key={data_key}
      data_key={data_key}
    >
      <h2>
        <span>
          {isFirstChildInSelection
            ? "Enter your child's details"
            : "Enter your other child's details"}
        </span>
      </h2>
      <FormInput
        type="select"
        name={"selectSchool"}
        select_type={"school"}
        labelText={"Select the school your child goes to"}
        placeholderText={"Select child's school"}
        isError={false}
        errorMessage={"Please select the school this child goes to"}
        schools={allSchools}
        allClasses={allClasses}
        setSelectedSchooldIds={setSelectedSchooldIds}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        dataKeyToTenantId={dataKeyToTenantId}
        setDataKeyToTenantId={setDataKeyToTenantId}
        fetchAllClassrooms={fetchAllClassrooms}
        data_key={data_key}
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        isRequired={true}
      />
      <FormInput
        type="text"
        name={`firstName`}
        labelText={"First name"}
        placeholderText={"Enter child's first name"}
        isError={false}
        errorMessage={""}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        data_key={data_key}
        isRequired={true}
      />
      <FormInput
        type="text"
        name={`lastName`}
        labelText={"Last name"}
        placeholderText={"Enter child's last name"}
        isError={false}
        errorMessage={""}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        data_key={data_key}
        isRequired={true}
      />
      <FormInput
        type="date_of_birth"
        name={`dateOfBirth`}
        labelText={"Child's date of birth"}
        placeholderText={""}
        isError={false}
        errorMessage={""}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        data_key={data_key}
        isRequired={true}
      />
      <FormInput
        type="text"
        name={`regNumber`}
        labelText={"Enter child's reg number"}
        placeholderText={"Enter child's reg number"}
        isError={false}
        errorMessage={""}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        data_key={data_key}
      />
      <FormInput
        type="select"
        name={"selectClass"}
        labelText={"Select child's class"}
        placeholderText={"Select class"}
        isError={false}
        errorMessage={""}
        allClasses={allClasses}
        select_type={"class"}
        selectedChildrenData={selectedChildrenData}
        setSelectedChildrenData={setSelectedChildrenData}
        fetchAllClassrooms={fetchAllClassrooms}
        dataKeyToTenantId={dataKeyToTenantId}
        setDataKeyToTenantId={setDataKeyToTenantId}
        data_key={data_key}
        selectedClasses={selectedClasses}
        setSelectedClasses={setSelectedClasses}
        isRequired={true}
      />
      {!isFirstChildInSelection && (
        <button
          className="add_remove_another_child"
          onClick={handleRemoveChild}
        >
          <span className="add_remove">-</span>
          <span>Remove child</span>
        </button>
      )}
    </div>
  );
};

export default EnterChildDetails;
