import { Button, Divider, Space, Table, Tooltip } from "antd";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import ClassroomService from "../../../services/classroom.service";
import AuthenticationService from "../../../services/authentication.service";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../http-common";
import useToken from "../../../hooks/useToken";

export async function subjectClassStudentsLoader({ params }) {
  try {
    const studentsResponse = await ClassroomService.getStudentsByClassroomId(
      params?.id,
      AuthenticationService.getUserTenantId()
    );
    const classroomsResponse = await ClassroomService.getById(params?.id);

    return {
      students: studentsResponse?.data,
      classroom: classroomsResponse?.data,
    };
  } catch (e) {
    return [];
  }
}

const SubjectClassStudentsList = () => {
  const { students, classroom } = useLoaderData();
  const [levelId, setLevelId] = useState("");
  const [studentsByLevelId, setStudentsByLevelId] = useState([]);
  const { token, setToken } = useToken();
  const {id} = useParams()

  useEffect(() => {
    setLevelId(classroom?.level?.id);
    // console.log("classroom", classroom)
  }, [classroom?.level?.id]);

  // useEffect(() => {
  //   console.log("level id", levelId);
  //   console.log("students by level id", studentsByLevelId);
  // }, [studentsByLevelId, levelId]);

  useEffect(() => {
    // console.log("params", id)
    async function subjectClassStudentsLoader() {
      try {
        const studentsResponse = await ClassroomService.getStudentsByClassroomId(
          id,
          AuthenticationService.getUserTenantId()
        );
        const classroomsResponse = await ClassroomService.getById(id);
        console.log("response", studentsResponse?.data)

        setStudentsByLevelId(studentsResponse?.data)

        return {
          students: studentsResponse?.data,
          classroom: classroomsResponse?.data,
        };
      } catch (e) {
        return [];
      }
    } 
    subjectClassStudentsLoader()
  } ,[id])

  // useEffect(() => {
  //   const fetchStudentsByLevelId = async () => {
  //     const response = await fetch(
  //       `${apiUrl}students/get-students-by-level-id/${levelId}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log("response", response);
  //     const students_by_level_id = await response.json();

  //     const reduced_data = students_by_level_id.map(({ user }) => user);
  //     console.log("reduced data", reduced_data)

  //     setStudentsByLevelId(reduced_data);
  //   };

  //   if (levelId && token) {
  //     fetchStudentsByLevelId();
  //   }
  // }, [levelId, token]);

  const navigate = useNavigate();

  const studentsTableColumns = [
    {
      title: "Reg number",
      // dataIndex: "username",
      dataIndex: "regnumber",
      key: "registrationNumber",
    },
    {
      title: "First name",
      // dataIndex: "firstName",
      dataIndex: "firstname",
      key: "firstname",
      sorter: {
        compare: (a, b) => a.firstname < b.firstname,
      },
    },
    {
      title: "Middle name",
      // dataIndex: "middleNames",
      dataIndex: "middlenames",
      key: "middlenames",
    },
    {
      title: "Last name",
      // dataIndex: "lastName",
      dataIndex: "lastname",
      key: "lastname",
      defaultSortOrder: "descend",
      sorter: {
        compare: (a, b) => a.lastname < b.lastname,
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: {
        compare: (a, b) => a.gender < b.gender,
      },
    },
    {
      title: "Current Results",
      dataIndex: "",
      key: "",
      render: (record) => (
        <Tooltip title="View Current Results">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => { 
              navigate(`/teacher/class/current-term-report/${record?.user?.id}/`);
            }}
          />
        </Tooltip>
      ),
    },
    {
      title: "Previous Results",
      dataIndex: "",
      key: "",
      render: (record) => (
        <Tooltip title="View Previous Results">
          <Button
            type="primary"
            icon={<EyeOutlined />} 
            onClick={() => { 
              navigate(`/teacher/class/previous-terms-report/${record?.user?.id}?s=${record?.user?.id}`);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  console.clear();
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>
          Students in {classroom?.level?.name}
          {classroom?.name}
        </h3>
      </div>

      <Divider type={"horizontal"} />

      <Table dataSource={studentsByLevelId} columns={studentsTableColumns} />
    </>
  );
};

export default SubjectClassStudentsList;
