import instance from "../http-common";

class AccountsService {

    getAllAccountsByInstitution(institutionId) {
        return instance.get(`accounts/get-all-users-except-parents-by-institution-id/${institutionId}/`)
    }

    createUser(data) {
        return instance.post('accounts/create/brainstake/20/25/accounts/', data);
    }
}

export default new AccountsService();