import { Link } from "react-router-dom";
import EnterChildDetails from "./EnterChildDetails";
import EnterParentDetails from "./EnterParentDetails";
import "./parent_self_reg.css";
import { useEffect, useState, useCallback } from "react";
import { apiUrl } from "../../../http-common";
import { v4 as uuidv4 } from "uuid";
import SuccessErrorModal from "../Widgets/SuccessErrorModal";
import Loading from "../Widgets/Loading";
import { emailRegex } from "../../../utils/utility_functions";

const ParentSelfRegistration = () => {
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState({});
  const [selectedChildrenData, setSelectedChildrenData] = useState({});
  const [allLevels, setAllLevels] = useState([]);
  const [allSchools, setAllSchools] = useState([]);
  const [allClasses, setAllClasses] = useState({});
  const [parentData, setParentData] = useState({});
  const [dataKeyToTenantId, setDataKeyToTenantId] = useState({});
  const [submissionError, setSubmissionError] = useState(false);
  const [successStatus, setSuccessStatus] = useState(false);
  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [areSomeRequiredInputsNotValid, setAreSomeRequiredInputsNotValid] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasFormSubmittedSuccessfully, setHasFormSubmittedSuccessfully] =
    useState(false);
  const [accountCreationStatusMessage, setAccountCreationStatusMessage] =
    useState("");

  const fetchAllSchools = useCallback(async () => {
    try {
      const res = await fetch(`${apiUrl}institutions/get-all-institutions/`, {
        method: "GET",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const data = await res.json();
      setAllSchools(data);
    } catch (e) {
      console.error("Error fetching schools:", e);
    }
  }, []);

  useEffect(() => {
    fetchAllSchools();
  }, [fetchAllSchools]);
 
  const fetchAllClassrooms = useCallback(async (tenant_id) => {
    try {
      const res = await fetch(
        `${apiUrl}class-rooms/get-classrooms-by-institution-id/${tenant_id}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = await res.json();
      const classes = data?.map((classroom) => ({
        level: classroom?.level?.id,
        levelName: classroom?.level?.name,
        class: classroom?.name,
      }));
      setAllClasses((prev) => ({ ...prev, [tenant_id]: classes }));
    } catch (e) {
      console.error("Error fetching classrooms:", e);
    }
  }, []);

  const handleAddChild = () => {
    const newChildId = uuidv4();
    setSelectedChildrenData((prev) => ({
      ...prev,
      [newChildId]: {},
    }));
  };

  const checkIfEmailInputsAreInvalid = () => {
    const input = document.querySelector("[name='email']");
    const inputValue = input.value;
 
    let isEmailValid = true;

    if (inputValue.length === 0 || emailRegex(inputValue)) {
      return true;
    }

    if (inputValue.length > 0 && !emailRegex(inputValue)) {
      input.classList.add("input_error");
      input.parentElement
        .querySelector(".error_message_single_input")
        .classList.remove("displayed_none");
      setAreSomeRequiredInputsNotValid((prev) => prev && true);
      setErrorMessage("Please enter a valid email");
      isEmailValid = false;
    } else {
      setAreSomeRequiredInputsNotValid(true);
      isEmailValid = true;
    }
    return isEmailValid;
  };

  const checkIfAllRequiredInputsAreValid = (setValid) => {
    const allInputs = document.querySelectorAll(".required");

    let areAllInputsValid = true;

    allInputs.forEach((input) => {
      if (setValid) {
        input.classList.remove("input_error");
        setAreSomeRequiredInputsNotValid(false);
        setErrorMessage("");
        return false;
      }
      const input_value =
        input.getAttribute("data_input_type") === "select"
          ? input.selectedOptions[0].value
          : input.value;

      if (input_value.length === 0) {
        setAreSomeRequiredInputsNotValid((prev) => prev && true);
        input.classList.add("input_error");
        setErrorMessage("Please correct the areas highlighted in red");
        areAllInputsValid &&= false;
      } else if (
        input.getAttribute("name") === "password" &&
        input.value?.trim().length < 8
      ) {
        input.classList.add("input_error");
        input.parentElement
          .querySelector(".error_message_single_input")
          .classList.remove("displayed_none");
        areAllInputsValid &&= false;
      } else if (
        input.getAttribute("name") === "repeatPassword" &&
        parentData.password !== parentData.repeatPassword
      ) {
        input.classList.add("input_error");
        input.parentElement
          .querySelector(".error_message_single_input")
          .classList.remove("displayed_none");
        areAllInputsValid &&= false;
      } else {
        setErrorMessage("");
        input.classList.remove("input_error");
        setAreSomeRequiredInputsNotValid(false);
        areAllInputsValid &&= true;
      }
    });
    return areAllInputsValid;
  };

  // useEffect(
  //   function displayParentData() {
  //     console.log(parentData);
  //   },
  //   [parentData]
  // );

  const clearAllInputs = () => {
    const allInputs = document.querySelectorAll(".input");

    allInputs.forEach((input) => {
      const actual_input =
        input.getAttribute("data_input_type") === "select"
          ? input.selectedOptions[0]
          : input;
      actual_input.value = "";
    });
  };

  const handleCreateAccount = async () => { 
    if (!checkIfEmailInputsAreInvalid()) {
      return;
    }

    if (!checkIfAllRequiredInputsAreValid()) {
      return;
    }

    setIsLoading(true);

    const consolidatedParentData = {
      firstName: parentData?.firstName || "",
      lastName: parentData?.lastName || "",
      email: parentData?.email || "",
      phoneNumber: parentData?.phoneNumber || "",
      alternatePhoneNumber: parentData?.alternatePhoneNumber || "",
      nationalID: parentData?.nationalID || "",
      role: "PARENT",
      password: parentData?.password || "",
    };

    const consolidatedChildrenData = Object.keys(selectedChildrenData).map(
      (childData) => ({
        dateOfBirth:
          `${selectedChildrenData[childData]?.dateOfBirth["YYYY"]}-${selectedChildrenData[childData]?.dateOfBirth["MM"]}-${selectedChildrenData[childData]?.dateOfBirth["DD"]}` ||
          "",
        firstName: selectedChildrenData[childData]?.firstName || "",
        lastName: selectedChildrenData[childData]?.lastName || "",
        level: selectedChildrenData[childData]?.level || "",
        class: selectedChildrenData[childData]?.class || "",
        role: "STUDENT",
        tenantId: selectedChildrenData[childData]?.tenant_id || "",
        regNumber: selectedChildrenData[childData]?.regNumber || "",
      })
    );

    const consolidatedParentAndChildrenData = {
      parentDetails: [consolidatedParentData],
      studentDetails: consolidatedChildrenData,
    };

    try {
      const response = await fetch(
        `${apiUrl}parents/parent-self-registration/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(consolidatedParentAndChildrenData),
        }
      );

      const response_data = await response.json();
 
      if (
        !response.ok && response.status === 400
      ) {
        setIsLoading(false);
        setHasFormSubmittedSuccessfully(false);
        setShowSuccessErrorModal(true);
        setAccountCreationStatusMessage(
          "This phone number is not available. Try again"
        );
        return;
      }

      if (
        response_data.success === false &&
        response_data.message === "You are already registered. Try To Login"
      ) {
        setIsLoading(false);
        setHasFormSubmittedSuccessfully(false);
        setShowSuccessErrorModal(true);
        setAccountCreationStatusMessage(
          "You are already registered. Try To Login"
        );
        return;
      }

      if (response_data.message === "Registration successful") {
        setIsLoading(false);
        setHasFormSubmittedSuccessfully(true);
        clearAllInputs();
        setAccountCreationStatusMessage(
          "Your account has been registered successfully"
        );
        setShowSuccessErrorModal(true);

        setTimeout(() => {
          window.location.reload();
        }, 5000)
      }
    } catch (e) {
      setIsLoading(false);
      setHasFormSubmittedSuccessfully(false);
      setShowSuccessErrorModal(true);
      setAccountCreationStatusMessage(
        "An error occured while creating your account. Try again"
      );
    }
  };

  return (
    <div className="parent_self_registration">
      <h1>Create your account as a parent</h1>
      <EnterParentDetails
        parentData={parentData}
        setParentData={setParentData}
      />

      {Object.keys(selectedChildrenData).map((childId) => (
        <EnterChildDetails
          key={childId}
          isFirstChildInSelection={false}
          setSelectedSchoolIds={setSelectedSchoolIds}
          setSelectedChildrenData={setSelectedChildrenData}
          selectedChildrenData={selectedChildrenData}
          allLevels={allLevels}
          setAllLevels={setAllLevels}
          allClasses={allClasses}
          allSchools={allSchools}
          data_key={childId}
          fetchAllClassrooms={fetchAllClassrooms}
          dataKeyToTenantId={dataKeyToTenantId}
          setDataKeyToTenantId={setDataKeyToTenantId}
          selectedClasses={selectedClasses}
          setSelectedClasses={setSelectedClasses}
        />
      ))}

      <button
        className="add_remove_another_child add_child"
        onClick={handleAddChild}
      >
        <span className="add_remove">+</span>
        <span>Add a child</span>
      </button>

      <div className="terms_and_privacy_acknowledgement">
        <p>
          By registering on this platform, you acknowledge and consent to the
          collection, processing, and secure storage of your and your child’s
          (or children’s) information for the purposes of school management and
          communication.
        </p>
        <p>
          Please review our&nbsp;
          <Link
            to="https://eschools.brainstake.tech/terms.html"
            target="_blank"
            rel="noreferrer"
          >
            terms
          </Link>
          &nbsp;and&nbsp;
          <Link
            to="https://eschools.brainstake.tech/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </Link>
          .
        </p>
      </div>

      {areSomeRequiredInputsNotValid && (
        <p className="error_message">{errorMessage}</p>
      )}

      <button className="create_account" onClick={handleCreateAccount}>
        CREATE ACCOUNT
      </button>

      <div
        className={`full_height displayed_none${
          showSuccessErrorModal ? " displayed_flex" : ""
        }`}
      >
        <SuccessErrorModal
          message={accountCreationStatusMessage}
          setShowSuccessErrorModal={setShowSuccessErrorModal}
          hasFormSubmittedSuccessfully={hasFormSubmittedSuccessfully}
        />
      </div> 
      <div
        className={`full_height displayed_none${
          isLoading ? " displayed_flex" : ""
        }`}
      >
        <Loading message={"Your account is being created. Please wait..."} />
      </div>
    </div>
  );
};

export default ParentSelfRegistration;
