import FormInput from "./FormInput";

const EnterParentDetails = ({ parentData, setParentData }) => {
  return (
    <div className="enter_parent_student_details">
      <h2>
        <span>Enter your details</span>
      </h2>
      <FormInput
        type={"text"}
        name={"firstName"}
        labelText={"First name"}
        placeholderText={"Enter your first name"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
        isRequired={true}
      />
      <FormInput
        type={"text"}
        name={"lastName"}
        labelText={"Last name"}
        placeholderText={"Enter your last name"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
        isRequired={true}
      />
      <FormInput
        type={"phone"}
        name={"phoneNumber"}
        labelText={"Phone number"}
        placeholderText={"Enter your phone"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isMainPhoneNumber={true}
        isParentData={true}
        isRequired={true}
      />
      <FormInput
        type={"phone"}
        name={"alternatePhoneNumber"}
        labelText={"Alternate phone number"}
        placeholderText={"Enter your other phone"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isMainPhoneNumber={false}
        isParentData={true}
      />
      <FormInput
        type={"text"}
        name={"nationalID"}
        labelText={"National ID Number"}
        placeholderText={"Enter your national ID #"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
        isRequired={true}
      />
      <FormInput
        type={"email"}
        name={"email"}
        labelText={"Email"}
        placeholderText={"example@email.com"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
      />
      <FormInput
        type={"password"}
        name={"password"}
        labelText={"Enter your password"}
        placeholderText={"Enter your password"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
        isRequired={true}
      />
      <FormInput
        type={"password"}
        name={"repeatPassword"}
        labelText={"Repeat your password"}
        placeholderText={"Repeat your password"}
        isError={false}
        parentData={parentData}
        setParentData={setParentData}
        isParentData={true}
        isRequired={true}
      />
    </div>
  );
};

export default EnterParentDetails;
