import { Button, Form, message, Modal, Upload } from "antd";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { handleError } from "../../../common";
import NewsletterService from "../../../services/newsletter.service";

const UpdateApprovalContentModal = ({ open, close, newsletter }) => {
  const [form] = Form.useForm();
  const [approvalComment, setApprovalComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

//   useEffect(() => {
//     setApprovalComment(newsletter?.content_approved);
//   }, [newsletter?.content_approved]);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      setDisabled(true);

      const formData = new FormData();
      formData.append("content_approved", approvalComment);

      const response = await NewsletterService.newUpdate(
        newsletter?.id,
        formData
      );
      if (response.status === 200) {
        await message.success(
          "Newsletter Approval Comment Updated Successfully"
        );
        await window.location.reload();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  const handleClear = () => {
    form.resetFields();
    setApprovalComment()
  };

  return (
    <Modal
      title={`Update Approval Comment for ${newsletter?.title} Newsletter`}
      open={open}
      onCancel={() => {
        handleClear();
        close();
      }}
      okButtonProps={{
        className: "d-none",
      }}
      cancelButtonProps={{
        className: "d-none",
      }}
      maskClosable
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        id="newsletter-form"
        onFinish={handleFormSubmit}
      >
        <Form.Item label="Approval Comment" name="content_approved">
          <textarea
            type="text"
            name="content_approved"
            className="content_approved__textarea" 
            onChange={(e) => {
              setApprovalComment(e.target.value);
            }}
            id={newsletter?.id}
          >{newsletter?.content_approved}</textarea>
        </Form.Item>

        <Button
          type="primary"
          size="large"
          className="mt-4"
          loading={loading}
          disabled={disabled}
          block
          htmlType="submit"
          icon={<PlusOutlined />}
        >
          Update Approval Comment
        </Button>
      </Form>
    </Modal>
  );
};

export default UpdateApprovalContentModal;
