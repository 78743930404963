import EndTermExamService from "../../../services/end-term-exam.service";
import {useLoaderData} from "react-router-dom";
import {
    Alert,
    Divider,
    message,
    Select,
    Table,
    Button,
    Form,
    Input,
    Space,
    Tooltip,
    Modal,
    FloatButton,
    Tag
} from "antd";
import EndTermPaperService from "../../../services/end-term-paper.service";
import {useRef, useState, useEffect} from "react";
import StudentMarkService from "../../../services/student-mark.service";
import ClassroomService from "../../../services/classroom.service";
import {EyeOutlined, SaveOutlined, SearchOutlined, EditOutlined} from "@ant-design/icons";
import TeacherExamCommentService from "../../../services/teacher-exam-comment.service";
import AuthenticationService from "../../../services/authentication.service";
import {refreshPage} from "../../../common";

export async function teacherClassLoader({params}) {
    try {
        const examResponse = await EndTermExamService.getBySubjectIdAndLevelId(params.subjectId, params.levelId);
        const studentsResponse = await ClassroomService.getStudentsByClassroomId(params.classroomId)
        const students = studentsResponse.data;
        const exam = examResponse.data;
        return {exam, students};
    } catch (e) {
        return []
    }
}

const TeacherClassList = () => {
    const {exam, students} = useLoaderData();
    console.log('Initial students data:', students);  // Add this line at the start
    const [papers, setPapers] = useState([]);
    const [examId, setExamId] = useState(exam?.id);
    const [tenantId] = useState(AuthenticationService.getUserTenantId());
    const [markRecord, setMarkRecord] = useState([]);

    const [currentPaperId, setCurrentPaperId] = useState(null);
    const [currentPaper, setCurrentPaper] = useState(null)
    const [results, setResults] = useState([]);
    const [bulkResultsUploadBtnLoader, setBulkResultsUploadBtnLoader] = useState(false);
    const [bulkResultsUploadBtnDisabledState, setBulkResultsUploadBtnDisabledState] = useState(false);
    const [studentMarkModalState, setStudentMarkModalState] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [customComment, setCustomComment] = useState('');
    const [currentStudentId, setCurrentStudentId] = useState(null);
    const [isSubmitCustomCommentBtnLoading, setIsSubmitCustomCommentBtnLoading] = useState(false);
    const [customCommentData, setCustomCommentData] = useState('');

    const [savedMarks, setSavedMarks] = useState({});
    const [editingStudent, setEditingStudent] = useState(null);

    const customCommentElement = document.querySelector('#custom-comment');
    const customCommentSaveBtn = document.querySelector('#custom-comment-save-btn');
    const customCommentCancelBtn = document.querySelector('#custom-comment-cancel-btn');
    const customCommentBtn = document.querySelector('#custom-comment-btn');
    const currentCommentElement = document.querySelector('#comment');

    const $papers = papers?.map(
        paper => ({
            label: paper?.name,
            value: paper?.id
        })
    )

    const fetchPapers = async () => {
        if (examId) {
            try {
                const response = await EndTermPaperService.getAllByEndTermExam(examId)
                setPapers(response?.data)
            } catch (e) {

            }
        }
    }

    const fetchPapersBySubjectIdAndStudentId = async (student) => {
        setCurrentStudentId(student);
        try {
            const response = await StudentMarkService.getCurrentTermResultBySubjectIdAndStudentId(
                exam?.subject,
                student
            )
            const commentData = await handleFetchTeacherComment(student)
            setCustomCommentData(commentData);
            setMarkRecord(response?.data);
        } catch (e) {

        }
    }

    const handleFetchTeacherComment = async (student) => {
        /*
        * This function fetches the teacher's custom comment for a particular student.
        * If the teacher has not added a custom comment for the student, it returns None which is saved as
        * undefined.
        *
        * params:
        *       student -> the student's id
        * return value:
        *       comment on success
        *       empty string on error
        * */
        try {
            const response = await TeacherExamCommentService.get(examId, student)
            if(response.status === 200) {
                return response?.data?.comment;
            }
        } catch (e) {
            return '';
        }
    }

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        }
    });

    const fetchExistingMarks = async (paperId) => {
        console.log('Fetching marks for paper:', paperId);
        try {
            const response = await StudentMarkService.getAllMarksByPaperId(paperId, tenantId, examId);
            console.log('API Response:', response);
            if (response?.status === 200) {
                const marksMap = {};
                // Map using student IDs from the API response
                response.data.forEach(mark => {
                    marksMap[mark.student_id] = mark.mark;
                });
                console.log('Processed marks map:', marksMap);
                setSavedMarks(marksMap);
            }
        } catch (e) {
            console.error('Error fetching marks:', e);
        }
    };

    const getStudentId = (record) => {
        // First try to get the ID from the student record
        return record?.id;
    };

    const subjectClassStudentsTableColumns = [
        {
            title: 'Registration number',
            dataIndex: 'regnumber',
            key: 'regnumber',
            sorter: {
                compare: (a, b) => a.regnumber?.localeCompare(b.regnumber),
                multiple: 1
            },
            ...getColumnSearchProps('regnumber')
        },
        {
            title: 'First name',
            dataIndex: 'firstname',
            key: 'firstname',
            // responsive: ['md', 'lg'],
            sorter: {
                compare: (a, b) => a.firstname?.localeCompare(b.firstname),
                multiple: 2
            },
            ...getColumnSearchProps('firstname')
        },
        {
            title: 'Last name',
            dataIndex: 'lastname',
            key: 'lastname',
            responsive: ['md', 'lg'],
            sorter: {
                multiple: 3,
                compare: (a, b) => a.lastname?.localeCompare(b.lastname),
            },
            defaultSortOrder: 'ascend',
            ...getColumnSearchProps('lastname')
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            responsive: ['md', 'lg'],
            defaultSortOrder: 'ascend',
            sorter: {
                multiple: 4,
                compare: (a, b) => a.gender?.localeCompare(b.gender),
            },
        },
        {
            title: 'Marks',
            dataIndex: '',
            key: 'x',
            align: 'center',
            render: (record) => {
                const studentId = getStudentId(record);
                const isEditing = editingStudent === studentId;
                const savedMark = savedMarks[studentId];
                console.log('Rendering mark for student:', studentId, 'Mark:', savedMark, 'Full record:', record);

                if (!isEditing && savedMark !== undefined) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
                            <span>{savedMark}</span>
                            <Button
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() => handleEditMark(studentId)}
                                size="small"
                                disabled={!currentPaper}
                            />
                        </div>
                    );
                }

                return (
                    <center>
                        <input
                            min={0}
                            max={currentPaper?.totalMark}
                            type='number'
                            className='form-control form-control-sm me-0 test w-50'
                            defaultValue={savedMark}
                            disabled={!currentPaper}
                            placeholder={currentPaper ? "Enter mark" : "Select paper first"}
                            style={{
                                cursor: currentPaper ? 'text' : 'not-allowed',
                                backgroundColor: currentPaper ? '#fff' : '#f5f5f5'
                            }}
                            onBlur={
                                event => {
                                    if (!currentPaper) return;
                                    
                                    const newValue = Number(event.target.value);
                                    if (newValue > currentPaper?.totalMark) {
                                        event.target.value = currentPaper.totalMark;
                                    }
                                    
                                    const filteredResults = results.filter(r => r.student !== studentId);
                                    const newMark = {
                                        student: studentId,
                                        mark: Number(event.target.value)
                                    };
                                    
                                    setResults([...filteredResults, newMark]);
                                    setSavedMarks(prev => ({
                                        ...prev,
                                        [studentId]: Number(event.target.value)
                                    }));

                                    if (isEditing) {
                                        setEditingStudent(null);
                                    }
                                }
                            }
                        />
                    </center>
                );
            }
        },
        {
            title: 'Action',
            dataIndex: '',
            key: '',
            render: (record) => {
                return (
                    <Space size="middle">
                        <Tooltip title="View mark and comment">
                            <Button
                                type="primary"
                                icon={<EyeOutlined/>}
                                onClick={async () => {
                                    fetchPapersBySubjectIdAndStudentId(record?.id);
                                    setStudentMarkModalState(true);
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            }
        }
    ]

    const handleChangePaper = (value) => {
        console.log('Paper changed to:', value);
        Array.from(document.querySelectorAll('.test')).forEach(
            input => (input.value = "")
        );
        setResults([]);
        setCurrentPaperId(value);
        const currentPaper = papers.find(paper => (paper?.id === value))
        console.log('Selected paper details:', currentPaper);
        setCurrentPaper(currentPaper);
        setEditingStudent(null);
        fetchExistingMarks(value);
    }

    useEffect(() => {
        // Fetch papers when component mounts
        fetchPapers();
    }, [examId]);

    useEffect(() => {
        // Initial fetch of papers
        fetchPapers();
        console.log('Current paper ID:', currentPaperId);
        console.log('Current exam ID:', examId);
        console.log('Current tenant ID:', tenantId);
        
        // If there's a currentPaperId, fetch its marks
        if (currentPaperId) {
            console.log('Fetching initial marks for paper:', currentPaperId);
            fetchExistingMarks(currentPaperId);
        }
    }, [currentPaperId]); // Re-run when currentPaperId changes

    const handleSubmitResults = async () => {
        if (results.length === 0) {
            message.info('There is no information to upload.');
            return 0
        }
        if (currentPaperId === null) {
            message.warning('Please select the PAPER for the marks you wish to enter below.');
            return 0
        }

        setBulkResultsUploadBtnLoader(true);
        setBulkResultsUploadBtnDisabledState(true);

        try {
            // Transform the results to use student instead of student_id
            const transformedResults = results.map(result => ({
                student: result.student,
                mark: result.mark
            }));

            const response = await StudentMarkService.bulkUploadMarksForParticularPaper(currentPaperId, transformedResults)

            if (response?.status === 200) {
                // Fetch the updated marks after saving
                await fetchExistingMarks(currentPaperId);
                setBulkResultsUploadBtnLoader(false);
                setBulkResultsUploadBtnDisabledState(false);
                setResults([]);
                message.success(response.data.success);
                if(response.data.errors && response.data.errors.length > 0) {
                    for(let i = 0; i < response.data.errors.length; i++) {
                        message.error(response.data.errors[i])
                    }
                }
            }
        } catch (e) {
            setBulkResultsUploadBtnLoader(false);
            setBulkResultsUploadBtnDisabledState(false);
            message.error('An error occurred.');
        }
    }

    const handleInitiateCustomCommentUI = () => {
        currentCommentElement.classList.add('d-none');
        customCommentBtn.classList.add('d-none');
        customCommentCancelBtn.classList.remove('d-none');
        customCommentSaveBtn.classList.remove('d-none');
        customCommentElement.classList.remove('d-none');
    }

    const handleCancelCreateCustomComment = () => {
        currentCommentElement.classList.remove('d-none');
        customCommentBtn.classList.remove('d-none');
        customCommentCancelBtn.classList.add('d-none');
        customCommentSaveBtn.classList.add('d-none');
        customCommentElement.classList.add('d-none');
    }

    const handleCreateCustomComment = async () => {
        if(customComment === '') {
            message.warning('Please enter the comment.');
            return;
        }

        setIsSubmitCustomCommentBtnLoading(true);

        try {
            const response = await TeacherExamCommentService.create({
                exam: examId,
                student: currentStudentId,
                comment: customComment,
                teacher: AuthenticationService.getUserId()
            })

            if(response?.status === 201) message.success('Custom comment added successfully.');
            if(response?.status === 200) message.success('Custom comment updated successfully.');
            refreshPage()
        } catch (e) {
            setIsSubmitCustomCommentBtnLoading(false);
            if(e.response.status === 400) message.error(e.response.data.errors);
        }
    }

    const handleEditMark = (studentId) => {
        setEditingStudent(studentId);
    };

    return (
        <>
            <div className="exam-header" style={{ 
                marginBottom: '24px', 
                background: '#fff', 
                padding: '24px', 
                borderRadius: '8px', 
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                border: '1px solid #e8e8e8'
            }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div>
                        <h2 style={{ 
                            margin: '0 0 16px 0', 
                            color: '#1890ff',
                            fontSize: '24px',
                            fontWeight: '600'
                        }}>
                            {exam?.subject?.name}
                        </h2>
                        <div style={{ color: '#666', fontSize: '16px' }}>
                            <div>
                                <strong>Exam:</strong> {exam?.name}
                            </div>
                            <div>
                                <strong>Papers:</strong> {exam?.numberOfPapers}
                            </div>
                        </div>
                    </div>
                    {currentPaper && (
                        <Tag color="blue" style={{ 
                            padding: '8px 16px', 
                            fontSize: '14px', 
                            fontWeight: '500',
                            marginTop: '8px'
                        }}>
                            Maximum Mark: {currentPaper?.totalMark}
                        </Tag>
                    )}
                </div>
            </div>

            <Table

                pagination={false}
                title={
                    () => (
                        <div style={{ padding: '16px 0' }}>
                            <Alert
                                message={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                        <div style={{ flex: 1 }}>Please select the paper to enter marks</div>
                                        <Select
                                            size="middle"
                                            style={{ width: '200px' }}
                                            options={$papers}
                                            placeholder="Select paper"
                                            onChange={handleChangePaper}
                                            onFocus={() => fetchPapers()}
                                        />
                                    </div>
                                }
                                type="info"
                                showIcon
                            />
                        </div>
                    )
                }
                dataSource={students?.sort((a, b) => {
                    if (a.gender > b.gender) return 1;
                    if (a.gender < b.gender) return -1;
                    if (a.lastname > b.lastname) return 1;
                    if (a.lastname < b.lastname) return -1;
                    return 0;
                })}
                columns={subjectClassStudentsTableColumns}
                footer={
                    () => (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="primary"
                                onClick={handleSubmitResults}
                                loading={bulkResultsUploadBtnLoader}
                                disabled={!currentPaper || bulkResultsUploadBtnDisabledState}
                                icon={<SaveOutlined />}
                            >
                                Save Marks
                            </Button>
                        </div>
                    )
                }
            >
            </Table>

            {(students?.length > 9) && <FloatButton
                type="primary"
                className="blue-float-btn"
                style={{background: '#2ba5d2 !important'}}
                tooltip="save"
                icon={<SaveOutlined/>}
                onClick={handleSubmitResults}
            />}

            <Modal
                open={studentMarkModalState}
                onCancel={() => {
                    setMarkRecord([])
                    setStudentMarkModalState(false);
                }}
                cancelButtonProps={{
                    className: 'd-none'
                }}
                okButtonProps={{
                    className: 'd-none'
                }}
                destroyOnClose={true}
            >
                <Form layout={"vertical"}>
                    <Form.Item label='Academic year'>
                        <Input
                            value={markRecord[0]?.academic_year}
                        />
                    </Form.Item>
                    <Form.Item label='Term'>
                        <Input
                            value={markRecord[0]?.term}
                        />
                    </Form.Item>
                    <Form.Item label='Total mark'>
                        <Input
                            value={
                                markRecord[1] && markRecord[1]?.total_mark
                            }
                        />
                    </Form.Item>

                    {
                        customCommentData !== undefined ? (
                            <div id='comment' className='mb-3'>
                                <p className='m-1'>Comment</p>
                                <Input.TextArea
                                    size={"large"}
                                    value={customCommentData}
                                />
                            </div>
                        ) : (
                            <div id='comment' className='mb-3'>
                                <p className='m-1'>Comment</p>
                                <Input.TextArea
                                    size={"large"}
                                    value={markRecord[1] && markRecord[1]?.comment}
                                />
                            </div>
                        )
                    }

                    <div id='custom-comment' className='mb-3 d-none'>
                        <p className='m-1'>Write custom comment and save.</p>
                        <Input.TextArea
                            size={"large"}
                            onChange={e => setCustomComment(e.target.value)}
                        />
                    </div>

                    <div className='d-flex justify-content-between align-items-center'>
                        <Button
                            className='d-none'
                            id='custom-comment-save-btn'
                            type={"primary"}
                            onClick={handleCreateCustomComment}
                            loading={isSubmitCustomCommentBtnLoading}
                        >
                            Save comment
                        </Button>

                        <Button
                            className='d-none'
                            id='custom-comment-cancel-btn'
                            type={"primary"}
                            onClick={handleCancelCreateCustomComment}
                            ghost
                        >
                            Cancel
                        </Button>
                    </div>

                    <Button
                        type={"primary"}
                        id='custom-comment-btn'
                        onClick={handleInitiateCustomCommentUI}
                    >
                        Customise comment
                    </Button>
                </Form>
            </Modal>
        </>
    )
}

export default TeacherClassList;



