import { useContext, useEffect, useState } from "react";
import COUNTRY_CODES from "../../../utils/countrycodes";
import AppContext from "antd/es/app/context";
import { AuthProvider } from "../../../context/authProvider";
import { use } from "react";

const FormInput = ({
  type,
  name,
  labelText,
  placeholderText,
  isError,
  errorMessage,
  schools,
  setSelectedSchooldIds,
  selectedChildrenData,
  setSelectedChildrenData,
  data_key,
  select_type,
  parentData,
  setParentData,
  data,
  isMainPhoneNumber,
  isParentData,
  allClasses,
  setAllClasses,
  dataKeyToTenantId,
  setDataKeyToTenantId,
  fetchAllClassrooms,
  selectedClasses,
  setSelectedClasses,
  isRequired,
}) => {
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("263");
  const [alternatePhoneNumberCountryCode, setAlternatePhoneNumberCountryCode] =
    useState("263");

  // useEffect(() => {
  //   console.log("all classes", allClasses);
  //   console.log("selected classes", selectedClasses);
  //   console.log("selected children data", selectedChildrenData);
  // }, [selectedClasses, allClasses, selectedChildrenData]);

  return (
    <div
      className={`form_input${isError ? " input_error" : ""}${
        select_type === "school" ? " full_width" : ""
      }`}
    >
      <label htmlFor={name}>{labelText}{isRequired ? <span className="required_input">&nbsp;*</span> : ""}</label>

      {type === "phone" && (
        <div className="phone_input">
          <select
            name="country_code"
            id="country_code"
            data_input_type="select"
            defaultValue="+263"
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const selectedCountryCode =
                e.target.selectedOptions[0].value?.replace("+", "");

              if (isMainPhoneNumber) {
                setPhoneNumberCountryCode(selectedCountryCode);
              } else {
                setAlternatePhoneNumberCountryCode(selectedCountryCode);
              }
            }}
          >
            {COUNTRY_CODES?.map((country_code_info) => (
              <option
                value={country_code_info?.code}
                key={`${country_code_info?.code}${country_code_info?.name}`}
              >
                {country_code_info?.code}&nbsp;{country_code_info?.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name={name}
            id={name}
            placeholder={placeholderText}
            className={`input${isRequired ? " required" : ""}`}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.target.classList?.remove("required");

              if (e.target.classList.contains("input_error")) {
                e.target.classList.remove("input_error");
                if(!e.target.parentElement.querySelector(".error_message_single_input").classList.contains("displayed_none")) {
                  e.target.parentElement.querySelector(".error_message_single_input").classList.add("displayed_none")
                }
              }

              const fullPhoneNumber = isMainPhoneNumber
                ? `${phoneNumberCountryCode}${e.target.value}`
                : `${alternatePhoneNumberCountryCode}${e.target.value}`;

              if (isMainPhoneNumber) {
                setParentData((prev) => ({
                  ...prev,
                  phoneNumber: fullPhoneNumber,
                }));
              } else {
                setParentData((prev) => ({
                  ...prev,
                  alternatePhoneNumber: fullPhoneNumber,
                }));
              }
            }}
          />
          <p className="displayed_none error_message_single_input">Please enter your phone number</p>
        </div>
      )}

      {type === "date_of_birth" && (
        <div className="date_of_birth">
          {["DD", "MM", "YYYY"].map((field, index) => (
            <input
              key={index}
              type="number"
              data_input_type="number"
              min={1}
              id={`${name}${data_key}`}
              name={field.toLowerCase()}
              className={`input${isRequired ? " required" : ""}`}
              placeholder={field}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.target.classList?.remove("input_error");

                setSelectedChildrenData((prev) => ({
                  ...prev,
                  [data_key]: {
                    ...prev[data_key],
                    dateOfBirth: {
                      ...prev[data_key]?.dateOfBirth,
                      [field]: e.target.value,
                    },
                  },
                }));
              }}
            />
          ))}
          {isError && <p className="error_message">{errorMessage}</p>}
        </div>
      )}
      {type === "select" && (
        <>
          {select_type === "school" && (
            <div className="select_school_level_class school">
              <select
                name="level"
                id={`level${data_key}`}
                data_input_type="select"
                className={`input${isRequired ? " required" : ""}`}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); 
                  e.target.classList?.remove("input_error");

                  const selectedSchoolId = parseInt(
                    e.target.selectedOptions[0].value
                  );
                  const selectedDataKey =
                    e.target.selectedOptions[0].getAttribute("data_key");
                  fetchAllClassrooms(parseInt(selectedSchoolId));
                  setDataKeyToTenantId((prev) => ({
                    ...prev,
                    [selectedDataKey]: selectedSchoolId,
                  }));
                  // setSelectedClasses((prev) => ({
                  //   ...prev,
                  //   [data_key]: allClasses[selectedSchoolId],
                  // }));
                  setSelectedChildrenData((prev) => ({
                    ...prev,
                    [data_key]: {
                      ...prev[data_key],
                      tenant_id: selectedSchoolId,
                    },
                  }));
                }}
              >
                <option value="" key="select_level">
                  Select child's school
                </option>
                {schools?.map((school) => (
                  <option
                    value={school?.tenant_id}
                    data_tenant_id={school?.tenant_id}
                    key={school?.tenant_id}
                    data_key={data_key}
                  >
                    {school?.institution_name}
                  </option>
                ))}
              </select>
              {isError && <p className="error_message">{errorMessage}</p>}
            </div>
          )}

          {select_type === "class" && (
            <div className="select_school_level_class class">
              <select
                name="class"
                id={`class${data_key}`}
                data_input_type="select"
                className={`input${isRequired ? " required" : ""}`}
                onMouseOver={() => {
                  const selectedSchoolId = selectedChildrenData[data_key]?.tenant_id
                  setSelectedClasses((prev) => ({
                    ...prev,
                    [data_key]: allClasses[selectedSchoolId],
                  }));
                }}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.target.classList?.remove("input_error");

                  const selectedLevelIdAndClass =
                    e.target.selectedOptions[0].value.split("-");
                  const selectedLevelId = parseInt(selectedLevelIdAndClass[0]);
                  const selectedClass = selectedLevelIdAndClass[1];
                  setSelectedChildrenData((prev) => ({
                    ...prev,
                    [data_key]: {
                      ...prev[data_key],
                      level: selectedLevelId,
                      class: selectedClass,
                    },
                  }));
                }}
                data_key={data_key}
              >
                <option value="" key="select_class">
                  Select class
                </option>
                {selectedClasses?.[data_key]?.map((classroom, index) => (
                  <option
                    key={index}
                    value={classroom?.level + "-" + classroom?.class}
                  >
                    {`${classroom?.levelName}`}
                    {`${classroom?.class}`}
                  </option>
                ))}
              </select>
              {isError && <p className="error_message">{errorMessage}</p>}
            </div>
          )}
        </>
      )}
      {type !== "phone" && type !== "date_of_birth" && type !== "select" && (
        <div className="not_phone_input">
          <input
            type={type}
            name={name}
            id={`${name}${data_key}`}
            data_input_type={name}
            className={`input${isRequired ? " required" : ""}`}
            placeholder={placeholderText}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (e.target.classList.contains("input_error")) {
                e.target.classList.remove("input_error");
                if(!e.target.parentElement.querySelector(".error_message_single_input").classList.contains("displayed_none")) {
                  e.target.parentElement.querySelector(".error_message_single_input").classList.add("displayed_none")
                } 
              }
              if (isParentData) {
                setParentData((prev) => ({ ...prev, [name]: e.target.value }));
              } else {
                const newChildData = {
                  ...selectedChildrenData[data_key],
                  [name]: e.target.value,
                };
                setSelectedChildrenData((prev) => ({
                  ...prev,
                  [data_key]: newChildData,
                }));
              }
            }}
          />
          <p className="displayed_none error_message_single_input">{name === "email" ? "Please enter a valid email": name === "password" ? "Password must have 8 characters or more." : name === "repeatPassword" ? "Passwords must match" : "Please enter a value"}</p>
        </div>
      )}
    </div>
  );
};

export default FormInput;
