import {
    DownOutlined,
    PlusOutlined,
    UsergroupAddOutlined,
    InboxOutlined
} from "@ant-design/icons";
import {
    Button,
    Card,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input, InputNumber,
    message,
    Modal,
    Select,
    Space, Spin,
    Table,
    Upload,
    Tag,
    Alert
} from "antd";
import * as XLSX from 'xlsx';
import {useEffect, useState} from "react";
import StudentService from "../../../services/student.service";
import NATIONALITIES from "../../../utils/nationalities";
import RELIGIONS from "../../../utils/religions";
import COUNTRY_CODES from "../../../utils/countrycodes";
import AuthenticationService from "../../../services/authentication.service";
import LevelService from "../../../services/level.service";
import { useLoaderData } from "react-router-dom";
import classroomService from "../../../services/classroom.service";
import {capitaliseFirstLetters, parentPhoneNumberPrefix, phoneNumberPrefix} from "../../../common";

export async function newStudentLoader() {
    try {
        const tenantId = AuthenticationService.getUserTenantId();
        const levelsResponse = await LevelService.getAll(tenantId);
        return { levels: levelsResponse.data};
    } catch (e) {
        return [];
    }
}

const NewStudent = () => {
    const { levels } = useLoaderData();
    const [classRoomsReturned, setClassRoomsReturned] = useState([]);


    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const tenantId = AuthenticationService.getUserTenantId();

    const [level, setLevel] = useState("");
    const [gender, setGender] = useState("");
    const [nationality, setNationality] = useState("");
    const [religion, setReligion] = useState("");
    const [residenceType, setResidenceType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleNames, setMiddleNames] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [enrollmentDate, setEnrollmentDate] = useState("");
    const [birthCertNumber, setBirthCertNumber] = useState("");
    const [allergies, setAllergies] = useState("");
    const [medicalAidName, setMedicalAidName] = useState("");
    const [medicalAidNumber, setMedicalAidNumber] = useState("");
    const [inclusive_needs, setInclusiveNeeds] = useState("");
    const [address, setAddress] = useState("");

    const [isLevelSelected, setIsLevelSelected] = useState(true)

    const [bulkUploadClassroom, setBulkUploadClassroom] = useState(null);

    const [bulkUploadModalState, setBulkUploadModalState] = useState(false);
    const [bulkUploadDisabledState, setBulkUploadDisabledState] = useState(false);

    const [bulkUploadByClassModalState, setBulkUploadByClassModalState] =
        useState(false);
    const [bulkUploadByClassDisabledState, setBulkUploadByClassDisabledState] =
        useState(false);

    const [studentsLoading, setStudentsLoading] = useState(false);
    const [searchedStudents, setSearchedStudents] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])

    const [previewData, setPreviewData] = useState([]);
    const [previewColumns, setPreviewColumns] = useState([]);
    const [uploadFile, setUploadFile] = useState(null);

    useEffect(() => {
        fetchStudents()
    }, []);

    const fetchStudents = async () => {
        setStudentsLoading(true)
        const institutionId = AuthenticationService.getUserTenantId()
        try {
            const studentsResponse = await StudentService.getAllStudentsByInstitutionId(institutionId)
            if(studentsResponse.status === 200) {
                const transformedStudents = studentsResponse.data ?
                    studentsResponse.data.map(student => {
                        const label = `${student.user?.firstName} ${student.user?.lastName} (${student.user?.username})`
                        return {label: label, value: student.id}
                    }) : []
                setSearchedStudents(transformedStudents)
            }
        } catch (e) {
            message.error("Failed to load Students")
        } finally {
            setStudentsLoading(false)
        }
    }

    const $levels = levels?.map((i) => ({
        label: i?.name,
        value: i?.id,
    }));

    const handleFormClear = () => {
        form.resetFields();
    };

    const handleSubmit = async (values) => {

        console.log("Submitted Values:", values);
        // add regex to validate names
        if (
            /^[A-Za-z]+$/.test(firstName) === false
        ) {
            message.error("First name must contain alphabetic characters only");
            return;
        } else if (
            /^[A-Za-z]+$/.test(lastName) === false
        ) {
            message.error("Last name must contain alphabetic characters only");
            return;
        } else if (
            middleNames !== '' && /^[A-Za-z]+$/.test(middleNames) === false
        ) {
            message.error("Middle name must contain alphabetic characters only");
            return;
        } 
        // let phoneNumber
        // if(values.phoneNumber) phoneNumber = values.countryCode + values.phoneNumber;

        let parentPhoneNumber
        if(values.parentPhoneNumber) parentPhoneNumber = values.countryCode + values.parentPhoneNumber;

        setDisabled(true);
        setLoading(true);

        
        try {
            const response = await StudentService.enrolStudent({
                user: {
                    firstName: capitaliseFirstLetters(firstName),
                    lastName: capitaliseFirstLetters(lastName),
                    middleNames: capitaliseFirstLetters(middleNames),
                    studentEmail,
                    parentEmail,
                    // phoneNumber,
                    parentPhoneNumber,
                    dateOfBirth,
                    gender,
                    tenant: tenantId,
                    password: lastName.toUpperCase(),
                },
                level,
                gender,
                nationality,
                religion,
                residenceType,
                enrollmentDate,
                birthCertNumber,
                allergies,
                medicalAidName,
                medicalAidNumber,
                address,
                inclusive_needs,
                siblings: selectedStudents,
                classroom: bulkUploadClassroom,
            });

            console.log(response);

            if (response?.status === 201) {
                handleFormClear();
                message.success("Student enrolled successfully.");
                setDisabled(false);
                setLoading(false);
                setLevel("");
                setGender("");
                setNationality("");
                setReligion("");
                setResidenceType("");
                setFirstName("");
                setLastName("");
                setMiddleNames("");
                setStudentEmail("");
                setParentEmail("");
                setDateOfBirth("");
                setEnrollmentDate("");
                setBirthCertNumber("");
                setAllergies("");
                setMedicalAidName("");
                setMedicalAidNumber("");
                setInclusiveNeeds("");
                setAddress("");
                window.location.reload();
            }
        } catch (e) {
            setDisabled(false);
            setLoading(false);
            if (e?.response?.status === 400) {
                const data = e.response.data.user;
                Object.keys(data).forEach((key) => {
                    const value = data[key][0];
                    message.error(`${key}: ${value}`);
                });
            }
        }
    };

    const handleChangeLevelId = async (value) => {
        await setLevel(value);

        if (value){
            console.log("level value is: ", value)
            try {
                const response = await classroomService.getAllClassroomsByLevelId(value, tenantId)
                if (response?.status === 200) {

                    const MappedClassrooms = response?.data?.map((i) => ({
                        label: `${i?.name}`,
                        value: i?.id,
                    }));

                    setClassRoomsReturned(MappedClassrooms)
                } else {
                    console.log("Error occured during fetching classrooms")
                }
            } catch (error) {
                console.log("Error occured during fetching classrooms")
            }
        }

        setIsLevelSelected(false)

    };
    const handleChangeGender = (value) => {
        setGender(value);
    };

    const handleChangeNationality = (value) => {
        setNationality(value);
    };

    const handleChangeReligion = (value) => {
        setReligion(value);
    };

    const handleChangeResidenceType = (value) => {
        setResidenceType(value);
    };

    const handleChangeEnrollmentDate = (date, dateString) => {
        setEnrollmentDate(dateString);
    };

    const handleChangeDateOfBirth = (date, dateString) => {
        console.log(dateString);
        setDateOfBirth(dateString);
    };

    const _NATIONALITIES = NATIONALITIES.map((nationality) => ({
        label: nationality,
        value: nationality,
    }));

    const _RELIGIONS = RELIGIONS.map((religion) => ({
        label: religion,
        value: religion,
    }));

    const handleFilePreview = async (file) => {
        setUploadFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
            const workbook = XLSX.read(e.target.result, { type: 'array' });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(firstSheet);
            
            if (data.length > 0) {
                // Track duplicates and errors
                const nameOccurrences = {};
                const hasErrors = {
                    duplicates: false,
                    missing: false
                };
                
                const processedData = data.map((row, index) => {
                    const errors = [];
                    const duplicateFields = [];
                    
                    // Check for required fields
                    if (!row.firstName) {
                        errors.push('Missing First Name');
                        hasErrors.missing = true;
                    }
                    if (!row.lastName) {
                        errors.push('Missing Last Name');
                        hasErrors.missing = true;
                    }
                    
                    // Create a unique key for the full name (case insensitive)
                    const fullNameKey = `${(row.firstName || '').toLowerCase()}_${(row.lastName || '').toLowerCase()}`;
                    
                    // Track occurrences of the full name
                    if (fullNameKey !== '_') {
                        if (!nameOccurrences[fullNameKey]) {
                            nameOccurrences[fullNameKey] = {
                                count: 1,
                                rows: [index],
                                name: `${row.firstName} ${row.lastName}`
                            };
                        } else {
                            nameOccurrences[fullNameKey].count++;
                            nameOccurrences[fullNameKey].rows.push(index);
                            hasErrors.duplicates = true;
                        }
                    }

                    return {
                        ...row,
                        key: index,
                        _errors: errors,
                        _duplicateNameKey: fullNameKey,
                        _rowIndex: index
                    };
                });

                // Mark duplicates after processing all records
                processedData.forEach(record => {
                    const nameInfo = nameOccurrences[record._duplicateNameKey];
                    if (nameInfo && nameInfo.count > 1) {
                        record._duplicates = ['firstName', 'lastName'];
                    } else {
                        record._duplicates = [];
                    }
                });

                // Show alert if there are errors
                if (hasErrors.duplicates || hasErrors.missing) {
                    Modal.warning({
                        title: 'Issues Found in Upload File',
                        content: (
                            <div>
                                {hasErrors.duplicates && (
                                    <div style={{ marginBottom: '10px' }}>
                                        <Alert
                                            message="Duplicate Names Found"
                                            description="Some students have duplicate names. Please review the highlighted rows."
                                            type="warning"
                                            showIcon
                                        />
                                    </div>
                                )}
                                {hasErrors.missing && (
                                    <div>
                                        <Alert
                                            message="Missing Required Fields"
                                            description="Some records are missing required fields (First Name or Last Name)."
                                            type="error"
                                            showIcon
                                        />
                                    </div>
                                )}
                            </div>
                        ),
                        width: 500,
                    });
                }

                const columns = Object.keys(data[0]).map(key => ({
                    title: key,
                    dataIndex: key,
                    key: key,
                    render: (text, record) => {
                        const isDuplicate = record._duplicates?.includes(key);
                        const style = {
                            backgroundColor: isDuplicate ? '#ffccc7' : undefined,
                            padding: '4px 8px',
                            display: 'block'
                        };
                        return (
                            <div style={style}>
                                {text}
                                {isDuplicate && (
                                    <Tag color="error" style={{ marginLeft: 8 }}>
                                        Duplicate name
                                    </Tag>
                                )}
                            </div>
                        );
                    }
                }));

                // Add issues column
                columns.push({
                    title: 'Issues',
                    dataIndex: '_issues',
                    key: '_issues',
                    fixed: 'right',
                    width: 200,
                    render: (_, record) => {
                        const duplicateMessage = record._duplicates?.length > 0 
                            ? [`Duplicate student name: ${record.firstName} ${record.lastName}`] 
                            : [];
                        
                        const issues = [
                            ...record._errors,
                            ...duplicateMessage
                        ];
                        
                        return issues.length > 0 ? (
                            <div>
                                {issues.map((issue, i) => (
                                    <Tag key={i} color="error" style={{ margin: '2px 0', display: 'block' }}>
                                        {issue}
                                    </Tag>
                                ))}
                            </div>
                        ) : (
                            <Tag color="success">No issues</Tag>
                        );
                    }
                });

                setPreviewColumns(columns);
                setPreviewData(processedData.map(record => ({
                    ...record,
                    hasErrors: record._errors.length > 0 || record._duplicates.length > 0
                })));
            }
        };
        reader.readAsArrayBuffer(file);
        return false;
    };

    const handleUploadChange = (info) => {
        const { status } = info.file;
        if (status === 'removed') {
            setUploadFile(null);
            setPreviewData([]);
            setPreviewColumns([]);
        }
    };

    const handleBulkUploadSubmit = async (e) => {
        e.preventDefault();
        if (!uploadFile || previewData.length === 0) {
            message.error("Please select a file first");
            return;
        }

        setBulkUploadDisabledState(true);
        try {
            const formData = new FormData();
            formData.append('data', uploadFile);

            const response = await StudentService.bulkUpload(tenantId, formData);
            setBulkUploadDisabledState(false);
            setBulkUploadModalState(false);
            setUploadFile(null);
            setPreviewData([]);
            setPreviewColumns([]);
            message.success(`${response.data.message}`);

            if (response.data.errors?.length > 0) {
                response.data.errors.forEach(error => {
                    if (error.birthCertNumber) {
                        message.error(error.birthCertNumber[0], 5);
                    }
                    if (error.level) {
                        message.error(error.level[0], 5);
                    }
                });
            }
        } catch (e) {
            if(e.response?.status === 400) {
                message.error(e.response.data.error);
            }
            setBulkUploadDisabledState(false);
        }
    };

    const handleBulkUploadByClassSubmit = async (e) => {
        e.preventDefault();
        if (!uploadFile || previewData.length === 0) {
            message.error("Please select a file first");
            return;
        }

        setBulkUploadByClassDisabledState(true);
        if (bulkUploadClassroom === null) {
            message.warning(
                "Please select the class in which you want to enrol the students."
            );
            setBulkUploadByClassDisabledState(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('data', uploadFile);

            const response = await StudentService.bulkUploadByClass(
                tenantId,
                bulkUploadClassroom,
                formData
            );
            setBulkUploadByClassDisabledState(false);
            setBulkUploadByClassModalState(false);
            setUploadFile(null);
            setPreviewData([]);
            setPreviewColumns([]);
            message.success(`${response.data.message}`);

            if (response.data.errors?.length > 0) {
                response.data.errors.forEach(error => {
                    if (error.birthCertNumber) {
                        message.error(error.birthCertNumber[0], 5);
                    }
                    if (error.level) {
                        message.error(error.level[0], 5);
                    }
                });
            }
        } catch (e) {
            if(e.response?.status === 400) {
                message.error(e.response.data.error);
            }
            setBulkUploadByClassDisabledState(false);
        }
    };

    const bulkUploadItems = [
        {
            label: "Level only",
            key: "1",
            onClick: () => setBulkUploadModalState(true),
        },
        {
            label: "Class",
            key: "2",
            onClick: () => setBulkUploadByClassModalState(true),
        },
    ];

    const menuProps = {
        items: bulkUploadItems,
    };

    const handleChangeClassroom = (value) => {
        setBulkUploadClassroom(value);
    };
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    };

    return (
        <div className='mx-5'>
            <div className="d-flex justify-content-between align-items-center">
                <h3>Enrol new student</h3>
                <Dropdown menu={menuProps}>
                    <Button
                        icon={<UsergroupAddOutlined />}
                        className="border-0 px-3 text-white"
                        style={{ background: "#39b54a" }}
                    >
                        <Space>
                            Bulk enrollment by...
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
            <Divider type={"horizontal"} />

            <Form
                form={form}
                layout={"vertical"}
                className="m-2"
                onFinish={handleSubmit}
            >
                <div className="row gy-3">
                    <Card title="Personal information" className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <Form.Item
                                    name="firstName"
                                    label="First name"
                                    rules={[{ required: true, message: 'First name is required!' }]}
                                >
                                    <Input
                                        onChange={(e) => setFirstName(e.target.value)}
                                        name="firstName"
                                        size={"large"}
                                        required
                                    />
                                </Form.Item>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name="dateOfBirth" label="Date of birth">
                                            <DatePicker
                                                onChange={handleChangeDateOfBirth}
                                                name="dateOfBirth"
                                                size={"large"}
                                                className="w-100"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="gender"
                                            label="Gender"
                                            rules={[{ required: true, message: 'Gender is required!' }]}
                                        >
                                            <Select
                                                name="gender"
                                                size={"large"}
                                                onChange={handleChangeGender}
                                                options={[
                                                    { label: "Male", value: "MALE" },
                                                    {
                                                        label: "Female",
                                                        value: "FEMALE",
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <Form.Item name="middleNames" label="Middle names">
                                    <Input
                                        onChange={(e) => setMiddleNames(e.target.value)}
                                        name="middleNames"
                                        size={"large"}
                                    />
                                </Form.Item>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name="birthCertNumber" label="Nation ID number">
                                            <Input
                                                onChange={(e) => setBirthCertNumber(e.target.value)}
                                                name="birthCertNumber"
                                                placeholder="63-232257R18"
                                                size={"large"}
                                                onInput={toInputUppercase}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name="homeAddress" label="Address">
                                            <Input
                                                onChange={(e) => setAddress(e.target.value)}
                                                name="homeAddress"
                                                size={"large"}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <Form.Item
                                    name="lastName"
                                    label="Last name"
                                    rules={[{ required: true, message: 'Last name is required!' }]}
                                >
                                    <Input
                                        onChange={(e) => setLastName(e.target.value)}
                                        name="lastName"
                                        size={"large"}
                                        required
                                    />
                                </Form.Item>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name="religion" label="Religion">
                                            <Select
                                                name="religion"
                                                size={"large"}
                                                onChange={handleChangeReligion}
                                                options={_RELIGIONS}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name="nationality" label="Nationality" initialValue={"Zimbabwean"}>
                                            <Select
                                                name="nationality"
                                                size={"large"}
                                                options={_NATIONALITIES}
                                                onChange={handleChangeNationality}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <Card title="Account information" className="col-md-12">
                        <div className="row">
                            {/* <div className="col-md-6">
                                <Form.Item
                                    name="phoneNumber"
                                    label="Student phone number"
                                    rules={[{ len: 9, message: 'Number should have 9 characters' }]}
                                >
                                    <Input
                                        addonBefore={phoneNumberPrefix}
                                        name="phoneNumber"
                                        className="w-100"
                                        size={"large"}
                                        type="number"
                                        min={9}
                                        maxLength={9}
                                    />
                                </Form.Item>
                            </div> */}
                            <div className="col-md-6">
                                <Form.Item
                                    name="parentPhoneNumber"
                                    label="Parent phone number"
                                    rules={[{ len: 9, required: true, message: 'Number should have 9 characters' }]}
                                    
                                >
                                    <Input
                                        addonBefore={parentPhoneNumberPrefix}
                                        name="parentPhoneNumber"
                                        className="w-100"
                                        size={"large"}
                                        type="number"
                                        min={9}
                                        maxLength={9}
                                        required
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="studentEmail" label="Student Email">
                                    <Input
                                        onChange={(e) => setStudentEmail(e.target.value)}
                                        htmlType="email"
                                        name="studentEmail"
                                        size={"large"}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-6">
                                <Form.Item name="parentEmail" label="Parent Email">
                                    <Input
                                        onChange={(e) => setParentEmail(e.target.value)}
                                        htmlType="email"
                                        name="parentEmail"
                                        size={"large"}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Card>

                    <Card title="Health information" className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="allergies" label="Allergies">
                                    <Input.TextArea
                                        onChange={(e) => setAllergies(e.target.value)}
                                        name="allergies"
                                        size={"large"}
                                    />
                                </Form.Item>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name="medicalAidName" label="Medical aid name">
                                            <Input
                                                onChange={(e) => setMedicalAidName(e.target.value)}
                                                name="medicalAidName"
                                                size={"large"}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item
                                            name="medicalAidNumber"
                                            label="Medical aid number"
                                        >
                                            <Input
                                                onChange={(e) => setMedicalAidNumber(e.target.value)}
                                                name="medicalAidNumber"
                                                size={"large"}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <Form.Item name="inclusiveNeeds" label="Inclusive Needs">
                                    <Input.TextArea
                                        onChange={(e) => setInclusiveNeeds(e.target.value)}
                                        name="inclusiveNeeds"
                                        size={"large"}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                    </Card>

                    <Card title="School information" className="col-md-12">
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Item
                                    name="grade"
                                    label="Level/Grade"
                                    rules={[{ required: true, message: 'Student level is required!' }]}
                                >
                                    <Select
                                        options={$levels}
                                        name="levelId"
                                        size={"large"}
                                        onChange={handleChangeLevelId}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item name="class" label="Class" help="Please select the Level first before attempting to select class.">
                                    <Select
                                        options={classRoomsReturned}
                                        name="classId"
                                        size={"large"}
                                        onChange={handleChangeClassroom}
                                        disabled={isLevelSelected}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item name="residenceStatus" label="Residence status">
                                    <Select
                                        name="residenceType"
                                        size={"large"}
                                        onChange={handleChangeResidenceType}
                                        options={[
                                            { label: "Boarder", value: "BOARDING" },
                                            { label: "Day", value: "DAY" },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item name="enrollmentDate" label="Enrolment date">
                                    <DatePicker
                                        onChange={handleChangeEnrollmentDate}
                                        name="enrollmentDate"
                                        size={"large"}
                                        className="w-100"
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div className="row">
                            <Form.Item name="siblings" label="Siblings">
                                <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select Siblings who attend the school"
                                    size="large"
                                    onChange={setSelectedStudents}
                                    options={searchedStudents}
                                    notFoundContent={studentsLoading ? <Spin size="small" /> : null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => (option?.label ?? '').includes(input) ||
                                        (option?.label ?? '').toLowerCase().includes(input)}
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </div>
                    </Card>
                </div>

                <div className="d-flex justify-content-end">
                    <Button
                        size={"large"}
                        className="px-5 mt-4 border-0 text-light"
                        disabled={disabled}
                        loading={loading}
                        htmlType="submit"
                        icon={<PlusOutlined />}
                        style={{ background: "#39b54a" }}
                    >
                        Enrol student
                    </Button>
                </div>
            </Form>

            <Modal
                title="Bulk Upload Students"
                open={bulkUploadModalState}
                onCancel={() => {
                    setBulkUploadModalState(false);
                    setUploadFile(null);
                    setPreviewData([]);
                    setPreviewColumns([]);
                }}
                width={1000}
                footer={null}
                className="bulk-upload-modal"
            >
                <form
                    id="bulk-upload-form"
                    className="pt-3"
                    onSubmit={handleBulkUploadSubmit}
                    method="post"
                    encType="multipart/form-data"
                >
                    <Upload.Dragger
                        name="data"
                        accept=".xlsx,.xls,.csv"
                        maxCount={1}
                        beforeUpload={handleFilePreview}
                        onChange={handleUploadChange}
                        onRemove={() => {
                            setUploadFile(null);
                            setPreviewData([]);
                            setPreviewColumns([]);
                        }}
                        style={{ marginBottom: '20px' }}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Only .xlsx, .xls and .csv files are allowed
                        </p>
                    </Upload.Dragger>

                    {previewData.length > 0 && (
                        <>
                            <h4 className="mt-4 mb-3">Preview</h4>
                            <Table 
                                dataSource={previewData} 
                                columns={previewColumns}
                                scroll={{ x: true }}
                                size="small"
                                pagination={{ pageSize: 5 }}
                            />
                            <Button
                                size={"large"}
                                htmlType="submit"
                                className="border-0 mt-3 text-light"
                                style={{ background: "#39b54a" }}
                                disabled={
                                    bulkUploadDisabledState || 
                                    !uploadFile || 
                                    previewData.some(record => record.hasErrors)
                                }
                                loading={bulkUploadDisabledState}
                                block
                            >
                                Upload
                            </Button>
                            {previewData.some(record => record.hasErrors) && (
                                <Alert
                                    message="Cannot Upload"
                                    description="Please fix all issues before uploading"
                                    type="warning"
                                    showIcon
                                    style={{ marginTop: '10px' }}
                                />
                            )}
                        </>
                    )}
                </form>
            </Modal>

            <Modal
                title="Fill in all fields before attempting to upload!"
                open={bulkUploadByClassModalState}
                onCancel={() => {
                    setBulkUploadByClassModalState(false);
                    setUploadFile(null);
                    setPreviewData([]);
                    setPreviewColumns([]);
                }}
                cancelButtonProps={{
                    className: "d-none",
                }}
                okButtonProps={{
                    className: "d-none",
                }}
                width={1000}
                destroyOnClose
                className="bulk-upload-modal"
            >
                <form
                    id="bulk-by-class-upload-form"
                    className="pt-2"
                    onSubmit={handleBulkUploadByClassSubmit}
                    method="post"
                    encType="multipart/form-data"
                >
                    <Form layout={"vertical"}>
                        <Form.Item name="grade" label="Level/Grade">
                            <Select
                                placeholder="Please select Level"
                                options={$levels}
                                name="levelId"
                                size={"large"}
                                onChange={handleChangeLevelId}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Select class"
                            help="Please select the Level first on the field above."
                        >
                            <Select
                                placeholder="Please select class"
                                options={classRoomsReturned}
                                size={"large"}
                                onChange={handleChangeClassroom}
                                disabled={isLevelSelected}
                            />
                        </Form.Item>
                    </Form>

                    <Upload.Dragger
                        name="data"
                        accept=".xlsx,.xls,.csv"
                        maxCount={1}
                        beforeUpload={handleFilePreview}
                        onChange={handleUploadChange}
                        onRemove={() => {
                            setUploadFile(null);
                            setPreviewData([]);
                            setPreviewColumns([]);
                        }}
                        style={{ marginBottom: '20px' }}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Only .xlsx, .xls and .csv files are allowed
                        </p>
                    </Upload.Dragger>

                    {previewData.length > 0 && (
                        <>
                            <h4 className="mt-4 mb-3">Preview</h4>
                            <Table 
                                dataSource={previewData} 
                                columns={previewColumns}
                                scroll={{ x: true }}
                                size="small"
                                pagination={{ pageSize: 5 }}
                            />
                            <Button
                                size={"large"}
                                htmlType="submit"
                                className="border-0 mt-3 text-light"
                                style={{ background: "#39b54a" }}
                                disabled={
                                    bulkUploadByClassDisabledState || 
                                    isLevelSelected || 
                                    !uploadFile || 
                                    previewData.some(record => record.hasErrors)
                                }
                                loading={bulkUploadByClassDisabledState}
                                block
                            >
                                Upload
                            </Button>
                            {previewData.some(record => record.hasErrors) && (
                                <Alert
                                    message="Cannot Upload"
                                    description="Please fix all issues before uploading"
                                    type="warning"
                                    showIcon
                                    style={{ marginTop: '10px' }}
                                />
                            )}
                        </>
                    )}
                </form>
            </Modal>
        </div>
    );
};

export default NewStudent;
