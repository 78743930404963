import NewsletterService from "../../../services/newsletter.service";
import { fallbackImg, toHumanDate } from "../../../common";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../http-common";
import "./unauthenticated_newsletter.css";
import institutionService from "../../../services/institution.service";

export async function newsletterLoader({ params }) {
  try {
    const newsletterResponse = await NewsletterService.newGetAllNewslettersById(
      params.id
    );
    return { newsletter: newsletterResponse.data };
  } catch (e) {
    console.log(e);
  }
}

const UnauthenticatedNewsletterDetail = () => {
  const url = window.location.pathname;
  const parts = url.split("/");
  const id = parts[2];
  
  const [newsletterData, setNewletterData] = useState([]);
  const [schoolData, setSchoolData] = useState({});
  const [schoolLogo, setSchoolLogo] = useState(null);

  async function singleNewsLetterLoader(id) {
    try {
      const singleNewsletterResponse = await fetch(
        `${apiUrl}newsletters/newsletters/get/${id}/`
      );

      if (!singleNewsletterResponse.ok) {
        throw new Error("Failed to fetch the newsletter.");
      }

      const data = await singleNewsletterResponse.json();
      setNewletterData(data);

    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (id) {
      singleNewsLetterLoader(id);
    }
  }, [id]);

  return (
    <div className="blog-post-container">
      <div className="blog-header">
        <h1>{schoolData?.institution_name || "School Newsletter"}</h1>
        <div className="blog-navigation">
          <span className="nav-separator">NEWSLETTER</span>
        </div>
      </div>

      <div className="blog-content">
        <div className="featured-image">
          <img
            src={newsletterData?.image || fallbackImg}
            alt={newsletterData?.title || "Newsletter"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = fallbackImg;
            }}
          />
        </div>

        <div className="post-metadata">
          <div className="metadata-item">
            <span className="metadata-label">Published on:</span>
            <span className="metadata-value">{toHumanDate(newsletterData?.date_created) || "25 Sep 2024"}</span>
          </div>
    
          {newsletterData?.pdf_file && (
            <div className="metadata-attachment">
              <h3>Attachments</h3>
              <a
                href={newsletterData?.pdf_file}
                download
                rel="noopener noreferrer"
                target="_blank"
              >
                Attached File
              </a>
            </div>
          )}
        </div>

        <div className="post-title">
          <h2>{newsletterData?.title || "School Update"}</h2>
        </div>

        <div className="post-content">
          {newsletterData?.body ? (
            <div dangerouslySetInnerHTML={{ __html: newsletterData.body }} />
          ) : (
            <p>There is no content to show.</p>
          )}
        </div>
      </div>

      <div className="footer">
        <span>&copy; Brainstake {new Date().getFullYear()} All rights reserved</span>
        <a href="https://eschools.brainstake.tech/privacy.html" target="_blank" rel="noreferrer">Privacy policy</a>
        <a href="https://eschools.brainstake.tech/terms.html" target="_blank" rel="noreferrer">Terms and conditions</a>
      </div>
    </div>
  );
};

export default UnauthenticatedNewsletterDetail;