import { Button, Form, Input, message, Spin } from "antd";
import { UserOutlined, LockOutlined, LoginOutlined } from "@ant-design/icons";
import "./styles.css";
import AuthenticationService from "../../services/authentication.service";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import bg from "../../Assets/images/bg_1.png";
import logo from "../../Assets/images/half_logo.png";
import dioscese from "../../Assets/images/diocese.png";
import langham from "../../Assets/images/Langham.png";
import mellary from "../../Assets/images/Mt Mellary.png";
import pamushana from "../../Assets/images/pamushana.png";
import { toInputUppercase } from "../../common";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { data as schoolData } from "../../pages/HomePage/schoolData";
import authenticationService from "../../services/authentication.service";
import { apiUrl } from "../../http-common";
import useToken from "../../hooks/useToken";

export default function Login({ setToken }) {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [schoolName, setSchoolName] = useState("");

  const [schoolLogoURL, setSchoolLogoURL] = useState("");

  const [
    hasParentTriedToLoginBeforeUsingURLParams,
    setHasParentTriedToLoginBeforeUsingURLParams,
  ] = useState(false);

  const location = useLocation();

  const [urlParams] = useSearchParams();

  const username_param = urlParams.get("username");
  const password_param = !hasParentTriedToLoginBeforeUsingURLParams
    ? urlParams.get("password")
    : "password";
  const redirect_param = urlParams.get("redirect");

  useEffect(
    function autoLoginIntoAccountToDisplayResultsToParents() {
      const executeLogin = async () => { 
        if (username_param && password_param && redirect_param === "results") {
          try {
            const response = await AuthenticationService.login({
              username: username_param,
              password: password_param,
            });

            if (response?.status === 200) {
              setToken(response?.data);

              (async function gatherLoginDetails() {
                async function fetchIPAddress() {
                  try {
                    const response = await fetch(
                      "https://api.ipify.org?format=json"
                    );
                    const data = await response.json();
                    return data.ip;
                  } catch (error) {
                    console.error("Error fetching IP:", error);
                    window.location.replace("/");
                    return "Unknown";
                  }
                }

                function getDeviceDetails() {
                  const userAgent = navigator.userAgent;
                  let deviceOS = "Unknown OS";
                  let browserUsed = "Unknown Browser";

                  if (/Windows/i.test(userAgent)) deviceOS = "Windows";
                  else if (/Mac/i.test(userAgent)) deviceOS = "MacOS";
                  else if (/Linux/i.test(userAgent)) deviceOS = "Linux";
                  else if (/Android/i.test(userAgent)) deviceOS = "Android";
                  else if (/iPhone|iPad|iPod/i.test(userAgent))
                    deviceOS = "iOS";

                  if (/Chrome/i.test(userAgent) && !/Edge/i.test(userAgent))
                    browserUsed = "Chrome";
                  else if (/Firefox/i.test(userAgent)) browserUsed = "Firefox";
                  else if (
                    /Safari/i.test(userAgent) &&
                    !/Chrome/i.test(userAgent)
                  )
                    browserUsed = "Safari";
                  else if (/Edge/i.test(userAgent)) browserUsed = "Edge";
                  else if (/MSIE|Trident/i.test(userAgent))
                    browserUsed = "Internet Explorer";

                  return {
                    deviceOS,
                    browserUsed,
                    userAgent,
                  };
                }

                function getDeviceType() {
                  return /Mobile|Android|iP(hone|od|ad)/i.test(
                    navigator.userAgent
                  )
                    ? "mobile"
                    : "desktop";
                }

                function getReferrerURL() {
                  return document.referrer || "No Referrer";
                }

                function generateSessionID() {
                  return (
                    Math.random().toString(36).substr(2, 9) + "-" + Date.now()
                  );
                }

                const ipAddress = await fetchIPAddress();
                const deviceDetails = getDeviceDetails();
                const deviceType = getDeviceType();
                const referrerURL = getReferrerURL();
                const loginTime = new Date().toISOString();
                const sessionID = generateSessionID();

                const loginDetails = {
                  user_id: authenticationService.getUserId(),
                  ip_address: ipAddress,
                  device_os: deviceDetails.deviceOS,
                  browser_used: deviceDetails.browserUsed,
                  user_agent: deviceDetails.userAgent,
                  device_type: deviceType,
                  username: authenticationService.getUsername(),
                  role: authenticationService.getUserRole(),
                  login_time: loginTime,
                  logout_time: null,
                  session_id: sessionID,
                  login_status:
                    authenticationService.getUserRole().length > 0
                      ? "success"
                      : "",
                  device_name: "",
                  access_method: referrerURL ? "Referrer" : "Direct",
                  access_duration: null,
                  error_message: "",
                  referrer_url: referrerURL,
                  custom_tags: "",
                  device_model: "",
                  network_provider: "",
                };

                const token = response?.data?.access;

                const res = await fetch(
                  `${apiUrl}tracking-system-access/access-logs/`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(loginDetails),
                  }
                );

                if (!res.ok) {
                  const errorData = await res.json();
                  console.error("Error posting access log:", errorData);
                  window.location.replace("/");
                } else {
                  console.log("Access log posted successfully!");
                  window.location.replace("/student/end-term-results");
                }
              })();
            }
            setDisabled(false);
            setLoading(false);
            return 1;
          } catch (e) {
            console.log(e.response);
            setDisabled(false);
            setLoading(false);
            // message.error("Account with given credentials not found.");
            setHasParentTriedToLoginBeforeUsingURLParams(true);
            return 0;
          }
        }
      };
      executeLogin();
    },
    [password_param]
  );

  function getLogoUrl(schoolName) {
    const school = schoolData.find((item) => item.schoolName === schoolName);
    return school ? school.logo : null;
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const school = params.get("school");
    if (school) {
      setSchoolName(school);
      const school_logo_url = getLogoUrl(school);
      console.log(school_logo_url);
      setSchoolLogoURL(school_logo_url);

      localStorage.setItem("SCHOOL_NAME_CONSTANT", school);
    }
  }, [location]);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (password === "" || username === "") {
      message.error("Please enter all the details.");
      return 0;
    }

    setLoading(true);
    setDisabled(true);

    try {
      const response = await AuthenticationService.login({
        username: username,
        password: password,
      });

      if (response?.status === 200) {
        setToken(response?.data);

        (async function gatherLoginDetails() {
          async function fetchIPAddress() {
            try {
              const response = await fetch("https://api.ipify.org?format=json");
              const data = await response.json();
              return data.ip;
            } catch (error) {
              console.error("Error fetching IP:", error);
              window.location.replace("/");
              return "Unknown";
            }
          }

          function getDeviceDetails() {
            const userAgent = navigator.userAgent;
            let deviceOS = "Unknown OS";
            let browserUsed = "Unknown Browser";

            if (/Windows/i.test(userAgent)) deviceOS = "Windows";
            else if (/Mac/i.test(userAgent)) deviceOS = "MacOS";
            else if (/Linux/i.test(userAgent)) deviceOS = "Linux";
            else if (/Android/i.test(userAgent)) deviceOS = "Android";
            else if (/iPhone|iPad|iPod/i.test(userAgent)) deviceOS = "iOS";

            if (/Chrome/i.test(userAgent) && !/Edge/i.test(userAgent))
              browserUsed = "Chrome";
            else if (/Firefox/i.test(userAgent)) browserUsed = "Firefox";
            else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent))
              browserUsed = "Safari";
            else if (/Edge/i.test(userAgent)) browserUsed = "Edge";
            else if (/MSIE|Trident/i.test(userAgent))
              browserUsed = "Internet Explorer";

            return {
              deviceOS,
              browserUsed,
              userAgent,
            };
          }

          function getDeviceType() {
            return /Mobile|Android|iP(hone|od|ad)/i.test(navigator.userAgent)
              ? "mobile"
              : "desktop";
          }

          function getReferrerURL() {
            return document.referrer || "No Referrer";
          }

          function generateSessionID() {
            return Math.random().toString(36).substr(2, 9) + "-" + Date.now();
          }

          const ipAddress = await fetchIPAddress();
          const deviceDetails = getDeviceDetails();
          const deviceType = getDeviceType();
          const referrerURL = getReferrerURL();
          const loginTime = new Date().toISOString();
          const sessionID = generateSessionID();

          const loginDetails = {
            user_id: authenticationService.getUserId(),
            ip_address: ipAddress,
            device_os: deviceDetails.deviceOS,
            browser_used: deviceDetails.browserUsed,
            user_agent: deviceDetails.userAgent,
            device_type: deviceType,
            username: authenticationService.getUsername(),
            role: authenticationService.getUserRole(),
            login_time: loginTime,
            logout_time: null,
            session_id: sessionID,
            login_status:
              authenticationService.getUserRole().length > 0 ? "success" : "",
            device_name: "",
            access_method: referrerURL ? "Referrer" : "Direct",
            access_duration: null,
            error_message: "",
            referrer_url: referrerURL,
            custom_tags: "",
            device_model: "",
            network_provider: "",
          };

          const token = response?.data?.access;

          const res = await fetch(
            `${apiUrl}tracking-system-access/access-logs/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(loginDetails),
            }
          );

          if (!res.ok) {
            const errorData = await res.json();
            console.error("Error posting access log:", errorData);
            window.location.replace("/");
          } else {
            console.log("Access log posted successfully!");
            window.location.replace("/");
          }
        })();
      }
      setDisabled(false);
      setLoading(false);
      return 1;
    } catch (e) {
      console.log(e.response);
      setDisabled(false);
      setLoading(false);
      message.error("Account with given credentials not found.");
      return 0;
    }
  };

  return (
    <div className="container-fluid main__container">
      {/* <div
        className="row vh-100"
        style={{
          // backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundOrigin: "content-box",
          backgroundAttachment: "fixed",
        }}
      > */}
      {/* <div className="col-md-7">
          <div className="d-none d-md-block justify-content-center align-items-center vh-100 ps-5 ms-5 ">
            <div className="ms-5">
              <img src={schoolLogoURL ? `./school_images/${schoolLogoURL}` : logo} height={250} width={250} className="ms-5" alt="Logo" />
              <h1 className="display-6 fw-bolder ms-5">{schoolName ? schoolName : 'Brainstake'} eSchools</h1>
              <p className="ms-md-5 fs-6">
                Unlock the power of effortless education management with our cutting-edge school management system.
              </p>

              <h4 className="ms-5 mb-3 mt-5 text-muted">Our clients</h4>
              <div className="d-flex justify-content-start">
                <img src={mellary} height={50} width={50} className="ms-5" alt="Logo" />
                <img src={langham} height={50} width={50} className="ms-5" alt="Logo" />
                <img src={dioscese} height={50} width={50} className="ms-5" alt="Logo" />
                <img src={pamushana} height={50} width={50} className="ms-5" alt="Logo" />
              </div>
            </div>
          </div>
        </div> */}

      {username_param && password_param && redirect_param === "results" ? (
        <div className="entire_screen_spinner_container">
          <Spin size="large" />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Form
            layout="vertical"
            onSubmitCapture={handleLogin}
            className="col-md-9 bg-white my-5 m-md-5 px-1 px-md-4 pt-4 rounded-1"
          >
            <Link to="https://eschools.tech">
              <img
                src={schoolLogoURL ? `./school_images/${schoolLogoURL}` : logo}
                height={250}
                width={250}
                className="school_logo_container"
                alt="Logo"
              />
            </Link>
            <h2 className="mb-3 text-center">
              Welcome to {schoolName ? schoolName : "your"}&nbsp;E-School
              {!schoolName && "s"}&nbsp;Platform
            </h2>
            <p className="powered_by">
              powered by&nbsp;
              <a
                href="https://brainstake.tech"
                target="_blank"
                rel="noreferrer"
              >
                Brainstake
              </a>
            </p>
            <hr
              className="decoration_hr_line"
              style={{
                borderTop: "4px solid green",
                width: "30%",
                maxWidth: "30%",
              }}
            />
            <p className="mt-2 text-center text-muted">
              Please sign in to continue to your account
            </p>
            <Form.Item>
              <Input
                size="large"
                prefix={<UserOutlined />}
                placeholder="Reg number"
                className="rounded-1"
                onInput={toInputUppercase}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Item>
            <Form.Item>
              <Input.Password
                size="large"
                prefix={<LockOutlined />}
                placeholder="Password"
                className="rounded-1"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                block
                icon={<LoginOutlined />}
                loading={loading}
                disabled={disabled}
              >
                Sign in
              </Button>
            </Form.Item>
            <div className="my-3 text-center">
              <span className="text-muted">
                By signing in, I agree to Brainstake{" "}
              </span>
              <a
                href="https://eschools.brainstake.tech/terms.html"
                target="_blank"
                rel="noreferrer"
                className="text-muted"
              >
                Terms of service
              </a>
              <span className="text-muted"> and </span>
              <a
                href="https://eschools.brainstake.tech/privacy.html"
                target="_blank"
                rel="noreferrer"
                className="text-muted"
              >
                Privacy policy
              </a>
            </div>
          </Form>
        </div>
      )}
      {/* </div> */}
    </div>
  );
}
