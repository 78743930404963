import { Card, Divider, List, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { fallbackImg, toHumanDate } from "../../../common";
import NewsletterService from "../../../services/newsletter.service";
import authenticationService from "../../../services/authentication.service";
import studentService from "../../../services/student.service";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../http-common";
import useToken from "../../../hooks/useToken";

const ParentsNewsletterList = () => {
  //   const { newsletters } = useLoaderData();
  const [newsletterData, setNewsletterData] = useState([]);
  const [childrenTenantId, setChildrenTenantId] = useState("");
  const navigate = useNavigate();

  async function fetchChildrenTenantId() {
    try {
      const childrenResponse = await studentService.getChildrenOfParent(
        authenticationService.getUserId()
      );
      const firstChild = childrenResponse?.data?.[0];
      const tenant = firstChild?.user?.tenant;
      console.log("tenant", tenant);
      setChildrenTenantId(tenant);
      return tenant;
    } catch (e) {
      console.error("Error fetching tenant ID:", e.message || e);
      return null;
    }
  }

  async function parentsNewslettersLoader() {
    try {
      const newslettersResponse =
        await NewsletterService.newGetAllNewslettersById(childrenTenantId);
      const filteredNewsletterResponse = newslettersResponse?.data?.filter(
        (newsletter) =>
          newsletter?.audience?.length > 0 &&
          (newsletter?.audience?.toLowerCase().includes("parent") ||
            newsletter?.audience?.toLowerCase().includes("all")) &&
          newsletter?.is_published
      );

      console.log("filteredNewsletterResponse", filteredNewsletterResponse);
      setNewsletterData(filteredNewsletterResponse);
      return { newsletters: filteredNewsletterResponse };
    } catch (e) {
      console.error("Error loading newsletters:", e.message || e);
    }
  }

  useEffect(() => {
    const fetchNewsletters = async () => {
      if (childrenTenantId) {
        await parentsNewslettersLoader();
      }
    };
    fetchNewsletters();
  }, [childrenTenantId]);

  useEffect(() => {
    console.log("childrenTenantId", childrenTenantId);
  }, [childrenTenantId]);

  useEffect(() => {
    console.log("newsletterData", newsletterData);
  }, [newsletterData]);

  useEffect(() => {
    fetchChildrenTenantId();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>Newsletter</h3>
      </div>

      <Divider type={"horizontal"} />

      <List
        itemLayout="vertical"
        grid={{
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
          gutter: 0,
        }}
        size="large"
        pagination={{
          xs: false,
          sm: false,
          lg: 6,
          xl: 8,
          xxl: 8,
        }}
        dataSource={newsletterData}
        renderItem={(item) => (
          <List.Item key={item.title} style={{ margin: "12px", padding: 0 }}>
            <Link
              to={`${item.id}`}
              className="text-success text-decoration-none"
            >
              <Card
                hoverable={true}
                onClick={() => navigate(`${item.id}`)}
                style={{ minHeight: 392 }}
                cover={
                  <img
                    alt="example"
                    id={`img-${item.id}`}
                    height={200}
                    style={{ objectFit: "cover" }}
                    src={item.image || fallbackImg}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = fallbackImg;
                    }}
                  />
                }
              >
                <Link
                  to={`${item.id}`}
                  className="text-success text-decoration-none"
                >
                  <h5>{item.title}</h5>
                </Link>
                <Typography.Paragraph
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                  className="mt-2"
                >
                  <div dangerouslySetInnerHTML={{ __html: item.body }} />
                </Typography.Paragraph>
                <div className="d-md-flex justify-content-between align-items-center">
                  {/* <span className="text-muted">
                  From: <strong>{item.author}</strong>
                </span> */}
                  <span>{toHumanDate(item.date_created)}</span>
                </div>
              </Card>
            </Link>
          </List.Item>
        )}
      />
    </>
  );
};

export default ParentsNewsletterList;
